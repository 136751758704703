import React, { useContext } from 'react'
import { AuthContext } from "../../../Context/AuthContext";
import Sidebar from '../../../Components/sidebar/Sidebar';
import Navbar from '../../../Components/navbar/navbar';
import './style.css'

//TODO: Update the content of the terms and conditions
export default function UserTermsCond() {
    const { currentUser } = useContext(AuthContext);
    return (
        <>
            <div className='consultant_home'>
                <Sidebar userType={'user'} />
                <div className='Consultant_home_container'>
                    <Navbar userData={currentUser} userType={'user'} />
                    <div className="container-content" data-aos="fade-up">
                        <div className="compalinace-content">
                            <div className="content-container">
                                <p className="content-head">Terms & Conditions</p>
                                <div className="content-separator"></div>
                                <p className="updated-date">Last updated on Aug 1st 2024</p>
                                <p className="content-text">
                                    Welcome to <b>Thapy</b>! These Terms and Conditions ("Terms") govern your use of our platform as a therapist. By registering as a therapist on <b>Thapy</b>, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our platform.
                                </p>

                                <p className="content-title"><strong>Eligibility:</strong></p>
                                <p className="content-text">
                                    You must be a licensed therapist with the appropriate certification and credentials to provide mental health services in your jurisdiction. By registering on <b>Thapy</b>, you represent and warrant that you meet these requirements.
                                </p>

                                <p className="content-title"><strong>Account Registration:</strong></p>
                                <p className="content-text">
                                    You may be required to create an account to access certain features of <b>Thapy</b> as a therapist. When registering for an account, you agree to provide accurate and complete information about your qualifications, experience, and certifications.
                                </p>

                                <p className="content-title"><strong>Professional Conduct:</strong></p>
                                <p className="content-text">
                                    As a therapist on <b>Thapy</b>, you agree to adhere to the highest standards of professional conduct and ethics. This includes:
                                </p>

                                <ul className="unorder-list">
                                    <li className="list-item">
                                        <p className="content-text list-text">Providing services within the scope of your expertise and training</p>
                                    </li>
                                    <li className="list-item">
                                        <p className="content-text list-text">
                                            Maintaining client confidentiality and privacy in accordance with applicable laws and regulations
                                        </p>
                                    </li>
                                    <li className="list-item">
                                        <p className="content-text list-text">
                                            Workshops and/or webinars delivered by trained Therapist
                                        </p>
                                    </li>
                                    <li className="list-item">
                                        <p className="content-text list-text">
                                            Refraining from engaging in any conduct that could harm or exploit clients, including but not limited to sexual misconduct, financial exploitation, or abuse of power
                                        </p>
                                    </li>
                                </ul>

                                <p className="content-title"><strong>Quality of Care:</strong></p>
                                <p className="content-text">
                                    You agree to provide quality mental health services to clients on <b>Thapy</b>, including conducting thorough assessments, developing appropriate treatment plans, and offering compassionate support and guidance.
                                </p>

                                <p className="content-title"><strong>Fees and Payment:</strong></p>
                                <p className="content-text">
                                    <b>Thapy</b> may deduct a service fee from your earnings for using the platform, which will be clearly outlined in your agreement with <b>Thapy</b>.
                                </p>

                                <p className="content-title"><strong>Compensation:</strong></p>
                                <p className="content-text">
                                    Your compensation will be based on the number of sessions you conduct with clients on <b>Thapy</b>. <b>Thapy</b> will take a percentage cut from your session fees as agreed upon in your contract.
                                </p>

                                <p className="content-title"><strong>Scheduling and Availability:</strong></p>
                                <p className="content-text">
                                    You have the flexibility to arrange your own schedule and availability on <b>Thapy</b>. You will use the scheduling system provided by <b>Thapy</b> to book sessions with clients when you are free.
                                </p>

                                <p className="content-title"><strong>Content Creation:</strong></p>
                                <p className="content-text">
                                    You may contribute to the motivational content section of <b>Thapy</b> by creating reels or other materials for the benefit of users. Your contributions may be compensated separately as agreed upon with <b>Thapy</b>
                                </p>

                                <p className="content-title"><strong>User Ratings:</strong></p>
                                <p className="content-text">
                                    After every session with a user, you will be rated based on the quality of your service. Your ratings will be visible to users on <b>Thapy</b>, and they may influence user decisions when choosing
                                    a therapist.
                                </p>

                                <p className="content-title"><strong>Termination:</strong></p>
                                <p className="content-text">
                                    We reserve the right to suspend or terminate your account on <b>Thapy</b> at any time, without prior notice or liability, for any reason whatsoever, including if we believe that you have violated these Terms or engaged in misconduct.
                                </p>

                                <p className="content-title"><strong>Changes to Terms:</strong></p>
                                <p className="content-text">
                                    We may revise these Terms from time to time in our sole discretion. The most current version will always be available on <b>Thapy</b>. By continuing to use, <b>Thapy</b> after any revisions become effective, you agree to be bound by the revised Term.
                                </p>

                                <p className="content-title"><strong>Grievance Redressal:</strong></p>
                                <p className="content-text">
                                    We take user concerns seriously. If you have any issues with Thapy's services, you can file a grievance through our dedicated support channel: <a href="mailto:contact@thapy.com">contact@thapy.com</a>
                                    . We strive to resolve all grievances fairly and efficiently. You can expect a response
                                    within one month of submitting your complaint.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}