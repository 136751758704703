import React, { useState, useEffect, useContext } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Badge,
  IconButton,
  OutlinedInput,
  Grid,
  Chip,
  FormHelperText,
  Avatar,
  Button,
} from "@mui/material";
import "./style.css";
import Loader from "../../../Components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { auth, storage } from "../../../Config/Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DBHandler from "../../../Utils/DBHandler";
import BackupIcon from "@mui/icons-material/Backup";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { usePsychologist } from "../../../Utils/Psychologist/PsychologistContext";
import { serverTimestamp } from "firebase/firestore";
import { AuthContext } from "../../../Context/AuthContext";

export default function IntroDetails() {
  const { psychologist, modifyPsychologist } = usePsychologist();
  const { currentUser } = useContext(AuthContext);
  const psyId = currentUser.id;
  const dbHandler = new DBHandler();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [currentStep, setCurrentStep] = useState(1);
  const [contactError, setContactError] = useState("");
  const [languageError, setLanguageError] = useState(false);
  const [expertiseError, setExpertiseError] = useState(false);

  const [fullName, setFullName] = useState(psychologist.name);
  const [profileImage, setProfileImage] = useState(
    psychologist.imagePath || ""
  );
  const [about, setAbout] = useState(psychologist.about || "");
  const [contactNumber, setContactNumber] = useState(psychologist.phone || "");
  const [countryCode, setCountryCode] = useState(
    psychologist.countryCode || "91"
  );
  const [priceConsult, setPriceConsult] = useState(psychologist.price || 0);
  const [upiID, setUpiID] = useState(psychologist.upi || "");
  const [language, setLanguage] = useState(psychologist.languages || []);
  const [expertise, setExpertise] = useState(psychologist.areas || []);
  const [selectedProfession, setSelectedProfession] = useState(
    psychologist.professional || false
  );
  const [image1, setImage1] = useState(psychologist.identityProofPath || "");
  const [image2, setImage2] = useState(psychologist.professionProofPath || "");

  const handleNext = () => {
    if (validateStep()) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const getFileExtension = (url) => {
    const cleanUrl = url.split("?")[0];
    const extension = cleanUrl.split(".").pop();
    return extension;
  };

  useEffect(() => {
    const fetchImages = async () => {
      try {
        if (psychologist.identityProofPath) {
          const identityExtension = getFileExtension(
            psychologist.identityProofPath
          );
          const identityUrl = await storage
            .ref(`psy/identity/${psychologist.id}.${identityExtension}`)
            .getDownloadURL();
          setImage1(identityUrl);
        }
        if (psychologist.professionProofPath) {
          const professionExtension = getFileExtension(
            psychologist.professionProofPath
          );
          const professionUrl = await storage
            .ref(`psy/profession/${psychologist.id}.${professionExtension}`)
            .getDownloadURL();
          setImage2(professionUrl);
        }
        if (psychologist.imagePath) {
          const profileExtension = getFileExtension(psychologist.imagePath);
          const profileUrl = await storage
            .ref(`psy/profile/${psychologist.id}.${profileExtension}`)
            .getDownloadURL();
          setProfileImage(profileUrl);
        }
      } catch (error) {
        toast.error("Error fetching images. Please try again later!!", { containerId: 'introDetails' });
      }
    };
    fetchImages();
  }, [psychologist]);

  const validateStep = () => {
    switch (currentStep) {
      case 1:
        return (
          profileImage !== "" &&
          contactNumber !== "" &&
          contactError === "" &&
          selectedProfession !== "" &&
          about !== "" &&
          fullName !== "" &&
          countryCode !== ""
        );
      case 2:
        return (
          language.length > 0 &&
          expertise.length > 0 &&
          priceConsult !== "" &&
          upiID !== ""
        );
      case 3:
        return image1 !== "" && image2 !== "";
      default:
        return false;
    }
  };

  const languageOptions = [
    { label: "English", value: "English" },
    { label: "Hindi (हिंदी)", value: "Hindi" },
    { label: "Bengali (বাংলা)", value: "Bengali" },
    { label: "Marathi (मराठी)", value: "Marathi" },
    { label: "Telugu (తెలుగు)", value: "Telugu" },
    { label: "Tamil (தமிழ்)", value: "Tamil" },
    { label: "Gujarati (ગુજરાતી)", value: "Gujarati" },
    { label: "Urdu (اردو)", value: "Urdu" },
    { label: "Kannada (کنڑ)", value: "Kannada" },
    { label: "Odia (ଓଡିଆ)", value: "Odia" },
    { label: "Malayalam (മലയാളം)", value: "Malayalam" },
    { label: "Punjabi (ਪੰਜਾਬੀ) ", value: "Punjabi" },
  ];

  const expertiseOptions = [
    { label: "General", value: "General" },
    { label: "Depression", value: "Depression" },
    { label: "Breakup", value: "Breakup" },
    { label: "Stress", value: "Stress" },
    { label: "Anxiety", value: "Anxiety" },
    { label: "Addiction", value: "Addiction" },
    { label: "Relationship", value: "Relationship" },
    { label: "Grief", value: "Grief" },
    { label: "Career", value: "Career" },
    { label: "Family", value: "Family" },
    { label: "Trauma", value: "Trauma" },
    { label: "Self-Esteem", value: "Self-Esteem" },
  ];

  const handleContactChange = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setContactNumber(value);
      if (value.length < 7 || value.length > 15) {
        setContactError("Contact number must be between 7 and 15 digits.");
      } else {
        setContactError("");
      }
    } else {
      setContactError("Invalid Contact Number");
    }
  };

  const handleLanguageChange = (event) => {
    const { value } = event.target;
    setLanguage(value);
    if (value.length === 0) {
      setLanguageError("Please select at least one language");
    } else {
      setLanguageError("");
    }
  };

  const handleExpertiseChange = (event) => {
    const { value } = event.target;
    setExpertise(value);
    if (value.length === 0) {
      setExpertiseError("Please select at least one language");
    } else {
      setExpertiseError("");
    }
  };

  const handleImageChange = (event, setImage) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      if (selectedImage.size > 1024 * 1024 * 5) {
        toast.error("Image size should be less than 5MB", { containerId: 'introDetails' });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(selectedImage);
      }
    } else {
      toast.error("Please add a valid image!!", { containerId: 'introDetails' });
    }
  };

  const uploadImage = async (file, path) => {
    const ref = storage.ref(path);
    await ref.put(file);
    return ref.getDownloadURL();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const image1File = document.getElementById("identity-upload").files[0];
        const image2File = document.getElementById("professional-upload")
          .files[0];
        const profileFile = document.getElementById("profile-image-upload")
          .files[0];

        let identityProofPath = "";
        let professionProofPath = "";
        let profileImagePath = "";

        if (image1File) {
          const image1Extension = image1File.name.split(".").pop();
          identityProofPath = await uploadImage(
            image1File,
            `psy/identity/${psyId}.${image1Extension}`
          );
        }
        if (image2File) {
          const image2Extension = image2File.name.split(".").pop();
          professionProofPath = await uploadImage(
            image2File,
            `psy/profession/${psyId}.${image2Extension}`
          );
        }
        if (profileFile) {
          const profileExtension = profileFile.name.split(".").pop();
          profileImagePath = await uploadImage(
            profileFile,
            `psy/profile/${psyId}.${profileExtension}`
          );
          await uploadImage(
            profileFile,
            `profile/${psyId}.${profileExtension}`
          );
        }

        const psychologistData = {
          id: psyId,
          name: fullName,
          phone: contactNumber,
          countryCode: countryCode,
          imagePath: profileImagePath,
          about: about,
          price: parseFloat(priceConsult),
          upi: upiID,
          languages: language,
          areas: expertise,
          professional: selectedProfession === "true" ? true : false,
          identityProofPath: identityProofPath,
          professionProofPath: professionProofPath,
          lastOnline: serverTimestamp(),
        };
        await dbHandler.updatePsychologist(psychologistData);
        await modifyPsychologist(psychologistData);
        toast.success("Profile updated successfully", { containerId: 'introDetails' });
        setTimeout(() => {
          navigate("/therapists/dashboard");
        }, 3000);
      } else {
        toast.error("Error updating details. Please try again later!!", { containerId: 'introDetails' });
      }
    } catch (error) {
      toast.error(error.message, { containerId: 'introDetails' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ToastContainer
        containerId="introDetails"
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {isLoading && <Loader />}
      <div className="col px-3">
        <ul
          className="nav nav-pills nav-fill pill-cust mt-3 py-1 rounded-lg justify-content-center"
          id="pills-tab"
          role="tablist"
        >
          {["Personal Info", "Consultation Info", "Verification"].map(
            (stepTitle, index) => (
              <li key={index + 1} className="nav-item" role="presentation">
                <a
                  className={`nav-link ${currentStep === index + 1 ? "active" : ""
                    } d-flex align-items-center`}
                  onClick={(e) => e.preventDefault()}
                  id={`pills-st${index + 1}-tab`}
                  data-toggle="pill"
                  href={`#pills-st${index + 1}`}
                  role="tab"
                  aria-controls={`pills-st${index + 1}`}
                  aria-selected={currentStep === index + 1}
                >
                  <span className="border fa-cust shadow-sm rounded-circle p-2 mx-2 d-flex align-items-center justify-content-center">
                    <i className="fas fa-pencil-alt"></i>
                  </span>
                  <p className="text-uppercase mb-0 pl-3">
                    <span className="step-info d-flex text-dark">
                      Step-{index + 1}
                    </span>
                    <span className="step-details d-flex mt-2 text-primary text-capitalize">
                      {stepTitle}
                    </span>
                  </p>
                </a>
              </li>
            )
          )}
        </ul>

        <div
          className="tab-content py-4 px-5 bg-white shadow"
          id="pills-tabContent"
        >
          {["Personal Info", "Consultation Info", "Verification"].map(
            (stepTitle, index) => (
              <div
                key={index + 1}
                className={`tab-pane fade ${currentStep === index + 1 ? "show active" : ""
                  }`}
                id={`pills-st${index + 1}`}
                role="tabpanel"
                aria-labelledby={`pills-st${index + 1}-tab`}
              >
                {stepTitle === "Personal Info" && (
                  <>
                    <h5 className="text-center mb-3">
                      Please Provide Your Information to Get Started
                    </h5>
                    <div className="mx-5 px-5">
                      <Box className="row gy-2 overflow-hidden ">
                        <Grid container justifyContent="center">
                          <Grid item xs={12}>
                            <Box
                              display="flex"
                              alignItems="center"
                              flexDirection="column"
                              justifyContent="center"
                            >
                              <Badge
                                overlap="circular"
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                badgeContent={
                                  <label htmlFor="profile-image-upload">
                                    <IconButton
                                      sx={{ color: "#00296b" }}
                                      aria-label="upload picture"
                                      component="span"
                                    >
                                      <i className="bi bi-camera-fill"></i>
                                    </IconButton>
                                    <input
                                      accept="image/*"
                                      style={{ display: "none" }}
                                      id="profile-image-upload"
                                      type="file"
                                      onChange={(e) =>
                                        handleImageChange(e, setProfileImage)
                                      }
                                    />
                                  </label>
                                }
                              >
                                <Avatar
                                  alt="Profile Image"
                                  src={profileImage}
                                  sx={{ width: 140, height: 150 }}
                                />
                              </Badge>
                              <FormHelperText style={{ fontSize: "1rem" }}>
                                <b>Upload your profile image</b>
                              </FormHelperText>
                            </Box>
                          </Grid>
                        </Grid>
                        <div className="wave-group">
                          <i className="bi bi-person-fill icon"></i>
                          <input
                            type="text"
                            className="input"
                            name="name"
                            id="name"
                            placeholder=""
                            required
                            onChange={(e) => setFullName(e.target.value)}
                            value={fullName}
                          />
                          <span className="bar"></span>
                          <label className="label">
                            {[
                              "F",
                              "u",
                              "l",
                              "l",
                              "\u00A0",
                              "N",
                              "a",
                              "m",
                              "e",
                            ].map((char, index) => (
                              <span
                                className="label-char"
                                style={{ "--index": index }}
                                key={index}
                              >
                                {char}
                              </span>
                            ))}
                          </label>
                        </div>

                        <div className="wave-group">
                          <i className="bi bi-envelope-fill icon"></i>
                          <input
                            type="email"
                            className="input"
                            name="email"
                            id="email"
                            placeholder=""
                            value={psychologist.email}
                          />
                          <span className="bar"></span>
                          <label className="label">
                            {["E", "m", "a", "i", "l"].map((char, index) => (
                              <span
                                className="label-char"
                                style={{ "--index": index }}
                                key={index}
                              >
                                {char}
                              </span>
                            ))}
                          </label>
                        </div>
                        <Box className={`col-md-6 ${window.innerWidth <= 768 ? 'col-12' : ''}`}>
                          <div className="ui-wrapper">
                            <input
                              checked=""
                              id="India"
                              name="flag"
                              type="radio"
                            />
                            <input id="USA" name="flag" type="radio" />
                            <input id="Nepal" name="flag" type="radio" />
                            <input id="Pakistan" name="flag" type="radio" />
                            <input id="Bangladesh" name="flag" type="radio" />
                            <input id="China" name="flag" type="radio" />
                            <input id="Afghanistan" name="flag" type="radio" />
                            <input id="Other" name="flag" type="radio" />

                            <input
                              className="dropdown-checkbox"
                              name="dropdown"
                              id="dropdown"
                              type="checkbox"
                            />
                            <label
                              className="dropdown-container"
                              htmlFor="dropdown"
                              style={{ color: "white" }}
                            >
                              <ArrowDropDownIcon />
                            </label>

                            <div className="input-wrapper">
                              <legend>
                                <label htmlFor="phonenumber">
                                  Phone Number *
                                </label>
                              </legend>
                              <div className="textfield">
                                <input
                                  pattern="\d+"
                                  maxLength="15"
                                  id="phonenumber"
                                  type="text"
                                  value={contactNumber}
                                  onChange={handleContactChange}
                                />
                                {contactError && (
                                  <span className="invalid-msg">
                                    {contactError}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="select-wrapper">
                              <ul>
                                <li className="India">
                                  <label htmlFor="India">
                                    <span>🇮🇳</span>India (+91)
                                  </label>
                                </li>
                                <li className="USA">
                                  <label htmlFor="USA">
                                    <span>🇺🇸</span>USA (+1)
                                  </label>
                                </li>
                                <li className="Nepal">
                                  <label htmlFor="Nepal">
                                    <span>🇳🇵</span>Nepal (+977)
                                  </label>
                                </li>
                                <li className="Pakistan">
                                  <label htmlFor="Pakistan">
                                    <span>🇵🇰</span>Pakistan (+92)
                                  </label>
                                </li>
                                <li className="Bangladesh">
                                  <label htmlFor="Bangladesh">
                                    <span>🇧🇩</span>Bangladesh (+880)
                                  </label>
                                </li>
                                {/* <li className="China">
                                  <label htmlFor="China">
                                    <span>🇨🇳</span>China (+86)
                                  </label>
                                </li> */}
                                {/* <li className="Afghanistan">
                                  <label htmlFor="Afghanistan">
                                    <span>🇦🇫</span>Afghanistan (+93)
                                  </label>
                                </li> */}
                                <li className="Other">
                                  <label htmlFor="Other">
                                    <span>🇴🇹</span>Other
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Box>
                        <Box className={`col-md-6 ${window.innerWidth <= 768 ? 'col-12' : ''}`}>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            required
                          >
                            <InputLabel
                              id="profession-label"
                              style={{ Color: "#00296b" }}
                            >
                              Select Profession
                            </InputLabel>
                            <Select
                              labelId="profession-label"
                              id="profession"
                              name="Profession"
                              onChange={(e) =>
                                setSelectedProfession(e.target.value)
                              }
                              value={selectedProfession}
                              input={
                                <OutlinedInput
                                  style={{ borderColor: "#00296b" }}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <i
                                        className="bi bi-check-square-fill"
                                        style={{ color: "#00296b" }}
                                      ></i>
                                    </InputAdornment>
                                  }
                                  label="Select Profession"
                                />
                              }
                            >
                              <MenuItem value="" disabled>
                                Select Profession
                              </MenuItem>
                              <MenuItem value="true">
                                I am a Professional
                              </MenuItem>
                              <MenuItem value="false">
                                Register as a Peer
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                        <Box className="col-12 mb-4">
                          <TextField
                            variant="outlined"
                            style={{ borderColor: "#00296b" }}
                            fullWidth
                            rows={2}
                            multiline
                            label="About"
                            type="text"
                            name="about"
                            id="about"
                            placeholder="This Bio will be visible to the users"
                            value={about}
                            onChange={(e) => setAbout(e.target.value)}
                            required
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <i className="bi bi-info-circle-fill"></i>
                                </InputAdornment>
                              ),
                            }}
                            margin="normal"
                          />
                        </Box>
                      </Box>
                    </div>
                  </>
                )}
                {stepTitle === "Consultation Info" && (
                  <>
                    <h5 className="text-center mb-5">
                      Please provide your consultation information below
                    </h5>
                    <div className="mx-5 px-5 first_page_container">
                      <Box className="row gy-1 overflow-hidden">
                        <div className="wave-group">
                          <i className="bi bi-currency-rupee icon"></i>
                          <input
                            type="text"
                            className="input"
                            name="priceConsult"
                            id="priceConsult"
                            required
                            onChange={(e) => setPriceConsult(e.target.value)}
                            value={priceConsult}
                          />
                          <span className="bar"></span>
                          <label className="label">
                            {"Price Per Consultation"
                              .split("")
                              .map((char, index) => (
                                <span
                                  className="label-char"
                                  style={{ "--index": index }}
                                  key={index}
                                >
                                  {char === " " ? "\u00A0" : char}
                                </span>
                              ))}
                          </label>
                        </div>

                        <div className="wave-group">
                          <i className="bi bi-credit-card icon"></i>
                          <input
                            type="text"
                            className="input"
                            name="upiID"
                            id="upiID"
                            onChange={(e) => setUpiID(e.target.value)}
                            value={upiID}
                            required
                          />
                          <span className="bar"></span>
                          <label className="label">
                            {"UPI ID".split("").map((char, index) => (
                              <span
                                className="label-char"
                                style={{ "--index": index }}
                                key={index}
                              >
                                {char === " " ? "\u00A0" : char}
                              </span>
                            ))}
                          </label>
                        </div>

                        <Box className="col-12">
                          <FormControl
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            error={languageError}
                            required
                          >
                            <InputLabel id="language-label">
                              Select Languages
                            </InputLabel>
                            <Select
                              labelId="language-label"
                              id="language"
                              name="Language"
                              multiple
                              onChange={handleLanguageChange}
                              value={language}
                              input={
                                <OutlinedInput
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <i className="bi bi-translate"></i>
                                    </InputAdornment>
                                  }
                                  label="Select Languages"
                                  id="select-multiple-chip"
                                />
                              }
                              renderValue={(selected) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                  }}
                                >
                                  {selected.map((value) => {
                                    const option = languageOptions.find(
                                      (opt) => opt.value === value
                                    );
                                    return (
                                      <Chip
                                        key={value}
                                        label={option ? option.label : value}
                                      />
                                    );
                                  })}
                                </Box>
                              )}
                            >
                              <MenuItem disabled value="">
                                <em>Select atleast 1 language</em>
                              </MenuItem>
                              {languageOptions.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {languageError && (
                              <FormHelperText>
                                Please select at least one language
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                        <Box className="col-12">
                          <FormControl
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            error={expertiseError}
                            required
                          >
                            <InputLabel id="language-label">
                              Select Expertise Areas
                            </InputLabel>
                            <Select
                              labelId="expertise-label"
                              id="expertise"
                              name="expertise"
                              multiple
                              onChange={handleExpertiseChange}
                              value={expertise}
                              input={
                                <OutlinedInput
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <i className="bi bi-award-fill"></i>
                                    </InputAdornment>
                                  }
                                  label="Select Expertise Areas"
                                  id="select-multiple-chip"
                                />
                              }
                              renderValue={(selected) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                  }}
                                >
                                  {selected.map((value) => {
                                    const option = expertiseOptions.find(
                                      (opt) => opt.value === value
                                    );
                                    return (
                                      <Chip
                                        key={value}
                                        label={option ? option.label : value}
                                      />
                                    );
                                  })}
                                </Box>
                              )}
                            >
                              <MenuItem disabled value="">
                                <em>Select atleast 1 expertise area</em>
                              </MenuItem>
                              {expertiseOptions.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {expertiseError && (
                              <FormHelperText>
                                Please select at least one expertise area
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                    </div>
                  </>
                )}
                {stepTitle === "Verification" && (
                  <>
                    <h5 className="text-center mb-5">
                      Please upload required documents for verification
                    </h5>

                    <Box className="col-12 mb-4">
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        direction="column"
                        spacing={2}
                      >
                        <Grid item>
                          <Avatar
                            alt="Identity Proof"
                            variant="square"
                            src={image1}
                            sx={{ width: 100, height: 100, bgcolor: "#94938f" }}
                          >
                            {!image1 && <UploadFileIcon fontSize="medium" />}
                          </Avatar>
                        </Grid>
                        <Grid item>
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="identity-upload"
                            type="file"
                            onChange={(e) => handleImageChange(e, setImage1)}
                          />
                          <label htmlFor="identity-upload">
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                            >
                              <Button
                                variant="contained"
                                component="span"
                                startIcon={<BackupIcon />}
                                sx={{
                                  backgroundColor: "#00296b",
                                  color: "#ffffff",
                                  "&:hover": { backgroundColor: "#001f54" },
                                }}
                              >
                                Upload Image
                              </Button>
                              <FormHelperText>
                                Upload an identity proof (e.g., Aadhar Card).
                              </FormHelperText>
                            </Box>
                          </label>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box className="col-12">
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        direction="column"
                        spacing={2}
                      >
                        <Grid item>
                          <Avatar
                            alt="Professional Proof"
                            variant="square"
                            src={image2}
                            sx={{ width: 100, height: 100, bgcolor: "#94938f" }}
                          >
                            {!image2 && <UploadFileIcon fontSize="medium" />}
                          </Avatar>
                        </Grid>
                        <Grid item>
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="professional-upload"
                            type="file"
                            onChange={(e) => handleImageChange(e, setImage2)}
                          />
                          <label htmlFor="professional-upload">
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                            >
                              <Button
                                variant="contained"
                                component="span"
                                startIcon={<BackupIcon />}
                                sx={{
                                  backgroundColor: "#00296b",
                                  "&:hover": { backgroundColor: "#001f54" },
                                }}
                              >
                                Upload Image
                              </Button>
                              <FormHelperText>
                                {selectedProfession === 'true'
                                  ? "Upload a professional proof (e.g., RCI License)."
                                  : "Upload your Student ID card"}
                              </FormHelperText>
                            </Box>
                          </label>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                )}
                <div className="btn-toolbar justify-content-center mt-5">
                  {index !== 0 && (
                    <button
                      type="button"
                      className="btn btn-primary rounded-pill ml-1 mb-5 "
                      onClick={handleBack}
                      style={{
                        backgroundColor: "#00296b",
                        borderColor: "#00296b",
                        marginRight: "50px",
                      }}
                    >
                      <i className="far fa-arrow-alt-circle-left"></i> Back
                    </button>
                  )}
                  {index !== 2 ? (
                    <button
                      type="button"
                      className="btn btn-primary rounded-pill ml-1 mb-5"
                      role="button"
                      onClick={handleNext}
                      disabled={!validateStep()}
                      style={{
                        backgroundColor: "#00296b",
                        borderColor: "#00296b",
                      }}
                    >
                      Next <i className="far fa-arrow-alt-circle-right"></i>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary rounded-pill ml-1 mb-5"
                      onClick={handleSubmit}
                      disabled={!validateStep()}
                      style={{
                        backgroundColor: "#00296b",
                        borderColor: "#00296b",
                      }}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            )
          )}
        </div>
        <p className="small m-5 text-justify">
          <span className="text-primary font-weight-bold">
            Important Notice:
          </span>{" "}
          While we verify certain key information (police checks, key
          qualifications, references) during the on-boarding of each Support
          Worker, Support Worker’s represent other information about themselves
          to customers via their profiles that we do not independently verify.
          In exercising your choice of Support Worker(s) and hiring them
          directly, we recommend that you check their credentials and original
          documents when you first meet them, and provide ongoing supervision.
          We recommend that Support Workers take a file of their key documents
          with them to their first meeting.
        </p>
      </div>
    </>
  );
}
