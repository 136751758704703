import React from 'react'
import privacyimg from '../../Assets/Privacy policy-bro.png'
import './PrivacyPolicy.css'
import HomeNavbar from '../../Components/Navbar';

const PrivacyPolicy = () => {
    return (
        <>
            <HomeNavbar />
            <div className="privacy_image_container">
                <div className="privacy_image">
                    <img src={privacyimg} alt="privacypolicy" />
                </div>
                <div className="privacy_text">
                    <h1 className="privacy_heading">Privacy Policy</h1>
                    <p className="privacy_paragraph">
                        Learn about our practices and find answers to your questions regarding how we handle and protect your personal information.
                    </p>
                </div>
            </div>
            <div className='privacypolicy_details_container'>
                <div className='common_privacy_policy_para'>
                    <span className='privacypolicy_details_heading'>Our Policy<span className="logo-dot">.</span></span>
                    <p className='privacy_first_para'>
                        At Thapy, we are committed to respecting the privacy and confidentiality of the information that you entrust us with. Our Privacy Policy outlines the policies and procedures regarding the collection, use and disclosure of Personal Information from users. Please review this Privacy Policy carefully. In order to guarantee privacy to the client, we maintain the client’s anonymity and work in accordance with confidentiality policies to ensure that all personal and health information received is maintained and transmitted through a highly secure environment. It is recommended that you do not use the website, mobile application(s) or the related Services if any of the terms of this Privacy Policy are not in accordance with the applicable laws of your country.
                    </p>
                </div>
                <div className="container-policy">
                    <div className="card">
                        <div className="card-body">
                            <div className='privacypolicy_points'>
                                <span className='privacypolicy_points_heading'>
                                    <span className='privacypolicy_points_number'>1. </span>Nature of Services
                                </span>
                                <p>
                                    Thapy is a psychological wellness platform that delivers emotional wellness products and Services to individuals and organizations. These include, but are not restricted to, organizational wellness programs through which employees of organizations or individuals affiliated with organizations avail various products and Services.
                                    Thapy offerings may include/are:
                                </p>
                                <ul className='custom-list'>
                                    <li>
                                        Online consultation with expert Therapist (who have been authorized by Thapy to use the platform for delivering their Services)
                                    </li>
                                    <li>Periodic self-assessments and psychological tests</li>
                                    <li>Workshops and/or webinars delivered by trained Therapist</li>
                                    <li>
                                        Self-help tools, content and programs delivered through a range of channels including, but not restricted to websites, mobile applications and emails
                                    </li>
                                    <li>
                                        Guide chat packs where in clients may be able to exchange encrypted private messages with their Therapist in addition to online consultation
                                    </li>
                                    <li>
                                        Participation in user forums, topics, replies, comments, feedback, blogs, reels and any other submissions on the platform
                                    </li>
                                    <li>
                                        Talking to peers is a facility which we provide for affordable price
                                    </li>
                                    <li>
                                        A curated shop offering a variety of products such as books, tools, and other resources aimed at improving mental health and well-being. These products can be purchased based on professional(s) recommendations or personal preference.
                                    </li>
                                    <p>
                                        Thapy reserves the right to add or remove products and Services from its overall offerings without prior notification. The aforementioned shall hereinafter be referred to as “Services”.
                                    </p>
                                </ul>


                            </div>
                            <div className='privacypolicy_points'>
                                <span className='privacypolicy_points_heading'>
                                    <span className='privacypolicy_points_number'>2. </span>Information We Collect
                                </span>
                                <ul className='custom-list'>
                                    <li className='list_spacing'>
                                        <span>Personal Information : </span>When you register an account on Thapy, we may collect personal information such as your name, email address, date of birth, gender, and payment information.
                                    </li>
                                    <li>
                                        <span>Usage Information : </span>We may collect information about how you interact with our app, including the pages you visit, the features you use, and the frequency and duration of your activities.
                                    </li>
                                    <li>
                                        <span>Device Information : </span>We may collect information about the device you use to access Thapy, including your device type, operating system, and unique device identifiers.
                                    </li>

                                </ul>


                            </div>



                            <div className='privacypolicy_points'>
                                <span className='privacypolicy_points_heading'>
                                    <span className='privacypolicy_points_number'>3. </span>How We Use Your Information:
                                </span>
                                <p>
                                    Thapy uses your information to:
                                </p>
                                <ul className='custom-list'>
                                    <li>
                                        <span>Support You : </span>We use your data to respond to inquiries, provide Thapy services, and process your requests.
                                    </li>
                                    <li>
                                        <span>Enhance Your Experience : </span> We personalize your Thapy journey and send relevant offers (with your permission).
                                    </li>
                                    <li>
                                        <span>Improve Thapy : </span> Data analysis helps us improve Thapy's functionality and user experience.
                                    </li>
                                    <li>
                                        <span>Safety and Security : </span> We may use your information to keep you informed about Thapy updates or important legal notices.
                                    </li>
                                    <li>
                                        <span>Research and Development : </span> In some cases, we may use anonymized data for research purposes, always with your consent.
                                    </li>
                                    <li>
                                        <span>With Therapists and Counselors : </span> We may share your information with therapists and   counselors on Thapy to facilitate your sessions and ensure quality care.
                                    </li>

                                </ul>
                                <p>
                                    We may, when permitted, combine your Personal Data with other information, whether online or offline, maintained and available to use from you or from other sources, and we may use and disclose the combined data for the purposes described in this Section or for internal business purposes. We may, when permitted, use and disclose anonymized and aggregated data for any purpose, which may include, without limitation, disclosures to third parties for analytics purposes, such as evaluating the effectiveness of Online Services or providing additional benefits, programs and services. We may use your Personal Data for internal research purposes, and we may share such information with third party research partners who are bound to maintain the confidentiality of any and all personally identifiable information and use it only for such research purposes as described under “How We Share Personal Data”.
                                </p>
                            </div>

                            <div className='privacypolicy_points'>
                                <span className='privacypolicy_points_heading'>
                                    <span className='privacypolicy_points_number'>4. </span>Data Security
                                </span>
                                <p>
                                    We take appropriate measures to safeguard your personal information and protect it from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
                                </p>

                            </div>

                            <div className='privacypolicy_points'>
                                <span className='privacypolicy_points_heading'>
                                    <span className='privacypolicy_points_number'>5. </span>Cookies
                                </span>
                                <p>
                                    We use “cookies” to collect information and smoothen your experience on our platform. A cookie is a small data file that we transfer to your device’s hard disk for record-keeping purposes. We use cookies for two purposes. First, we may utilise persistent cookies to save your user credentials for future logins to the Services. Second, we may utilise session ID cookies to enable certain features of the Services, to better understand how you interact with the Services and to monitor aggregate usage by users of the Services and online traffic routing on the Services. Unlike persistent cookies, session cookies are deleted from your computer when you log off from the Services and then close your browser.
                                    <p>
                                        We may work with third parties that place or read cookies on your browser to improve your user experience. In such cases, by using the third-party services through our platform, you consent to their Privacy Policy and terms of use and agree not to hold Amaha liable for any issues arising from such use.
                                    </p>

                                    <p>
                                        You can instruct your browser, by changing its options, to stop accepting cookies or to prompt you before accepting a cookie from the websites you visit. If you do not accept cookies, however, you may not be able to use all features or functionalities of the platform.
                                    </p>


                                </p>

                            </div>

                            <div className='privacypolicy_points'>
                                <span className='privacypolicy_points_heading'>
                                    <span className='privacypolicy_points_number'>6. </span>Thapy Account Management
                                </span>
                                <p>
                                    You have the option to deactivate your Thapy account at any time. While your profile will no longer be active, we may retain certain limited data to comply with legal requirements or for internal administrative purposes. You may also choose to opt out of receiving promotional communications from us.
                                </p>

                            </div>

                            <div className='privacypolicy_points'>
                                <span className='privacypolicy_points_heading'>
                                    <span className='privacypolicy_points_number'>7. </span>Confidentiality
                                </span>
                                <p>
                                    Thapy prioritizes your privacy. Information shared in therapy sessions is confidential and will not be disclosed without your consent, except in rare circumstances:
                                </p>
                                <ul className='custom-list'>
                                    <li>
                                        <span>Safety Risk : </span>: If there's a serious threat of harm to yourself or others.
                                    </li>
                                    <li>
                                        <span>Legal Requirement : </span> If compelled by law (e.g., court order).
                                    </li>
                                </ul>
                                <p>
                                    Your therapist may keep confidential session notes as required by professional standards.
                                </p>
                            </div>

                            <div className='privacypolicy_points'>
                                <span className='privacypolicy_points_heading'>
                                    <span className='privacypolicy_points_number'>8. </span>Sharing wisely
                                </span>
                                <p>
                                    Thapy protects your info, but public forums within the app are NOT private. Be cautious about what you share. Thapy is not responsible for misuse of information you choose to make public. Data security is a priority, but no online system is foolproof.
                                </p>
                                <p>
                                    Use Thapy responsibly. Keep your personal info private!
                                </p>
                            </div>

                            <div className='privacypolicy_points'>
                                <span className='privacypolicy_points_heading'>
                                    <span className='privacypolicy_points_number'>9. </span>Changes to This Privacy Policy
                                </span>
                                <p>
                                    Thapy connects you with licensed therapists for mental health support. We strive for quality, but can't guarantee specific outcomes or the advice provided. Therapists are independent and Thapy isn't liable for their actions. Calls are not recorded, but therapists may keep confidential session notes as required by professional standards.
                                    <p>
                                        By continuing to use Thapy after any updates to this Privacy Policy, you acknowledge and agree to the revised terms. This signifies your continued consent to the collection, use, and processing of your personal information as outlined in the updated Privacy Policy.
                                    </p>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default PrivacyPolicy

