import React, { useRef, useContext } from "react";
import { ArrowBackIos, ArrowForwardIos, Star } from "@mui/icons-material";
import "./TherapistsPage.css";
import Sidebar from '../sidebar/Sidebar';
import Navbar from '../navbar/navbar';
import { AuthContext } from "../../Context/AuthContext";
import { useLocation } from 'react-router-dom';
import UserBookingAppCal from '../UserBookingAppointmentCal/UserBookingAppCal';

const TherapistsPage = () => {
    const reviewCardsRef = useRef(null);
    const { currentUser } = useContext(AuthContext);
    const location = useLocation();
    const state = location.state || {};

    const profile = state.psychologist;
    if (!profile) {
        return <div>No profile data available</div>;
    }

    const scrollLeft = () => {
        if (reviewCardsRef.current) {
            reviewCardsRef.current.scrollBy({ left: -300, behavior: "smooth" });
        }
    };

    const scrollRight = () => {
        if (reviewCardsRef.current) {
            reviewCardsRef.current.scrollBy({ left: 300, behavior: "smooth" });
        }
    };

    const renderStars = (rating) => {
        const totalStars = 5; // Total number of stars
        const filledStars = Math.round(rating); // Number of filled stars based on rating

        return [...Array(totalStars)].map((_, index) => (
            <Star
                key={index}
                sx={{ color: index < filledStars ? "#fcb001" : "#ccc" }} // Fill star if index < filledStars
            />
        ));
    };

    return (
        <>
            <div className='user_home'>
                <Sidebar userType={'user'} />
                <div className='homeContainer'>
                    <Navbar userData={currentUser} userType={'user'} />
                    {/* <div className="detailsPageContainer"> */}
                    <div className="therapist-page-container">
                        <div className="left-section">
                            <img
                                className="profile-image"
                                src={profile.imagePath}
                                alt={profile.name}
                            />
                            <div className="details">
                                <h2>{profile.areas.join(', ')}</h2>
                                <p className="bluepara">{profile.status}</p>
                                <h3>Rated by {profile.noOfRatings} clients</h3>
                                <div className="divider"></div>
                            </div>
                            <div className="languageandprice">
                                <div className="language">
                                    <h3 className="lnghead">Languages</h3>
                                    <p className="lngpara">{profile.languages.join(', ')}</p>
                                </div>
                                <div className="price-card">
                                    <h3 className="lnghead">Price</h3>
                                    <p className="lngpara">₹{profile.price}/hr</p>
                                </div>
                            </div>
                        </div>

                        {/* Right Section */}
                        <div className="right-section">
                            <h1 className="nameheading">{profile.professional ? `Dr. ${profile.name}` : profile.name}</h1>
                            <p className="role">{profile.professional ? 'Therapist' : 'Peer'}</p>
                            <div className="ratings">
                                <h3 className="ratingHeading">Ratings</h3>
                                <div className="stars">
                                    {renderStars(profile.overallRating)}
                                </div>
                            </div>
                            <div className="action-buttons">
                                <button className="profilebuttons">Call</button>
                                <button className="profilebuttons">Video</button>
                                <button className="profilebuttons">Message</button>
                            </div>
                            <div className="about-section">
                                <h3 className="abtheading">About</h3>
                                <p>
                                    {profile.about}
                                </p>
                            </div>
                            <div className="reviews">
                                <h3 className="revheading">Reviews</h3>
                                <div className="review-controls">
                                    <ArrowBackIos onClick={scrollLeft} className="scroll-arrow" />
                                    <div className="review-cards-container" ref={reviewCardsRef}>
                                        {/* Review Cards */}
                                        {[...Array(5)].map((_, index) => (
                                            <div className="reviewcard" key={index}>
                                                <div className="verified_customer_section">
                                                    <div className="image_review">
                                                        <div className="customer_image">
                                                            <img
                                                                src="https://cdn.shopify.com/s/files/1/0664/2191/5900/t/8/assets/screenshot-20221121-alle-191353-1669054447602.png?v=1669054450"
                                                                alt="customer"
                                                            />
                                                        </div>
                                                        <div className="customer_name_review_status">
                                                            <div className="customer_name">Olivia Smith</div>
                                                            <div className="customer_review">
                                                                {[...Array(5)].map((_, starIndex) => (
                                                                    <Star key={starIndex} sx={{ color: "gold" }} />
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="customer_comment">
                                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                        Corporis beatae impedit minima quibusdam veritatis expedita
                                                        nihil voluptates eligendi? Repudiandae, eius!
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <ArrowForwardIos onClick={scrollRight} className="scroll-arrow" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {profile.professional && <UserBookingAppCal therapist={profile} />}
                    {/* </div> */}
                </div>
            </div>
        </>

    );
};

export default TherapistsPage;
