import React from 'react';
import { Link } from "react-router-dom";
import './style.css';

export default function FooterComp() {
    return (
        <footer id="footer" className="footer">
            <div className="container-footer footer-top">
                <div className="row gy-4">
                    <div className="col-xxl-5 col-lg-5 col-md-12 footer-about">
                        <Link to="/" className="logo d-flex align-items-center">
                            <span>Thapy<span className="logo-dot">.</span></span>
                        </Link>
                        <p className="about-text">Thapy is a mental health app that connects users with the best and most affordable therapists/peers according to their needs at the comfort of your home. Making therapy as easy as ordering your favourite pizza.</p>
                        <div className="social-links d-flex mt-4">
                            <Link to="/" aria-label="Twitter"><i className="bi bi-twitter"></i></Link>
                            <Link to="/" aria-label="Facebook"><i className="bi bi-facebook"></i></Link>
                            <Link to="/" aria-label="Instagram"><i className="bi bi-instagram"></i></Link>
                            <Link to="https://www.linkedin.com/company/thapy/" aria-label="LinkedIn"><i className="bi bi-linkedin"></i></Link>
                        </div>
                    </div>

                    <div className="col-lg-2 col-6 footer-links">
                        <h4>Useful Links</h4>
                        <ul>
                            <li><Link to="/privacyPolicy">Privacy Policy</Link></li>
                            <li><Link to="/termsandconditions">Terms & Conditions Users</Link></li>
                            <li><Link to="/therapistTerms">Terms & Conditions Therapists</Link></li>
                            <li><Link to="/peerTerms">Terms & Conditions Peers</Link></li>
                        </ul>
                    </div>

                    <div className="col-lg-2 col-6 footer-links">
                        <h4>Our Services</h4>
                        <ul>
                            <li><Link to="/">Search for Therapists & Peers</Link></li>
                            <li><Link to="/contact">Contact Us</Link></li>
                            <li><Link to="/faq">FAQs</Link></li>
                            <li><Link to="/">Careers</Link></li>
                        </ul>
                    </div>

                    <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                        <h4>Contact Us</h4>
                        <p>Manipal Insitute of Technology</p>
                        <p>Manipal District, 576104</p>
                        <p>Karnataka, India</p>

                        <p className="mt-4"><strong>Phone:</strong> <span>+91 865 742 0799</span></p>
                        <p><strong>Email:</strong> <span>thapyworkspace@gmail.com</span></p>
                    </div>
                </div>
            </div>

            <div className="container copyright text-center mt-0">
                <p>Copyright &copy; 2024,<strong>Thapy</strong>. All rights reserved.</p>
                <div className="credits">
                    Designed by <Link to="/">Thapy</Link>
                </div>
            </div>
        </footer>
    );
}
