import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { AuthContext } from "../../../Context/AuthContext";
import Sidebar from '../../../Components/sidebar/Sidebar';
import Navbar from '../../../Components/navbar/navbar';
import DBHandler from "../../../Utils/DBHandler";
import Loader from '../../../Components/Loader/Loader';
import './style.css';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TherapistProfileCard from '../../../Components/TherapistProfile/TherapistProfileCard';

export default function TherapistList() {
    const [therapistData, setTherapistData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [lastDoc, setLastDoc] = useState(null);
    const [loadingMore, setLoadingMore] = useState(false);
    const { currentUser } = useContext(AuthContext);
    const isFetching = useRef(false);

    const fetchTherapists = useCallback(async () => {
        if (isFetching.current) return;
        isFetching.current = true;
        setLoadingMore(true);

        try {
            const dbHandler = new DBHandler();
            const { psychologists: newPsychologists, lastDocument } = await dbHandler.fetchPsychologistListLazy(true, 10, lastDoc);

            if (newPsychologists.length > 0) {
                setTherapistData(prevData => [...prevData, ...newPsychologists]);
                setLastDoc(lastDocument);
            }
        } catch (error) {
            toast.error('Error fetching therapists. Kindly try again later!!', { containerId: 'therapistListPage' });
        } finally {
            setLoadingMore(false);
            isFetching.current = false;
        }
    }, [lastDoc]);

    useEffect(() => {
        setLoading(true);
        fetchTherapists().then(() => setLoading(false));
    }, [fetchTherapists]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.scrollHeight - 50 && !loadingMore) {
                fetchTherapists();
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [fetchTherapists, loadingMore]);

    return (
        <>
            <ToastContainer
                containerId="therapistListPage"
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className='consultant_home'>
                <Sidebar userType={'user'} />
                <div className='Consultant_home_container'>
                    <Navbar userData={currentUser} userType={'user'} />
                    {loading && <Loader />}
                    <h2 className='consultant_call_heading'>Our Therapists</h2>
                    <hr className="divider" />
                    <TherapistProfileCard psychologist={therapistData} psyType={true} isHome={false} />
                    {loadingMore && <Loader />}
                </div>
            </div>
        </>
    );
}
