import React, { useState, useEffect, useContext, useMemo } from "react";
import Navbar from "../navbar/navbar";
import Sidebar from "../sidebar/Sidebar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import dayjs from "dayjs";
import DBHandler from "../../Utils/DBHandler";
import { AuthContext } from "../../Context/AuthContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Loader/Loader';
import "./AppointmentCalendar.css";


//TODO: Implement the removal of previous added timeslots that are passed automatically

const AppointmentCalendar = () => {
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [appointments, setAppointments] = useState({});
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [loading, setLoading] = useState(false);
  const dbHandler = useMemo(() => new DBHandler(), []);
  const { currentUser } = useContext(AuthContext);

  const generateTimeSlots = () => {
    const hours = Array.from({ length: 18 }, (_, i) => 6 + i); // 6 AM to 11 PM
    const slots = [];

    hours.forEach(hour => {
      const formattedHour = hour.toString().padStart(2, '0');
      slots.push(`${formattedHour}:00`);
      slots.push(`${formattedHour}:30`);
    });

    return slots;
  };

  const timeslots = generateTimeSlots();

  const isFutureSlot = (time) => {
    const slotDateTime = dayjs(`${selectedDate.format("YYYY-MM-DD")} ${time}`, 'YYYY-MM-DD HH:mm');
    return slotDateTime.isAfter(dayjs().add(6, 'hour'));
  };

  useEffect(() => {
    const fetchTimeSlots = async () => {
      setLoading(true);
      try {
        const dateKey = selectedDate.format("YYYY-MM-DD");
        const timeSlots = await dbHandler.getPsychologistTimeSlotsForDate(currentUser.id, dateKey);
        setAppointments((prev) => ({
          ...prev,
          [dateKey]: timeSlots || [],
        }));
      } catch (error) {
        toast.error("Error fetching time slots. Please try again later!!", { containerId: 'appointmentCalendar' });
      } finally {
        setLoading(false);
      }
    };

    fetchTimeSlots();
  }, [dbHandler, selectedDate, currentUser.id]);

  const handleSlotClick = (time) => {
    if (!isFutureSlot(time)) {
      toast.error("The selected time slot must be at least 6 hours in the future!!", { containerId: 'appointmentCalendar' });
      return;
    }
    if (selectedSlots.includes(time)) {
      setSelectedSlots(selectedSlots.filter((slot) => slot !== time));
    } else {
      setSelectedSlots([...selectedSlots, time]);
    }
  };

  const handleSave = async () => {
    try {
      const dateKey = selectedDate.format("YYYY-MM-DD");

      const newSlots = selectedSlots.map(time => ({
        time,
        status: 'Available',
      }));

      const existingSlots = appointments[dateKey] || [];
      const combinedSlots = [
        ...existingSlots,
        ...newSlots
      ].filter((slot, index, self) =>
        index === self.findIndex((t) => (
          t.time === slot.time
        ))
      );

      setAppointments(prev => ({
        ...prev,
        [dateKey]: combinedSlots,
      }));

      await dbHandler.updatePsychologistTimeSlots(currentUser.id, dateKey, combinedSlots);

      setSelectedSlots([]);
      toast.success("Calendar updated successfully!", {
        containerId: "appointmentCalendar"
      });
    } catch (error) {
      toast.error("Failed to update calendar. Please try again.", {
        containerId: "appointmentCalendar"
      });
    }
  };



  const handleCancel = async () => {
    try {
      const dateKey = selectedDate.format("YYYY-MM-DD");

      const updatedAppointments = (appointments[dateKey] || []).filter(
        (slot) => !selectedSlots.includes(slot.time)
      );

      setAppointments((prev) => ({
        ...prev,
        [dateKey]: updatedAppointments,
      }));

      const updatedSlots = updatedAppointments.map(slot => slot.time);
      await dbHandler.removePsychologistTimeSlots(currentUser.id, dateKey, updatedSlots);

      setSelectedSlots([]);

      toast.success("Calendar updated successfully!", {
        containerId: "appointmentCalendar"
      });
    } catch (error) {
      toast.error("Failed to update calendar. Please try again.", {
        containerId: "appointmentCalendar"
      });
    }
  };


  const isSlotSelected = (time) => selectedSlots.includes(time);
  const isSlotBooked = (date, time) => appointments[date]?.some(slot => slot.time === time);

  return (
    <>
      <ToastContainer
        containerId="appointmentCalendar"
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className='consultant_home'>
        <Sidebar userType={'therapist'} />
        <div className='Consultant_home_container'>
          <Navbar userData={currentUser} userType={'therapist'} />
          {loading && <Loader />}
          <div className="calendar_container">
            <div className="calendar">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  disablePast={true}
                  openTo="day"
                  value={selectedDate}
                  onChange={(newValue) => {
                    setSelectedDate(newValue);
                  }}
                  maxDate={dayjs().add(15, "day")}
                  renderInput={(params) => <div {...params} />}
                  componentsProps={{
                    actionBar: {
                      sx: {
                        "& .MuiPickersToolbar-root": {
                          color: "#fff",
                          backgroundColor: "#0496ff",
                        },
                        "& .MuiPickersDay-root": {
                          "&:hover": {
                            backgroundColor: "#0496ff",
                            color: "#fff",
                          },
                        },
                        "& .Mui-selected": {
                          backgroundColor: "#0496ff !important",
                          color: "#fff",
                        },
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="timeslots_container">
              <h2 className="timeslot_main_heading">
                Add Appointment Slots
                <Tooltip arrow title="You can set your available time slots for appointments. Please note that each appointment call lasts for 1 hour, so plan your slots accordingly." placement="top">
                  <InfoOutlinedIcon className="info-icon" />
                </Tooltip>
              </h2>
              <div className="timeslots">
                {timeslots.map((time) => (
                  <div
                    key={time}
                    className={`timeslot ${isSlotSelected(time)
                      ? "selected"
                      : isSlotBooked(selectedDate.format("YYYY-MM-DD"), time)
                        ? "filled"
                        : ""
                      }`}
                    onClick={() => handleSlotClick(time)}
                  >
                    {time}
                  </div>
                ))}
              </div>
              <div className="timeslots_buttons">
                <button className="savebutton save_button" onClick={handleSave}>Save</button>
                <button className="cancelbutton cancel_button" onClick={handleCancel}>Remove</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppointmentCalendar;
