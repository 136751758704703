import React, { useState, useEffect, useContext } from 'react';
import "./home.css";
import { useNavigate } from 'react-router-dom';
import Navbar from '../../../Components/navbar/navbar';
import { AuthContext } from "../../../Context/AuthContext";
import Sidebar from '../../../Components/sidebar/Sidebar';
import welcomimg from '../../../Assets/welcome.jpg';
import therapistimg from '../../../Assets/therapists.png';
import peerimg from '../../../Assets/peer.png';
import HomeTherapistCards from '../../../Components/HomeTherapistsCards/HomeTherapistCards';
import HomePeersCards from '../../../Components/HomePeersCards/HomePeersCards';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function UserHome() {
  const { currentUser } = useContext(AuthContext);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();

  const welcomeItems = [
    {
      img: welcomimg,
      title: `Welcome ${currentUser.name}`,
      text: `Welcome to THAPY! Connect with top therapists and peers from the comfort of your home. We make therapy as easy as ordering your favorite pizza. Remember, "You Talk, We Listen."`,
      buttonText: 'Instant Call',
      buttonLink: '/user/instantcall',
    },
    {
      img: therapistimg,
      title: 'Professional Therapy',
      text: 'Connect with a professional, find support, and take steps towards wellness.',
      buttonText: 'Know More',
      buttonLink: '/user/therapist',
    },
    {
      img: peerimg,
      title: 'Peer Support',
      text: 'Talk with your friend, ease your mind, and feel the stress melt away.',
      buttonText: 'Know More',
      buttonLink: '/user/peer',
    },
  ];

  const handleBtnClick = (url, buttonText) => {
    if (buttonText === 'Instant Call') {
      toast.info('Coming soon!', { containerId: 'userHome' });
    } else {
      navigate(url);
    }
  };


  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % welcomeItems.length);
    }, 4000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [welcomeItems.length]);

  return (
    <>
      <div className='user_home'>
        <Sidebar userType={'user'} />
        <div className='homeContainer'>
          <ToastContainer
            containerId="userHome"
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <Navbar userData={currentUser} userType={'user'} />
          <div className='userWelcomeContainer'>
            <div className='carousel' style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
              {welcomeItems.map((item, index) => (
                <div className='userWelcomeCard' key={index}>
                  <div className='userWelcome'>
                    <div className='userWelcomeImg'>
                      <img src={item.img} alt={item.title} />
                    </div>
                    <div className='userWelcomeText'>
                      <h3>{item.title}</h3>
                      <p>{item.text}</p>
                      {item.buttonText && (
                        <button
                          className='userbtn'
                          onClick={() => handleBtnClick(item.buttonLink, item.buttonText)}
                        >
                          {item.buttonText}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <HomeTherapistCards />
          <HomePeersCards />
        </div>
      </div>
    </>
  );
}
