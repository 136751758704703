import React, { useContext, useState, useMemo, useCallback } from 'react';
import { Rating } from '@mui/material';
import { FaStar, FaVideo, FaCoins, FaMicrophone } from 'react-icons/fa';
import './BookingConfirmation.css';
import Sidebar from '../sidebar/Sidebar';
import Navbar from '../navbar/navbar';
import { useLocation } from 'react-router-dom';
import { AuthContext } from "../../Context/AuthContext";

const BookingConfirmation = () => {
  const { currentUser } = useContext(AuthContext);
  const location = useLocation();
  const { therapist, selectedTime, selectedDate, selectedMode } = location.state || {};
  const [useCredits, setUseCredits] = useState(false);

  // Memoized function to convert time to IST
  const convertToIST = useCallback((timeString) => {
    const [hour, minute] = timeString.split(':').map(Number);
    const date = new Date();
    date.setHours(hour, minute, 0);

    return date.toLocaleTimeString('en-US', {
      timeZone: 'Asia/Kolkata',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  }, []);

  // Memoized function to calculate charges
  const calculateCharge = useCallback((price) => {
    const taxCharge = price * 0.01;
    const serviceCharge = 50;
    return parseFloat((price + taxCharge + serviceCharge).toFixed(2));
  }, []);

  // Memoized function to calculate payable amount and credit discount
  const { payableAmount, creditDiscount } = useMemo(() => {
    const charge = calculateCharge(therapist.price);
    const creditBalance = currentUser.creditEarned - currentUser.creditRedeemed;
    let creditDiscount = useCredits ? Math.min(creditBalance, charge) : 0;

    // Calculate payable amount and adjust if necessary
    let payableAmount = Math.max(charge - creditDiscount, 1);
    if (payableAmount === 1 && useCredits) {
      creditDiscount = Math.min(creditBalance, creditDiscount - 1);
      payableAmount = 1;
    }

    return {
      payableAmount: payableAmount.toFixed(2),
      creditDiscount: creditDiscount.toFixed(2)
    };
  }, [calculateCharge, therapist.price, currentUser.creditEarned, currentUser.creditRedeemed, useCredits]);

  // Memoized credit balance calculation
  const creditBalance = useMemo(() => currentUser.creditEarned - currentUser.creditRedeemed, [currentUser]);

  return (
    <div className='user_home'>
      <Sidebar userType={'user'} />
      <div className='homeContainer'>
        <Navbar userData={currentUser} userType={'user'} />
        <div className="bookingconf_cont">
          {/* Therapist Details */}
          <div className="booking_user_card">
            <div className='bookimg_cont'>
              <img src={therapist.imagePath} alt={therapist.name} className="user-image" />
            </div>
            <div className="user_details">
              <h2 className="therapist-name">Dr. {therapist.name}</h2>
              <h3 className="therapist-subheading">Therapist</h3>
              <p className="therapist-areas">{therapist.areas.join(', ')}</p>
              <p className="therapist-languages">{therapist.languages.join(', ')}</p>
              <div className="therapist-rating">
                <Rating
                  name="therapist-rating"
                  value={therapist.rating}
                  precision={0.5}
                  readOnly
                  size="large"
                  emptyIcon={<FaStar style={{ color: '#dcdcdc' }} />}
                />
              </div>
            </div>
          </div>

          {/* Session Details */}
          <div className="session_details">
            <h2 className="session-heading">Session Details</h2>
            <div className="session-info">
              {selectedMode === 'Video' ? <FaVideo className="video-icon" /> : <FaMicrophone className="video-icon" />}
              <div className="session-text">
                <p className="session-type">{selectedMode === 'Video' ? 'Video Consultation' : 'Audio Consultation'}</p>
                <p className="session-date">
                  @{new Date(selectedDate).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })} {convertToIST(selectedTime)}
                </p>
              </div>
            </div>
          </div>

          {/* Bill Details */}
          <div className='bill_container'>
            <h2 className="bill-heading">Bill Details</h2>
            <div className="bill-item">
              <span>Consultation Fee</span>
              <span className='span_bold'>₹{therapist.price.toFixed(2)}</span>
            </div>
            <div className="bill-item">
              <span>Service Fee</span>
              <span className='span_bold'>₹50.00</span>
            </div>
            <div className="bill-item bill_divider">
              <span>Tax</span>
              <span className='span_bold'>1.00%</span>
            </div>
            <div className="bill-item total">
              <span>Total Payable</span>
              <span>₹{calculateCharge(therapist.price).toFixed(2)}</span>
            </div>
            <div className="bill-item bill_divider">
              <span>Credit Discount</span>
              <span className='span_bold'>₹{creditDiscount}</span>
            </div>
            <div className="bill-item final">
              <span>Final Amount</span>
              <span>₹{payableAmount}</span>
            </div>
          </div>

          {/* Client Details */}
          <div className='client_container'>
            <h2 className="client-heading">Client Details</h2>
            <div className="client-item">
              <span>User Phone Number</span>
              <span className='span_bold'>+91 {currentUser.phone}</span>
            </div>
            <div className="client-item">
              <span>User Name</span>
              <span className='span_bold'>{currentUser.name}</span>
            </div>
          </div>

          {/* Proceed and Pay Section */}
          <div className='proceed_and_pay_cont'>
            <div className='credit-option'>
              <input
                type="checkbox"
                id="useCredits"
                checked={useCredits}
                onChange={() => setUseCredits(prev => !prev)}
              />
              <label htmlFor="useCredits">
                Save extra using Credits <FaCoins className="coins-icon" />
              </label>
              <p className="balance-text">
                Balance: ₹{(creditBalance - creditDiscount).toFixed(2)}
              </p>
            </div>
            <div className='payment-summary'>
              <div className='total-payable'>
                <span className='total_head'>Total Payable Amount</span>
                <span className='total_amt'>₹{payableAmount}</span>
              </div>
              <button className='proceed-btn'>Proceed & Pay</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmation;
