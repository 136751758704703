import React from "react";
import faq_img from "../../Assets/FAQs-rafiki.png";
import "./Faq.css";
import HomeNavbar from '../../Components/Navbar';

const Faq = () => {
  const faqsUsers = [
    {
      id: 'headingOne',
      target: 'collapseOne',
      question: 'What is the purpose of Thapy?',
      answer: (
        <>
          At <strong>Thapy</strong>, we provide a convenient platform for individuals to access mental health support. It connects users with licensed therapists and peer support specialists, offering a range of therapeutic resources to enhance well-being.
        </>
      )
    },
    {
      id: 'headingTwo',
      target: 'collapseTwo',
      question: 'How does the therapy process work in the application?',
      answer: 'The therapy process typically involves creating an account, completing an initial assessment, and then choosing a licensed therapist. Users can engage in live video or messaging sessions, set goals, and access resources tailored to their mental health needs.'
    },
    {
      id: 'headingThree',
      target: 'collapseThree',
      question: 'What is the difference between a therapist and peer councillors?',
      answer: 'Therapists are licensed professionals with formal training in mental health, offering clinical expertise. Peer counsellors, while experienced and supportive, share personal experiences to empathize and assist based on their lived experiences. Both play unique roles in providing valuable support.'
    },
    {
      id: 'headingFour',
      target: 'collapseFour',
      question: 'What is the role of a licensed therapist?',
      answer: 'A licensed therapist is a trained mental health professional who provides evidence-based therapy to help individuals manage and overcome various psychological and emotional challenges. They use scientifically proven techniques to support their clients’ mental health and well-being. Their work includes assessing, diagnosing, and treating mental health disorders.'
    },
    {
      id: 'headingFive',
      target: 'collapseFive',
      question: 'What is the role of a peer counselor?',
      answer: 'A peer counselor uses their personal experiences and supportive nature to help others facing similar challenges. Unlike licensed therapists, peer counselors may not have formal clinical training but draw upon their lived experiences to offer empathy and practical advice. They provide support by sharing their own journey and helping others navigate their own challenges.'
    },
    {
      id: 'headingSix',
      target: 'collapseSix',
      question: 'What should I do if I experience technical issues during a session?',
      answer: ' If you encounter technical issues, first check your internet connection. If problems persist, navigate to the website\'s settings and select the "Technical Support" option. Our support team will assist you in resolving any technical issues promptly.'
    },
    {
      id: 'headingSeven',
      target: 'collapseSeven',
      question: 'How can I provide feedback or report issues with the website?',
      answer: 'We welcome your feedback! You can provide input or report issues through the website\'s settings menu, where you\'ll find a dedicated "Feedback" or "Report a Problem" option. Alternatively, you can reach out to our support team via email.'
    },
    {
      id: 'headingEight',
      target: 'collapseEight',
      question: 'What security measures are in place to protect user data?',
      answer: 'We employ robustsecurity measures, including encryption protocols and strict privacy policies, to safeguard user data. Confidentiality and privacy are fundamental principles guiding our app\'s design and functionality.'
    }
  ];

  const faqsTherapist = [
    {
      id: 'headingOneTherapist',
      target: 'collapseOneTherapist',
      question: 'What is the purpose of Thapy?',
      answer: (
        <>
          At <strong>Thapy</strong>, we provide a convenient platform for individuals to access mental health support. It connects users with licensed therapists and peer support specialists, offering a range of therapeutic resources to enhance well-being.
        </>
      )
    },
    {
      id: 'headingTwoTherapist',
      target: 'collapseTwoTherapist',
      question: 'How does the therapy process work in the application?',
      answer: 'The therapy process typically involves creating an account, completing an initial assessment, and then choosing a licensed therapist. Users can engage in live video or messaging sessions, set goals, and access resources tailored to their mental health needs.'
    },
    {
      id: 'headingThreeTherapist',
      target: 'collapseThreeTherapist',
      question: 'What is the difference between a therapist and peer councillors?',
      answer: 'Therapists are licensed professionals with formal training in mental health, offering clinical expertise. Peer counsellors, while experienced and supportive, share personal experiences to empathize and assist based on their lived experiences. Both play unique roles in providing valuable support.'
    },
    {
      id: 'headingFourTherapist',
      target: 'collapseFourTherapist',
      question: 'What is the role of a licensed therapist?',
      answer: 'A licensed therapist is a trained mental health professional who provides evidence-based therapy to help individuals manage and overcome various psychological and emotional challenges. They use scientifically proven techniques to support their clients’ mental health and well-being. Their work includes assessing, diagnosing, and treating mental health disorders.'
    },
    {
      id: 'headingFiveTherapist',
      target: 'collapseFiveTherapist',
      question: 'What is the role of a peer counselor?',
      answer: 'A peer counselor uses their personal experiences and supportive nature to help others facing similar challenges. Unlike licensed therapists, peer counselors may not have formal clinical training but draw upon their lived experiences to offer empathy and practical advice. They provide support by sharing their own journey and helping others navigate their own challenges.'
    },
    {
      id: 'headingSixTherapist',
      target: 'collapseSixTherapist',
      question: 'What should I do if I experience technical issues during a session?',
      answer: ' If you encounter technical issues, first check your internet connection. If problems persist, navigate to the website\'s settings and select the "Technical Support" option. Our support team will assist you in resolving any technical issues promptly.'
    },
    {
      id: 'headingSevenTherapist',
      target: 'collapseSevenTherapist',
      question: 'How can I provide feedback or report issues with the website?',
      answer: 'We welcome your feedback! You can provide input or report issues through the website\'s settings menu, where you\'ll find a dedicated "Feedback" or "Report a Problem" option. Alternatively, you can reach out to our support team via email.'
    },
    {
      id: 'headingEightTherapist',
      target: 'collapseEightTherapist',
      question: 'What security measures are in place to protect user data?',
      answer: 'We employ robustsecurity measures, including encryption protocols and strict privacy policies, to safeguard user data. Confidentiality and privacy are fundamental principles guiding our app\'s design and functionality.'
    }
  ];

  return (
    <>
      <HomeNavbar />
      <div className="faq_container">
        <div className="faq_content">
          <div className="faq_text_image_container">
            <div className="faq_image">
              <img src={faq_img} alt="FAQ" />
            </div>
            <div className="faq_text">
              <h1 className="faq_heading">FAQs</h1>
              <p className="faq_paragraph">
                Have question? Here you can find answers to the most common
                questions about our services and how we can assist you.
              </p>
            </div>
          </div>
          <div className="container-faq">
            <div className="row faq-row justify-content-center"></div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="card">
                <div className="card-body">
                  <div className="row justify-content-center mt-2">
                    <div className="col-lg-9">
                      <ul className="nav nav-tabs nav-tabs-custom nav-justified" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button className="nav-link active" id="pills-general-tab" data-bs-toggle="pill" data-bs-target="#pills-general" type="button" role="tab" aria-controls="pills-general" aria-selected="true">
                            <span className="font-size-18">Users</span>
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="pills-buyers-tab" data-bs-toggle="pill" data-bs-target="#pills-buyers" type="button" role="tab" aria-controls="pills-buyers" aria-selected="false">
                            <span className="font-size-18">Peers & Therapists</span>
                          </button>
                        </li>
                      </ul>
                      <div className="tab-content pt-3" id="pills-tabContent">
                        <div className="tab-pane fade active show" id="pills-general" role="tabpanel" aria-labelledby="pills-general-tab">
                          <div id="accordion" className="accordion-style">
                            {faqsUsers.map((item, index) => (
                              <div className="card card-accord mb-3" key={index}>
                                <div className="card-header" id={item.id}>
                                  <h5 className="mb-0">
                                    <button
                                      className={`btn btn-link ${item.id === 'headingOne' ? '' : 'collapsed'}`}
                                      data-bs-toggle="collapse"
                                      data-bs-target={`#${item.target}`}
                                      aria-expanded={item.id === 'headingOne' ? 'true' : 'false'}
                                      aria-controls={item.target}
                                    >
                                      <span className="text-theme-secondary me-2">Q.</span>
                                      {item.question}
                                    </button>
                                  </h5>
                                </div>
                                <div
                                  id={item.target}
                                  className={`collapse ${item.id === 'headingOne' ? 'show' : ''}`}
                                  aria-labelledby={item.id}
                                  data-bs-parent="#accordion"
                                >
                                  <div className="card-body">
                                    {item.answer}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="tab-pane fade" id="pills-buyers" role="tabpanel" aria-labelledby="pills-buyers-tab">
                          <div id="accordion" className="accordion-style">
                            {faqsTherapist.map((item, index) => (
                              <div className="card mb-3" key={index}>
                                <div className="card-header" id={item.id}>
                                  <h5 className="mb-0">
                                    <button
                                      className={`btn btn-link ${item.id === 'headingOneTherapist' ? '' : 'collapsed'}`}
                                      data-bs-toggle="collapse"
                                      data-bs-target={`#${item.target}`}
                                      aria-expanded={item.id === 'headingOneTherapist' ? 'true' : 'false'}
                                      aria-controls={item.target}
                                    >
                                      <span className="text-theme-secondary me-2">Q.</span>
                                      {item.question}
                                    </button>
                                  </h5>
                                </div>
                                <div
                                  id={item.target}
                                  className={`collapse ${item.id === 'headingOneTherapist' ? 'show' : ''}`}
                                  aria-labelledby={item.id}
                                  data-bs-parent="#accordion"
                                >
                                  <div className="card-body">
                                    {item.answer}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default Faq;
