import React, { useContext, useMemo, useState, useEffect } from "react";
import Sidebar from '../../../Components/sidebar/Sidebar';
import { Box, Typography } from '@mui/material';
import Navbar from '../../../Components/navbar/navbar';
import creditimg from "../../../Assets/credit.png";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Loader from '../../../Components/Loader/Loader';
import WalletIcon from "@mui/icons-material/Wallet";
import { AuthContext } from "../../../Context/AuthContext";
import DBHandler from "../../../Utils/DBHandler";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CreditHistory.css";

const CreditHistory = () => {
  const { currentUser } = useContext(AuthContext);
  const [creditData, setCreditData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const userId = currentUser.id;
  const userId = 'obLUxMXIiAdGVNr3Gg2ksThfrww1';
  const dbHandler = useMemo(() => new DBHandler(), []);


  useEffect(() => {
    const fetchCreditData = async () => {
      if (userId) {
        setLoading(true);
        try {
          const data = await dbHandler.fetchCreditHistory(userId);
          setCreditData(data);
        } catch (error) {
          toast.error("Error fetching credit history. Please try again later!!", { containerId: 'creditHistory' });
        } finally {
          setLoading(false);
        }
      }
    };

    fetchCreditData();
  }, [userId, dbHandler]);

  const dateFormatter = useMemo(() => {
    const dateOptions = { day: 'numeric', month: 'short', year: 'numeric', timeZone: 'Asia/Kolkata' };
    return new Intl.DateTimeFormat('en-IN', dateOptions);
  }, []);

  const timeFormatter = useMemo(() => {
    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: 'Asia/Kolkata' };
    return new Intl.DateTimeFormat('en-IN', timeOptions);
  }, []);


  return (
    <>
      <ToastContainer
        containerId="creditHistory"
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="credit_main_cont">
        <Sidebar userType={"user"} />
        <div className="credit_content_wrapper">
          <Navbar userData={currentUser} userType={'user'} />
          <div className="credit_top_section">
            {loading && <Loader />}
            <img src={creditimg} alt="credit" className="credit_img" />
            <div className="credit_details">
              <h2>Credit History</h2>
              <div className="gift_svg">
                <CardGiftcardIcon />
              </div>
              <h3 className="cred_bal_head">Your Credit Balance</h3>
              <p className="cred_bal_para">{`₹ ${currentUser?.creditEarned - currentUser?.creditRedeemed}`}</p>
              <div className="credit_cards_cont">
                <div className="credit_cards">
                  <div className="credit_card_details">
                    <div className="credit_icon-container">
                      <CurrencyRupeeIcon />
                    </div>
                    <div className="credit_money">
                      <h3>Received</h3>
                      <p>{`₹ ${currentUser?.creditEarned}`}</p>
                    </div>
                  </div>
                </div>
                <div className="credit_cards">
                  <div className="credit_card_details">
                    <div className="credit_icon-container">
                      <CurrencyRupeeIcon />
                    </div>
                    <div className="credit_money">
                      <h3>Redeemed</h3>
                      <p>{`₹ ${currentUser?.creditRedeemed}`}</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="recent_head">
            <h3>Recent Transaction</h3>
          </div>
          {creditData.length === 0 ? (
            <Typography className="no-credit-history-message">
              No recent credit history found at the Moment
            </Typography>
          ) : (
            <div className="transaction-table">
              {creditData.map((transaction, index) => {
                const sign = transaction.isCredited === 1 ? '+' : '-';
                const amount = `${sign}₹${transaction.credit}`;
                const formattedDate = dateFormatter.format(transaction.timestamp.toDate());
                const formattedTime = timeFormatter.format(transaction.timestamp.toDate());
                const transactionType = transaction.isCredited === 1 ? 'Received' : 'Redeemed';
                const amtColor = transaction.isCredited === 1 ? 'green' : 'red';

                return (
                  <div key={index} className="transaction-row">
                    <div className="transaction-icon-name">
                      <div className="transaction-icon">
                        <WalletIcon />
                      </div>
                      <div className="transaction-details">
                        <h3>{transactionType}</h3>
                      </div>
                    </div>
                    <div className="transaction-date">
                      {formattedDate} <div>
                      {formattedTime}
                        </div>
                    </div>
                    <div className={`transaction-amount-${amtColor}`}>{amount}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CreditHistory;
