import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, BarController } from 'chart.js';
import './lineChart.css';

// Register the required components for Chart.js
Chart.register(CategoryScale, LinearScale, BarElement, BarController, Title, Tooltip, Legend);

const LineChart = ({ tenDaysPayments = [] }) => {
  const chartRef = useRef(null);
  const [barThickness, setBarThickness] = useState(20);
  const [fontSize, setFontSize] = useState(12);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setBarThickness(10); // Adjust this value for mobile view
        setFontSize(7); // Adjust this value for mobile view
      } else {
        setBarThickness(18); // Adjust this value for desktop view
        setFontSize(12); // Adjust this value for desktop view
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        const chart = new Chart(ctx, {
          type: 'bar', // Specify the chart type as 'bar'
          data: {
            labels: tenDaysPayments.length === 0 ? [] : tenDaysPayments.map(payment => formatDate(payment.date)),
            datasets: [
              {
                label: 'Earnings',
                data: tenDaysPayments.map(payment => payment.amt),
                backgroundColor: '#6096FF',
                barThickness: barThickness,
                borderRadius: 10,
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
            scales: {
              x: {
                grid: {
                  display: false,
                },
                ticks: {
                  maxRotation: 0,
                  minRotation: 0,
                  font: {
                    size: fontSize,
                  },
                },
              },
              y: {
                grid: {
                  display: true,
                  color: '#e0e0e0',
                },
                ticks: {
                  beginAtZero: true,
                  font: {
                    size: fontSize,
                  },
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
            },
          },
        });

        // Cleanup function to destroy the chart instance when the component unmounts
        return () => {
          chart.destroy();
        };
      }
    }
  }, [tenDaysPayments, barThickness, fontSize]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'short', day: '2-digit' };
    return date.toLocaleDateString('en-US', options);
  };

  return (
    <Box className='line_card'>
      <Typography variant="h6" gutterBottom className='graph_heading'>
        Earning/Day
      </Typography>
      <div className="chart-container">
        {tenDaysPayments.length === 0 ? (
          <Typography variant="h6" align="center" className='no-data'>
            No data available
          </Typography>
        ) : (
          <canvas ref={chartRef}></canvas>
        )}
      </div>
    </Box>
  );
};

export default LineChart;
