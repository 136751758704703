import React, { useState, useEffect } from 'react';
import './TimerPopup.css';

const TimerPopup = ({ onClose }) => {
  const [timeLeft, setTimeLeft] = useState(5 * 60 * 1000); // 5 minutes in milliseconds

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 0) {
          clearInterval(interval);
          return 0;
        }
        return prevTime - 1000;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60000);
    const seconds = ((time % 60000) / 1000).toFixed(0);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div className='timer_popup'>
      <div className='timer_popup_content'>
        <h2>Waiting for Payment. Expected Waiting Time</h2>
        <div className='timer_display'>
          <p>{formatTime(timeLeft)}</p>
        </div>
      </div>
    </div>
  );
};

export default TimerPopup;
