import React, { useContext } from 'react';
import { AuthContext } from "../Context/AuthContext";
import Sidebar from '../Components/sidebar/Sidebar';
import HomeNavbar from '../Components/Navbar';
import Navbar from '../Components/navbar/navbar';
import DeleteAccount from './Therapist/DeleteAccount/DeleteAccount';
import './Therapist/Home/home.scss';

export default function ShowDeleteAccount() {
    const { currentUser } = useContext(AuthContext);

    return (
        <>
            {currentUser && currentUser.professional ? (
                <div className='therapy_home'>
                    <Sidebar userType={'therapist'} />
                    <div className='homeContainer'>
                        <Navbar userData={currentUser} userType={'therapist'} />
                        <DeleteAccount />
                    </div>
                </div>
            ) : (
                <>
                    <HomeNavbar />
                    <DeleteAccount />
                </>
            )}
        </>
    );
}
