import React, { useState, useEffect, useContext, useMemo } from "react";
import "./toggle.css";
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DBHandler from '../../Utils/DBHandler';
import { AuthContext } from "../../Context/AuthContext";
import { usePsychologist } from "../../Utils/Psychologist/PsychologistContext";
import 'firebase/firestore';
import firebase from "firebase/compat/app";
import { useNavigate } from "react-router-dom";

const Toggle = () => {
  const dbHandler = useMemo(() => new DBHandler(), []);
  const { psychologist, modifyPsychologist } = usePsychologist();
  const [psyData, setPsychologistData] = useState({});
  const [liveCallEnabled, setLiveCallEnabled] = useState(false);
  const [instantCallEnabled, setInstantCallEnabled] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPsychologistData = async () => {
      try {
        const psychologistData = await dbHandler.getPsychologist(currentUser.id);
        setPsychologistData(psychologistData);

        let calls = psychologistData.calls;
        if (calls instanceof Map) {
          calls = Object.fromEntries(calls.entries());
        }

        setLiveCallEnabled(calls.Live || false);
        setInstantCallEnabled(calls.Instant || false);
      } catch (error) {
        console.error('Error fetching psychologist data:', error);
      }
    };

    fetchPsychologistData();
  }, [psychologist, dbHandler, currentUser.id]);

  //TODO: When therapist goes to some other tab, it showing offline, Think of some better implementation
  // useEffect(() => {

  //   const handleVisibilityChange = () => {
  //     if (liveCallEnabled) {
  //       if (document.visibilityState === 'visible' && document.hasFocus()) {
  //         updatePsychologistStatus('Online');
  //       } else {
  //         updatePsychologistStatus('Offline');
  //       }
  //     } else {
  //       updatePsychologistStatus('Offline');
  //     }
  //   };

  //   const handleFocus = () => {
  //     if (liveCallEnabled) {
  //       updatePsychologistStatus('Online');
  //     }
  //   };

  //   const handleBlur = () => {
  //     if (liveCallEnabled) {
  //       updatePsychologistStatus('Offline');
  //     }
  //   };

  //   const handleBeforeUnload = () => {
  //     if (liveCallEnabled) {
  //       updatePsychologistStatus('Offline');
  //     }
  //   };

  //   document.addEventListener('visibilitychange', handleVisibilityChange);
  //   window.addEventListener('focus', handleFocus);
  //   window.addEventListener('blur', handleBlur);
  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   // Initial check when the component mounts
  //   handleVisibilityChange();

  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //     window.removeEventListener('focus', handleFocus);
  //     window.removeEventListener('blur', handleBlur);
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, [liveCallEnabled, currentUser.id]);

  const updatePsychologistStatus = async (status) => {
    try {
      await dbHandler.updatePsychologist({
        id: currentUser.id,
        status: status,
        lastOnline: status === 'Online' ? firebase.firestore.FieldValue.serverTimestamp() : null
      });
    } catch (error) {
      console.error('Error updating psychologist status:', error);
    }
  };

  const handleLiveCallToggle = async () => {
    const newLiveValue = !liveCallEnabled;
    setLiveCallEnabled(newLiveValue);

    try {
      await dbHandler.updateCallStatus(currentUser.id, 'Live', newLiveValue);
      const updatedPsychologistData = {
        ...psyData,
        status: newLiveValue ? 'Online' : 'Offline',
        lastOnline: firebase.firestore.FieldValue.serverTimestamp(),
        calls: {
          ...psyData.calls,
          Live: newLiveValue
        }
      };
      await modifyPsychologist(updatedPsychologistData);
      await updatePsychologistStatus(newLiveValue ? 'Online' : 'Offline');
    } catch (error) {
      console.error('Error updating live call status:', error);
    }
  };

  const handleInstantCallToggle = async () => {
    const newInstantValue = !instantCallEnabled;
    setInstantCallEnabled(newInstantValue);

    try {
      await dbHandler.updateCallStatus(currentUser.id, 'Instant', newInstantValue);
      const updatedPsychologistData = {
        ...psyData,
        calls: {
          ...psyData.calls,
          Instant: newInstantValue
        }
      };
      await modifyPsychologist(updatedPsychologistData);
    } catch (error) {
      console.error('Error updating instant call status:', error);
    }
  };

  const handleBookSlot = () => {
    navigate("/therapists/bookslots");
  }

  return (
    <div className="toggle_card">
      <div className="inner_toggle">
        <div className="top_toggle">
          <div className="flex_toggle heading">
            <h2 className="top_heading">Booking Calls</h2>
            <Tooltip arrow title="You can set your available timeslots for appointments. Once user books an appointment, you need to confirm it and then initaite the call at the time of appointment." placement="right-start">
              <InfoOutlinedIcon className="info-icon" />
            </Tooltip>
          </div>
          <p className="centered-text">You get ₹ 238 / call</p>

          <div className="toggle_button-container">
            <button onClick={handleBookSlot}>
              Set Availability
              <div className="arrow-wrapper">
                <div className="arrow"></div>
              </div>
            </button>
          </div>
        </div>
        <div className="bottom_toggle">
          <div className="first_toggle">
            <div className="flex_toggle ">
              <h2 className="top_heading short ">Live Call</h2>
              <Tooltip title="If you enable this then users will be able to do voice/video call to you directly. You can accept or reject the call" placement="right-start" arrow>
                <InfoOutlinedIcon className="info-icon small_icon" />
              </Tooltip>
            </div>
            <p className="details">You get ₹238 / call</p>
            <label>
              <input className="toggle-checkbox" type="checkbox" checked={liveCallEnabled} onChange={handleLiveCallToggle} />
              <div className="toggle-slot">
                <div className="sun-icon-wrapper">
                  <div
                    className="iconify sun-icon"
                    data-icon="feather-sun"
                    data-inline="false"
                  ></div>
                </div>
                <div className="toggle-button"></div>
                <div className="moon-icon-wrapper">
                  <div
                    className="iconify moon-icon"
                    data-icon="feather-moon"
                    data-inline="false"
                  ></div>
                </div>
              </div>
            </label>
          </div>

          <div className="second_toggle">
            <div className="flex_toggle">
              <h2 className="top_heading short">Instant Call</h2>
              <Tooltip arrow title="Instant Call Price is Fixed. If you enables this then you can recieve instant call from users. You will have 10 seconds to accept the call." placement="right-start">
                <InfoOutlinedIcon className="info-icon small_icon" />
              </Tooltip>
            </div>
            <p className="details">You get ₹40 / call</p>
            <label>
              <input className="toggle-checkbox" type="checkbox" checked={instantCallEnabled} onChange={handleInstantCallToggle} />
              <div className="toggle-slot">
                <div className="sun-icon-wrapper">
                  <div
                    className="iconify sun-icon"
                    data-icon="feather-sun"
                    data-inline="false"
                  ></div>
                </div>
                <div className="toggle-button"></div>
                <div className="moon-icon-wrapper">
                  <div
                    className="iconify moon-icon"
                    data-icon="feather-moon"
                    data-inline="false"
                  ></div>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Toggle;
