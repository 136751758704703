import firebase from "firebase/compat/app";
import { GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import DBHandler from "../Utils/DBHandler";
import TherapistDashboardInfo from "../Utils/TherapistDashboard";

const firebaseConfig = {
  apiKey: "AIzaSyB2lF0sgbXtouvvKdFGU4Yq3qPDYlEiHNI",
  authDomain: "amisoul-79b62.firebaseapp.com",
  projectId: "amisoul-79b62",
  storageBucket: "amisoul-79b62.appspot.com",
  messagingSenderId: "894778163765",
  appId: "1:894778163765:web:f692d3f963d43f7e9be77c",
  measurementId: "G-BGP93VWFZR"
};

const initialize = firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
const provider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com');
const messaging = getMessaging(initialize);
const dbHandler = new DBHandler();

export const requestPermission = async (psyId) => {
  await Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      return getToken(messaging, {
        vapidKey: "BA8H9h3ZRX5O_dL2ytwXW5c9i2y18uhdEZHaApHNdmUzESZzZGGiRMTF_tYS2gx06bGSCX7oMPHKbrsSM4mknhQ"
      })
        //TODO: SAVE TOKEN TO DATABASE
        .then(async (deviceToken) => {
          if (deviceToken) {
            const updatedInfo = new TherapistDashboardInfo({
              deviceToken: { [deviceToken]: true }
            });

            await dbHandler.addTherapistDeviceToken(psyId, updatedInfo);
          } else {
            console.log("No registration token available.");
          }
        })
        .catch(err => {
          console.log("An error occurred while retrieving token. ");
        });
    } else {
      console.log("User Permission Denied");
    }
  });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export { initialize, auth, db, storage, provider, appleProvider };
