import React, { useState, useEffect } from 'react';
import './CallRequest.css';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import TimerPopup from './TimerPopup';
import DBHandler from '../../Utils/DBHandler';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CallRequest = ({ callData, psyId, onClose }) => {
  const [isTimerVisible, setIsTimerVisible] = useState(false);
  const dbHandler = new DBHandler();

  const handleAcceptClick = () => {
    dbHandler.updateCallRequest({ id: psyId, status: "Accepted" });
    setIsTimerVisible(true);
  };

  const handleRejectClick = () => {
    dbHandler.updatePsychologist({ id: psyId, status: "Online" });
    dbHandler.updateCallRequest({ id: psyId, status: "Available" });
    setIsTimerVisible(false);
    onClose();
  };

  //TODO: Show toast message based on call status, and close the timer
  useEffect(() => {
    const handleStatusChange = (status) => {
      switch (status) {
        case 'Payment Success':
          toast.info('Payment successful! Call in progress.', { containerId: 'callRequestToast' });
          break;
        case 'Payment Fail':
          toast.error('Payment failed! Unable to connect the call at this time.', { containerId: 'callRequestToast' });
          break;
        case 'Cancelled':
          toast.error('User cancelled the call.', { containerId: 'callRequestToast' });
          break;
        default:
          break;
      }
      setIsTimerVisible(false);
    };

    if (callData) {
      handleStatusChange(callData.status);
    }
  }, [callData]);

  return (
    <>
      <ToastContainer
        containerId="callRequestToast"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {!isTimerVisible && (
        <>
          <div className='call_request_overlay'></div>
          <div className='call_request_container'>
            <div className='call_request_content'>
              <h3>Incoming Call Request</h3>
              <div className='call_request_details'>
                <AccountCircleIcon className='user_icon' />
                <div>
                  <p className='user_name'>{callData?.userName || 'Unknown User'}</p>
                  <p>requested a call</p>
                </div>
              </div>
              <div className='call_request_buttons'>
                <button className='callBtn answer' onClick={handleAcceptClick}>
                  <span>Answer</span>
                  <AddIcCallIcon className='callsvg' />
                </button>
                <button className='callBtn reject' onClick={handleRejectClick}>
                  <span>Reject</span>
                  <PhoneDisabledIcon className='callsvg' />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {isTimerVisible && <TimerPopup onClose={() => setIsTimerVisible(false)} />}
    </>
  );
};

export default CallRequest;
