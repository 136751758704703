import React, { useState, useEffect, useContext } from 'react';
import './overview.scss';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { AuthContext } from "../../../Context/AuthContext";
import DBHandler from "../../../Utils/DBHandler";

const Overview = () => {
    const [therapistData, setTherapistData] = useState([]);
    const { currentUser } = useContext(AuthContext);

    const currentDate = new Date();
    const offset = 330; // IST offset in minutes (UTC+05:30)
    const istDate = new Date(currentDate.getTime() + offset * 60 * 1000);
    const istDateString = istDate.toISOString().split('T')[0];

    useEffect(() => {
        const fetchBookings = async () => {
            const dbHandler = new DBHandler();
            const data = await dbHandler.getTherapistDashboardInfo(currentUser.id);
            setTherapistData(data);
        };

        fetchBookings();
    }, [currentUser.id]);

    const tenDaysPayments = therapistData.tenDaysPayments || [];
    const latestPayment = tenDaysPayments[tenDaysPayments.length - 1];
    const latestDateString = latestPayment ? latestPayment.date : '';
    const currBalance = latestDateString === istDateString ? latestPayment?.amt || 0.00 : 0.00;

    const splitNumber = (number) => {
        const num = Number(number);
        if (isNaN(num)) {
            return { integerPart: '0', decimalPart: '00' };
        }

        const [integerPart, decimalPart] = num.toFixed(2).split('.');
        return { integerPart, decimalPart };
    };

    const formatSettledDate = (date) => {
        if (!date) {
            return '';
        }
        const timestamp = date.toDate();
        const options = { weekday: 'short', month: 'short', day: 'numeric' };
        return timestamp.toLocaleDateString('en-IN', options);
    };

    const { integerPart: intCurrBalance, decimalPart: decCurrBalance } = splitNumber(currBalance);
    const { integerPart: intLastSettledBalance, decimalPart: decLastSettledBalance } = splitNumber(therapistData.lastSettledAmt);
    return (
        <>
            <div className="overview-container shadow">
                <h2 className='overview-heading'>Overview</h2>
                <div className="overview_content">
                    <div className="overview-item first_item">
                        <span className="item-title">
                            Current Balance
                            <Tooltip title="This is the total amount that is due to be deposited in your bank account after deduction of any other applicable charges, and adjustment of refunds and credits" placement="top" arrow>
                                <InfoOutlinedIcon fontSize="small" sx={{ cursor: 'pointer' }} />
                            </Tooltip>
                        </span>
                        <span className="item-value"><span className='overview-currency'>₹</span> {intCurrBalance}<span className='overview-decimal'>.{decCurrBalance}</span></span>
                    </div>
                    <div className="overview-item">
                        <span className="item-title">
                            Settlement Due Today
                            <Tooltip title="This is the amount initiated for deposit into your bank account and is in processing" placement="top" arrow>
                                <InfoOutlinedIcon fontSize="small" sx={{ cursor: 'pointer' }} />
                            </Tooltip>
                        </span>
                        <span className="item-value"><span className='overview-currency'>₹</span> 0<span className='overview-decimal'>.00</span></span>
                    </div>
                    <div className="overview-item">
                        <span className="item-title">
                            Previous Settlement
                            <Tooltip title="This is the last amount initiated for deposit into your bank account" placement="top" arrow>
                                <InfoOutlinedIcon fontSize="small" sx={{ cursor: 'pointer' }} />
                            </Tooltip>
                        </span>
                        <span className="item-value"><span className='overview-currency'>₹</span> {intLastSettledBalance}<span className='overview-decimal'>.{decLastSettledBalance}</span></span>
                        {
                            therapistData.lastSettledDate && (
                                <span className="item-status processed">Processed on {formatSettledDate(therapistData.lastSettledDate)}</span>
                            )
                        }
                    </div>
                    <div className="overview-item last_item">
                        <span className="item-title">
                            Upcoming Settlement
                            <Tooltip title="This is the amount that’ll be deposited into your bank account next as per your settlement cycle." placement="top" arrow>
                                <InfoOutlinedIcon fontSize="small" sx={{ cursor: 'pointer' }} />
                            </Tooltip>
                        </span>
                        <span className="item-value"><span className='overview-currency'>₹</span> 819<span className='overview-decimal'>.36</span></span>
                        <span className="item-info">To be processed on 13 Jun, 1:00 PM</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Overview;
