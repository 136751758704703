import React from 'react';
import './TherapistProfileCard.css';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import StarIcon from '@mui/icons-material/Star';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Card = ({ name, imagePath, professional, status, areas, languages, price, rating, about, handleViewBtn }) => {
    return (
        <div className="profile-card" data-aos="fade-up" data-aos-delay="100">
            <div className="profile-header">
                <img
                    src={imagePath}
                    alt={name}
                    className="profile-pic"
                />
                <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                    <defs>
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g className="parallax">
                        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
                        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                    </g>
                </svg>
                <div className="profile-status">
                    <FiberManualRecordIcon style={{ color: status === 'Online' ? 'green' : 'red', fontSize: 12 }} />
                    <span className="status">{status}</span>
                </div>
            </div>
            <div className="profile-details">
                <h2 className="profile-name">{professional ? `Dr. ${name}` : name}</h2>
                <p className="languages">{areas.join(', ')}</p>
                <p className="languages">{languages.join(', ')}</p>
                <p className="profile-bio">{about}</p>
                <div className="profile-rating">
                    <span className="cost">₹{price} /hr</span>
                    <span className="rating"><StarIcon style={{ color: 'gold', fontSize: 18 }} /> {rating}</span>
                </div>
                <button className='viewProfileBtn' onClick={handleViewBtn}>View Profile</button>
            </div>
        </div>
    );
};

const TherapistProfileCard = ({ psychologist, psyType, isHome }) => {
    const navigate = useNavigate();

    const handleBtnClick = (psychologist, isHome) => {
        if (isHome) {
            toast.info('Please log in to access the profile. Redirecting you to the login page!!', { containerId: 'therapistProfileCardPage' });
            setTimeout(() => {
                navigate('/login');
            }, 3500);
        } else {
            const route = psyType ? `/user/therapist/${psychologist.id}` : `/user/peer/${psychologist.id}`;
            navigate(route, { state: { psychologist } });
        }
    };

    return (
        <>
            <ToastContainer
                containerId="therapistProfileCardPage"
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className='therapdetailsContainer'>
                <div className="therapists-profile-container">
                    <div className="cards-container">
                        {psychologist.map((profile, index) => (
                            <Card
                                key={index}
                                {...profile}
                                handleViewBtn={() => handleBtnClick(profile, isHome)} // Pass the specific profile
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TherapistProfileCard;
