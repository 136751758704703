import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PsychologyIcon from '@mui/icons-material/Psychology';
import SpaIcon from '@mui/icons-material/Spa';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import CallIcon from '@mui/icons-material/Call';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import LockIcon from '@mui/icons-material/Lock';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { AuthContext } from "../../Context/AuthContext";
import './sidebar.scss';

const Sidebar = ({ userType }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(window.innerWidth <= 1024);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const sidebarLinks = {
    therapist: [
      { type: "section", label: "Home" },
      { type: "link", to: "/therapists/dashboard", icon: <DashboardIcon className="icon" />, label: "Dashboard" },
      { type: "link", to: "/therapists/settlement", icon: <PriceChangeIcon className="icon" />, label: "Settlements" },
      { type: "section", label: "Consultation" },
      { type: "link", to: "/therapists/appointments", icon: <EditCalendarIcon className="icon" />, label: "Booking" },
      { type: "link", to: "/therapists/liveCalls", icon: <CallIcon className="icon" />, label: "Live & Instant Calls" },
      { type: "section", label: "Settings" },
      { type: "link", to: "/therapists/faq", icon: <LiveHelpIcon className="icon" />, label: "FAQs" },
      { type: "link", to: "/therapists/privacyPolicy", icon: <PrivacyTipIcon className="icon" />, label: "Privacy Policy" },
      { type: "link", to: "/therapists/terms&Conditions", icon: <BookmarkBorderIcon className="icon" />, label: "Terms of Service" },
      { type: "link", to: "/therapists/resetPassword", icon: <LockIcon className="icon" />, label: "Password Reset" },
      { type: "link", to: "/delete-account", icon: <DeleteIcon className="icon" sx={{ color: 'red' }} />, label: "Delete Account", style: { color: 'red', fontWeight: 'bolder' } }
    ],
    user: [
      { type: "section", label: "Home" },
      { type: "link", to: "/user/dashboard", icon: <DashboardIcon className="icon" />, label: "Dashboard" },
      { type: "link", to: "/user/therapist", icon: <PsychologyIcon className="icon" />, label: "Therapists" },
      { type: "link", to: "/user/peer", icon: <SpaIcon className="icon" />, label: "Peers" },
      { type: "section", label: "Consultation" },
      { type: "link", to: "/user/appointments", icon: <EditCalendarIcon className="icon" />, label: "Booking" },
      { type: "link", to: "/user/liveCalls", icon: <CallIcon className="icon" />, label: "Live & Instant Calls" },
      { type: "section", label: "Refer & Earn" },
      { type: "link", to: "/user/refer&Earn", icon: <GroupAddIcon className="icon" />, label: "Refer & Earn" },
      { type: "link", to: "/user/creditHistory", icon: <CurrencyRupeeIcon className="icon" sx={{ color: '#fcb001' }} />, label: `Credits: ₹${currentUser?.creditEarned - currentUser?.creditRedeemed}` },
      { type: "section", label: "Settings" },
      { type: "link", to: "/user/faq", icon: <LiveHelpIcon className="icon" />, label: "FAQs" },
      { type: "link", to: "/user/privacyPolicy", icon: <PrivacyTipIcon className="icon" />, label: "Privacy Policy" },
      { type: "link", to: "/user/terms&Conditions", icon: <BookmarkBorderIcon className="icon" />, label: "Terms of Service" },
      { type: "link", to: "/user/resetPassword", icon: <LockIcon className="icon" />, label: "Password Reset" },
      { type: "link", to: "/delete-account", icon: <DeleteIcon className="icon" sx={{ color: 'red' }} />, label: "Delete Account", style: { color: 'red', fontWeight: 'bolder' } }
    ]
  };

  return (
    <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
      <div className="top">
        <MenuIcon className="hamburger" onClick={handleToggle} />
        <Link to={`/${userType}/dashboard`} style={{ textDecoration: "none" }}>
          <span className={`logo ${isCollapsed ? "hidden" : ""}`}>Thapy</span>
          <span className={`logo-dot ${isCollapsed ? "hidden" : ""}`}>.</span>
        </Link>
      </div>
      <div className="center">
        <ul>
          {sidebarLinks[userType].map((item, index) =>
            item.type === "section" ? (
              <p key={index} className={`title ${isCollapsed ? "hidden" : ""} main_title`}>{item.label}</p>
            ) : (
              <Link key={index} to={item.to} style={{ textDecoration: "none" }}>
                <li>
                  {item.icon}
                  <span className={isCollapsed ? "hidden" : ""} style={item.style || {}}>{item.label}</span>
                </li>
              </Link>
            )
          )}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;

