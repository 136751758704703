import React, { useState, useEffect, useContext } from 'react';
import './ConsultationCard.css';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../../Components/sidebar/Sidebar';
import Navbar from '../../../Components/navbar/navbar';
import DBHandler from "../../../Utils/DBHandler";
import { CardComponent } from '../../../Components/UserCardComponent/CardComponent';
import Loader from '../../../Components/Loader/Loader';
import { usePsychologist } from '../../../Utils/Psychologist/PsychologistContext';
import { AuthContext } from "../../../Context/AuthContext";

const ConsultationCard = () => {
  const [tab, setTab] = useState('upcoming');
  const [bookingData, setBookingData] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [loading, setLoading] = useState(true);
  const { psychologist } = usePsychologist();
  const { currentUser } = useContext(AuthContext);
  const psyId = currentUser.id;
  // const psyId = 'obLUxMXIiAdGVNr3Gg2ksThfrww1';
  const navigate = useNavigate();
  //TODO: Add rating for completed calls

  useEffect(() => {
    const fetchBookings = async () => {
      setLoading(true);
      try {
        const dbHandler = new DBHandler();
        const { bookings: newBookings, lastDocument } = await dbHandler.getBookingHistory(psyId, 'Booking', 10, lastDoc);

        const combinedBookings = [...bookingData, ...newBookings];

        // const uniqueBookings = Array.from(new Map(combinedBookings.map(booking => [booking.bookingId, booking])).values());

        setBookingData(combinedBookings);
        setLastDoc(lastDocument);
      } catch (error) {
        console.error('Error fetching settlement history:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBookings();
  }, [psyId, lastDoc]);


  const handleTabChange = (tab) => {
    setTab(tab);
  };

  const statusMap = {
    'upcoming': 'Scheduled',
    'completed': 'Completed',
    'cancelled': 'Cancelled',
  };

  const filterBookings = () => {
    let filteredBookings = bookingData.filter(booking => {
      if (tab === 'cancelled') {
        return booking.bookingStatus === -1 || booking.status === 'Payment Fail';
      } else if (tab === 'completed') {
        return booking.bookingStatus === 1 && booking.status !== 'Payment Fail';
      } else {
        return booking.bookingStatus === 0 && booking.status !== 'Payment Fail';
      }
    });

    if (tab === 'upcoming') {
      filteredBookings.sort((a, b) => a.dateTimeSlot - b.dateTimeSlot);
    } else {
      filteredBookings.sort((a, b) => b.dateTimeSlot - a.dateTimeSlot);
    }

    return filteredBookings;
  };

  const handleCardClick = (booking) => {
    navigate(`/therapist/consultationInfo/${booking.userId}`, { state: { callData: booking } });
  };

  const updateBookingHistory = async (paymentId, newStatus) => {
    setLoading(true);
    await new Promise(resolve => setTimeout(resolve, 500));  // Simulate network delay for demonstration purposes
    setBookingData(prevBookings => {
      return prevBookings.map(booking => {
        if (booking.paymentId === paymentId) {
          return { ...booking, bookingStatus: newStatus };
        }
        return booking;
      });
    });
    setLoading(false);
  };

  const filteredBookings = filterBookings();

  return (
    <div className='consultant_home'>
      <Sidebar userType={'therapist'} />
      <div className='Consultant_home_container'>
        <Navbar userData={currentUser} userType={'therapist'} />
        {loading && <Loader />}
        <h2 className='consultant_call_heading'>Booking Calls</h2>
        <hr className="divider" />
        <div className="tabs">
          <div className={`tab ${tab === 'upcoming' ? 'active' : ''}`} onClick={() => handleTabChange('upcoming')}>Upcoming</div>
          <div className={`tab ${tab === 'completed' ? 'active' : ''}`} onClick={() => handleTabChange('completed')}>Completed</div>
          <div className={`tab ${tab === 'cancelled' ? 'active' : ''}`} onClick={() => handleTabChange('cancelled')} style={{ color: '#d90429' }}>Cancelled</div>
        </div>
        <hr className="divider" />
        <Box className="card-grid">
          {filteredBookings.length > 0 ? (
            filteredBookings.map((booking, index) => (
              <CardComponent
                key={index}
                initials={booking.userName[0].toUpperCase()}
                consultationData={booking}
                onClick={() => handleCardClick(booking)}
                psyName={psychologist.name}
                updateBookingHistory={updateBookingHistory}
              />
            ))
          ) : (
            <Typography className="no-bookings-message">No {statusMap[tab]} Appointments at the Moment</Typography>
          )}
        </Box>
      </div>
    </div>
  );
};

export default ConsultationCard;
