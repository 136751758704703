import "./widget.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
// import CalendarIcon from "@mui/icons-material/CalendarMonthOutlined";
// import QueryIcon from "@mui/icons-material/LiveHelpOutlined";
import CallIcon from '@mui/icons-material/Call';
import BookIcon from '@mui/icons-material/Book';
import GradeIcon from '@mui/icons-material/Grade';
import { Link } from "react-router-dom";

const Widget = ({ type, value }) => {
  let data;

  //temporary
  const amount = 100;
  const diff = 20;

  switch (type) {
    case "user":
      data = {
        title: "TOTAL UNIQUE PATIENTS",
        isMoney: false,
        link: "See all patients",
        linkURL: '/therapists/appointments',
        value: value,
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "liveCalls":
      data = {
        title: "TOTAL LIVE & INSTANT CALLS",
        isMoney: false,
        link: "View all calls",
        linkURL: '/therapists/liveCalls',
        value: value,
        icon: (
          <CallIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "bookingCalls":
      data = {
        title: "TOTAL BOOKINGS CALLS",
        isMoney: false,
        link: "View all bookings",
        linkURL: '/therapists/appointments',
        value: value,
        icon: (
          <BookIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
    case "rating":
      data = {
        title: "TOTAL RATINGS",
        isMoney: false,
        link: "See details",
        linkURL: '/therapists/dashboard',
        value: value,
        icon: (
          <GradeIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {data.isMoney && "$"} {data.value}
        </span>
        <Link to={data.linkURL} className="link"><span style={{ cursor: 'pointer' }}>{data.link}</span></Link>
      </div>
      <div className="right">
        <div className="percentage positive">
          <KeyboardArrowUpIcon />
          {diff} %
        </div>
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
