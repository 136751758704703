import React, { useState, useEffect, useContext } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from '@mui/material/TablePagination';
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Overview from "./OverviewSettlement";
import Tooltip from '@mui/material/Tooltip';
import "./table.scss";
import DBHandler from "../../../Utils/DBHandler";
import Loader from '../../../Components/Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from "../../../Context/AuthContext";


const SettlementHistory = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [payments, setPayments] = useState([]);
    const [lastDoc, setLastDoc] = useState(null);
    const { currentUser } = useContext(AuthContext);
    const psyId = currentUser.id;

    useEffect(() => {
        const fetchSettlementHistory = async () => {
            setLoading(true);
            try {
                const dbHandler = new DBHandler();
                const { payments: newPayments, lastDocument } = await dbHandler.getSettlementHistory(psyId, rowsPerPage, lastDoc);

                const combinedPayments = [...payments, ...newPayments];

                const uniquePayments = Array.from(new Map(combinedPayments.map(payment => [payment.trxId, payment])).values());

                setPayments(uniquePayments);
                setLastDoc(lastDocument);
            } catch (error) {
                toast.error('Error fetching settlement history. Please try again later or conatact the administrator!!', { containerId: 'settlementHistory' });
            } finally {
                setLoading(false);
            }
        };

        fetchSettlementHistory();
    }, [psyId, rowsPerPage, lastDoc]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setLastDoc(null);
        setPayments([]);
    };

    const formatDate = (date) => {
        const options = {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            timeZone: 'Asia/Kolkata',
        };

        return date instanceof Date ? new Intl.DateTimeFormat('en-US', options).format(date) : '---';
    };

    return (
        <div className="list-container">
            <ToastContainer
                containerId="settlementHistory"
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Overview />
            {loading && <Loader />}
            <div className="homeContainer">
                <TableContainer
                    component={Paper}
                    className="table"
                    sx={{
                        margin: "10px 40px 10px",
                        width: "auto",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow className="tableRow">
                                <TableCell className="tableCell">Created on</TableCell>
                                <TableCell className="tableCell">TransactionId</TableCell>
                                <TableCell className="tableCell" sx={{ textAlign: "center" }}>
                                    UTR Number
                                    <Tooltip title="A Unique Transaction Reference (UTR) number available across banks, which can be used to track a specific settlement in your bank account" placement="top" arrow>
                                        <InfoOutlinedIcon className="utr-icon" fontSize="small" sx={{ cursor: 'pointer' }} />
                                    </Tooltip>
                                </TableCell>
                                <TableCell className="tableCell" sx={{ textAlign: "center" }}>
                                    Net Settlement
                                </TableCell>
                                <TableCell className="tableCell" sx={{ textAlign: "center" }}>Settled on</TableCell>
                                <TableCell
                                    className="tableCell"
                                    sx={{ textAlign: "center" }}
                                >
                                    Status
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(payments || []).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((payment) => (
                                    <TableRow hover key={payment.trxId}>
                                        <TableCell className="tableValue">{formatDate(payment.createdOn)}</TableCell>
                                        <TableCell className="tableValue">{payment.trxId}</TableCell>
                                        <TableCell className="tableValue" sx={{ textAlign: "center" }}>{payment.utr === '' ? '---' : payment.utr}</TableCell>
                                        <TableCell className="tableValue" sx={{ textAlign: "center" }}>
                                            {`₹ ${payment.amt}`}
                                        </TableCell>
                                        <TableCell className="tableValue" sx={{ textAlign: "center" }}>{formatDate(payment.settledOn)}</TableCell>
                                        <TableCell
                                            sx={{
                                                textAlign: "center",
                                                color: payment.paymentStatus === 1 ? "green" : "red",
                                            }}
                                        >
                                            <span
                                                className={payment.paymentStatus === 1 ? "processedStatus" : "unprocessedStatus"}
                                                style={{
                                                    display: "inline-block",
                                                    padding: "5px 10px",
                                                    borderRadius: "15px",
                                                    backgroundColor: payment.paymentStatus === 1 ? "rgba(0, 163, 82, 0.09)" : "rgba(217, 45, 32, 0.09)",
                                                    color: payment.paymentStatus === 1 ? "rgb(0, 138, 69)" : "rgb(217, 45, 32)",
                                                    fontWeight: "600",
                                                    fontSize: '14px',
                                                    "@media (maxWidth: 600px)": {
                                                        padding: "2px 5px",
                                                    },
                                                }}
                                            >
                                                <Tooltip title={payment.paymentStatus === 1 ? 'The due settlement deposit is successful and complete from our end (The settlement amount may take 2-3 hours to reflect in your account depending on the bank)' : payment.paymentStatus === 0 ? "The due settlement amount is yet to be settle from our end" : 'The due settlement amount is declined. Kindly contact the administration for more info'} placement="top" arrow>
                                                    <InfoOutlinedIcon className="utr-icon" fontSize="small" sx={{ cursor: 'pointer', margin: '0 5px 3px 0' }} />
                                                </Tooltip>
                                                {payment.paymentStatus === 1 ? "Processed" : payment.paymentStatus === 0 ? "Pending" : "Declined"}
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={payments.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};

export default SettlementHistory;
