import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Consultationimg from '../../Assets/ConsultationInfo.png';
import './ConsultationInfo.css';
import Sidebar from '../sidebar/Sidebar';
import Navbar from '../navbar/navbar';
import '../../Pages/Therapist/Home/home.scss';
import DBHandler from "../../Utils/DBHandler";
import Loader from '../Loader/Loader';
import { AuthContext } from "../../Context/AuthContext";

const ConsultationInfo = () => {
    const { userId } = useParams();
    const location = useLocation();
    const callData = location.state?.callData || {};
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { currentUser } = useContext(AuthContext);

    const getDateTime = () => {
        const convertTimestampToDate = (timestamp) => {
            return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
        };

        if (callData.callType === 'Live Call') {
            return convertTimestampToDate(callData.timestamp);
        } else {
            return callData.dateTimeSlot instanceof Date ? callData.dateTimeSlot : convertTimestampToDate(callData.dateTimeSlot);
        }
    };


    const dateTimeSlot = getDateTime();

    const dateOptions = { day: 'numeric', month: 'short', year: 'numeric', timeZone: 'Asia/Kolkata' };
    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: 'Asia/Kolkata' };

    const dateFormatter = new Intl.DateTimeFormat('en-IN', dateOptions);
    const timeFormatter = new Intl.DateTimeFormat('en-IN', timeOptions);

    const formattedDate = dateFormatter.format(dateTimeSlot);
    const formattedTime = timeFormatter.format(dateTimeSlot);

    useEffect(() => {
        const fetchBookings = async () => {
            const dbHandler = new DBHandler();
            setLoading(true);
            const data = await dbHandler.getUser(userId);
            setUserData(data);
            setLoading(false);
        };

        fetchBookings();
    }, [userId]);
    return (
        <div className='therapy_home'>
            <Sidebar userType={'therapist'} />
            <div className='homeContainer'>
                <Navbar userData={currentUser} userType={'therapist'} />
                {loading && <Loader />}
                <div className='consultation_info_container'>
                    <div className='consultation_img_container'>
                        <img
                            src={Consultationimg}
                            className='deleteAc_img'
                            alt="Patient Info"
                        />
                    </div>
                    <div className='consultation_information_container'>
                        <h2 className='consultation_info_heading'>
                            Client Information
                        </h2>
                        <div className='client_information_Container'>
                            <div className='client_info_card'>
                                <h2 className='info_sub_heading'>
                                    General
                                </h2>
                                <div className='booking_info'>
                                    <h3 className='booking_sub_heading'>Name</h3>
                                    <p className='booking_info_details'>{userData.name}</p>
                                </div>
                                <div className='booking_info'>
                                    <h3 className='booking_sub_heading'>Phone No</h3>
                                    <p className='booking_info_details'>{`+${userData.countryCode} ${userData.phone}`}</p>
                                </div>
                                <div className='booking_info'>
                                    <h3 className='booking_sub_heading'>Gender</h3>
                                    <p className='booking_info_details'>{userData.gender}</p>
                                </div>
                                <div className='booking_info'>
                                    <h3 className='booking_sub_heading'>Marital Status</h3>
                                    <p className='booking_info_details'>{userData.maritalStatus}</p>
                                </div>
                            </div>
                            <div className='client_info_card'>
                                <h2 className='info_sub_heading'>
                                    Lifestyle
                                </h2>
                                <div className='booking_info'>
                                    <h3 className='booking_sub_heading'>Occupation</h3>
                                    <p className='booking_info_details'>{userData.occupation}</p>
                                </div>
                                <div className='booking_info'>
                                    <h3 className='booking_sub_heading'>Activity Level</h3>
                                    <p className='booking_info_details'>{userData.activityLevel}</p>
                                </div>
                                <div className='booking_info'>
                                    <h3 className='booking_sub_heading'>Alcohol Consumption</h3>
                                    <p className='booking_info_details'>{userData.alcoholConsumption}</p>
                                </div>
                                <div className='booking_info'>
                                    <h3 className='booking_sub_heading'>Smoking Habits</h3>
                                    <p className='booking_info_details'>{userData.smokingHabits}</p>
                                </div>
                            </div>
                            <div className='client_info_card'>
                                <h2 className='info_sub_heading'>
                                    Medical
                                </h2>
                                <div className='booking_info'>
                                    <h3 className='booking_sub_heading'>Allergies</h3>
                                    <p className='booking_info_details'>{userData.allergies}</p>
                                </div>
                                <div className='booking_info'>
                                    <h3 className='booking_sub_heading'>Chronic Diseases</h3>
                                    <p className='booking_info_details'>{userData.chronicDiseases}</p>
                                </div>
                                <div className='booking_info'>
                                    <h3 className='booking_sub_heading'>Current Medication</h3>
                                    <p className='booking_info_details'>{userData.currentMedication}</p>
                                </div>
                                <div className='booking_info'>
                                    <h3 className='booking_sub_heading'>Past Medication</h3>
                                    <p className='booking_info_details'>{userData.pastMedication}</p>
                                </div>
                                <div className='booking_info'>
                                    <h3 className='booking_sub_heading'>Injuries</h3>
                                    <p className='booking_info_details'>{userData.injuries}</p>
                                </div>
                                <div className='booking_info'>
                                    <h3 className='booking_sub_heading'>Surgeries</h3>
                                    <p className='booking_info_details'>{userData.surgeries}</p>
                                </div>
                            </div>
                        </div>
                        <h2 className='consultation_info_heading mt-5 mb-4'>
                            {callData.callType === 'Booking' ? 'Booking Information' : 'Call Information'}
                        </h2>
                        <div className='booking_form_container'>
                            <div className='booking_info'>
                                <h3 className='booking_sub_heading'>Booking ID</h3>
                                <p className='booking_info_details'>
                                    {callData.status === 'Payment Fail'
                                        ? <span className='payment_failed'>Payment Failed</span>
                                        : callData.bookingId}
                                </p>

                            </div>
                            <div className='booking_info'>
                                <h3 className='booking_sub_heading'>Call Type</h3>
                                <p className='booking_info_details'>{callData.callType}</p>
                            </div>
                            <div className='booking_info'>
                                <h3 className='booking_sub_heading'>Session Mode</h3>
                                <p className='booking_info_details'>{`${callData.sessionMode} Consultation`}</p>
                            </div>
                            <div className='booking_info'>
                                <h3 className='booking_sub_heading'>
                                    {callData.callType === 'Booking' ? 'Appointment Date' : 'Call Date & Time'}
                                </h3>
                                <p className='booking_info_details'>{`${formattedDate} @${formattedTime}`}</p>
                            </div>
                            <div className='booking_info'>
                                <h3 className='booking_sub_heading'>Amount</h3>
                                <p className='booking_info_details'>{`₹${callData.amt}`}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConsultationInfo;
