import React, { useEffect, useState } from 'react';
import './welcome.scss';
import { Link } from 'react-router-dom';
import image from '../../Assets/therapistLogin-removebg.png';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { usePsychologist } from '../../Utils/Psychologist/PsychologistContext';
import Tooltip from '@mui/material/Tooltip';

const Welcome = ({ currBalance = 0, lastSettledBalance = 0, lastSettledDate = null }) => {
    const { psychologist } = usePsychologist();
    const [currentDate, setCurrentDate] = useState('');

    useEffect(() => {
        const date = new Date();
        const options = { weekday: 'short', month: 'short', day: 'numeric' };
        const indianDate = date.toLocaleDateString('en-IN', options);
        setCurrentDate(indianDate);
    }, []);

    const formatSettledDate = (date) => {
        if (!date) {
            return '';
        }
        const timestamp = date.toDate();
        const options = { weekday: 'short', month: 'short', day: 'numeric' };
        return timestamp.toLocaleDateString('en-IN', options);
    };

    const splitNumber = (number) => {
        const num = Number(number);
        if (isNaN(num)) {
            return { integerPart: '0', decimalPart: '00' };
        }

        const [integerPart, decimalPart] = num.toFixed(2).split('.');
        return { integerPart, decimalPart };
    };

    const { integerPart: intCurrBalance, decimalPart: decCurrBalance } = splitNumber(currBalance);
    const { integerPart: intLastBalance, decimalPart: decLastBalance } = splitNumber(lastSettledBalance);

    return (
        <div className='welcome-card card shadow'>
            <h2>Welcome Back, {psychologist.name}</h2>
            <p className='date'>{currentDate}</p>
            <div className='inner-card shadow'>
                <div className='section'>
                    <div className='payment'>
                        <div className='inner_section'>
                            <h3 className='currency_heading'>Current Balance</h3>
                            <Tooltip title="This is the total amount that is due to be deposited in your bank account after deduction of any other applicable charges, and adjustment of refunds and credits" placement="top" arrow>
                                <InfoOutlinedIcon className='welcome-icon' />
                            </Tooltip>
                        </div>

                        <p className="current_balance">
                            <span className="currency">₹</span>
                            <span className="amount">{intCurrBalance}</span>
                            <span className="decimal">.{decCurrBalance}</span>
                        </p>
                    </div>

                    <div className='middle_element'>
                        <div className='icon-and-line'>
                            <CheckCircleIcon className='check_icon' style={{ color: 'green' }} />
                            <div className='vertical-line'></div>
                        </div>
                        <div className='settlement'>
                            <h3 className='currency_heading mb-0'>Last Settlement</h3>
                            <p className='money'>
                                <span className="currency">₹</span>
                                <span className="amount">{intLastBalance}</span>
                                <span className="decimal">.{decLastBalance}</span>
                            </p>
                            <p className='para'>
                                {lastSettledDate ? (
                                    <>
                                        <FiberManualRecordIcon className='dot' />
                                        Deposited in your bank account on {formatSettledDate(lastSettledDate)}
                                        <FiberManualRecordIcon className='dot second' />
                                    </>
                                ) : (
                                    <>
                                        <FiberManualRecordIcon className='dot' />
                                        No previous settlement history found
                                        <FiberManualRecordIcon className='dot second' />
                                    </>
                                )}
                                <Link aria-current="page" to="/therapists/settlement">
                                    <span className='blue_span'> View all settlements</span>
                                </Link>
                                <ChevronRightIcon className='arrow' />
                            </p>
                        </div>
                    </div>

                    <img src={image} className='image' alt='Therapist Login' />
                </div>
            </div>
        </div>
    );
};

export default Welcome;
