import React from 'react';
import "./Home.css";

export default function FaqHome() {
    const faqsUsers = [
        {
            question: "How does the therapy process work in the application?",
            answer: "The therapy process typically involves creating an account, completing an initial assessment, and then choosing a licensed therapist.Users can engage in live video or messaging sessions, set goals, and access resources tailored to their mental health needs."
        },
        {
            question: "How do I connect with a therapist on the app?",
            answer: "Connecting with a therapist is easy. After signing up and completing the assessment, you can browse through profiles of licensed therapists, read their bios, and choose the one that best fits your preferences.From there, you can schedule sessions or start messaging."
        },
        {
            question: "What qualifications do the therapists have?",
            answer: "Our therapists are licensed professionals with degrees in psychology, counselling, or related fields.They have undergone rigorous training and hold valid licenses, ensuring they meet the highest standards of competence and ethical practice."
        },
        {
            question: "Is the app suitable for crisis situations?",
            answer: "While our app provides valuable support, it is not intended for crisis situations. If you are in immediate danger or experiencing a crisis, please contact emergency services or a crisis hotline.Our platform is designed for ongoing support and personal development."
        },
        {
            question: "What is a peer and how it is different from a therapist?",
            answer: "e Peer counselors do not have an actual degree but utilizing their wealth of experience and supportive nature, draw upon personal stories to empathize and aid individuals based  on their own life encounters. "
        },
        {
            question: "Are there any age restrictions for using the app?",
            answer: "Users must be at least 18 years old to use the app independently. For users under 18, parental consent and supervision are required."
        }
    ];

    const faqsTherapist = [
        {
            question: "What is the difference between a therapist and peer councillors?",
            answer: 'Therapists are licensed professionals with formal training in mental health, offering clinical expertise. Peer counsellors, while experienced and supportive, share personal experiences to empathize and assist based on their lived experiences. Both play unique roles in providing valuable support.'
        },
        {
            question: 'What should I do if I experience technical issues during a session?',
            answer: ' If you encounter technical issues, first check your internet connection. If problems persist, navigate to the website\'s settings and select the "Technical Support" option. Our support team will assist you in resolving any technical issues promptly.'
        },
        {
            question: 'What is the role of a licensed therapist?',
            answer: 'A licensed therapist is a trained mental health professional who provides evidence-based therapy to help individuals manage and overcome various psychological and emotional challenges. They use scientifically proven techniques to support their clients’ mental health and well-being. Their work includes assessing, diagnosing, and treating mental health disorders.'
        },
        {
            question: 'What is the role of a peer counselor?',
            answer: 'A peer counselor uses their personal experiences and supportive nature to help others facing similar challenges. Unlike licensed therapists, peer counselors may not have formal clinical training but draw upon their lived experiences to offer empathy and practical advice. They provide support by sharing their own journey and helping others navigate their own challenges.'
        },
        {
            question: 'How can I provide feedback or report issues with the website?',
            answer: 'We welcome your feedback! You can provide input or report issues through the website\'s settings menu, where you\'ll find a dedicated "Feedback" or "Report a Problem" option. Alternatively, you can reach out to our support team via email.'
        },
        {
            question: 'What security measures are in place to protect user data?',
            answer: 'We employ robustsecurity measures, including encryption protocols and strict privacy policies, to safeguard user data. Confidentiality and privacy are fundamental principles guiding our app\'s design and functionality.'
        }
    ];

    return (
        <div className="container-faq" data-aos="fade-up" data-aos-delay="150">
            <div className="row faq-row justify-content-center">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="section-title text-center mt-4">
                                <h1>FAQs</h1>
                            </div>
                            <div className="row justify-content-center mt-2">
                                <div className="col-lg-9">
                                    <ul className="nav nav-tabs nav-tabs-custom nav-justified" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="pills-general-tab" data-bs-toggle="pill" data-bs-target="#pills-general" type="button" role="tab" aria-controls="pills-general" aria-selected="true">
                                                <span className="font-size-18">Users</span>
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-buyers-tab" data-bs-toggle="pill" data-bs-target="#pills-buyers" type="button" role="tab" aria-controls="pills-buyers" aria-selected="false">
                                                <span className="font-size-18">Peers & Therapists</span>
                                            </button>
                                        </li>
                                    </ul>
                                    <div className="tab-content pt-3" id="pills-tabContent">
                                        <div className="tab-pane fade active show" id="pills-general" role="tabpanel" aria-labelledby="pills-general-tab">
                                            <div className="row g-4 mt-2">
                                                {faqsUsers.map((faq, index) => (
                                                    <div className="col-lg-6" key={index}>
                                                        <h5>{faq.question}</h5>
                                                        <p className="text-muted">{faq.answer}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="pills-buyers" role="tabpanel" aria-labelledby="pills-buyers-tab">
                                            <div className="row g-4 mt-2">
                                                {faqsTherapist.map((faq, index) => (
                                                    <div className="col-lg-6" key={index}>
                                                        <h5>{faq.question}</h5>
                                                        <p className="text-muted">{faq.answer}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
