import React from 'react'
import './UserTerms.css'
import termsimg from '../../Assets/terms and conditions.png'
import '../PrivacyPolicy/PrivacyPolicy.css'
import HomeNavbar from '../../Components/Navbar';

const TherapistsTerms = () => {
  return (
    <>
      <HomeNavbar />
      <div className="terms_image_container">
        <div className="terms_image">
          <img src={termsimg} alt="privacypolicy" />
        </div>
        <div className="terms_text">
          <h1 className="terms_heading">Terms and Conditions</h1>
          <p className="terms_paragraph">
            Explore the guidelines that govern your use of our services, ensuring transparency and fairness for all users.
          </p>
        </div>
      </div>

      <div className='privacypolicy_details_container'>
        <div className='common_privacy_policy_para'>
          <span className='privacypolicy_details_heading'>T&C for Therapists<span className="logo-dot">.</span></span>
          <p className='privacy_first_para'>
            Welcome to Thapy! These Terms and Conditions ("Terms") govern your use of our platform as a therapist. By registering as a therapist on Thapy, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our platform.
          </p>
        </div>

        <div className="container-policy">
          <div className="card">
            <div className="card-body">
              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>1. </span>Eligibility
                </span>
                <p>You must be a licensed therapist with the appropriate certification and credentials to provide mental health services in your jurisdiction. By registering on Thapy, you represent and warrant that you meet these requirements.</p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>2. </span>Account Registration
                </span>
                <p>You may be required to create an account to access certain features of Thapy as a therapist. When registering for an account, you agree to provide accurate and complete information about your qualifications, experience, and certifications.</p>
              </div>


              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>3. </span>Professional Conduct
                </span>
                <p>As a therapist on Thapy, you agree to adhere to the highest standards of professional conduct and ethics. This includes:</p>
                <ul className='custom-list'>
                  <li>
                    Providing services within the scope of your expertise and training.
                  </li>
                  <li>
                    Maintaining client confidentiality and privacy in accordance with applicable laws and regulations.
                  </li>
                  <li>Refraining from engaging in any conduct that could harm or exploit clients, including but not limited to sexual misconduct, financial exploitation, or abuse of power.
                  </li>
                  <li>
                    Engage in any conduct that could damage, disable, or impair Thapy or its users experience.
                  </li>

                </ul>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>4. </span>Quality of Care
                </span>
                <p>You agree to provide quality mental health services to clients on Thapy, including conducting thorough assessments, developing appropriate treatment plans, and offering compassionate support and guidance.</p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>5. </span>Fees and Payment
                </span>
                <p>Thapy may deduct a service fee from your earnings for using the platform, which will be clearly outlined in your agreement with Thapy.</p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>6. </span>Compensation
                </span>
                <p>Your compensation will be based on the number of sessions you conduct with clients on Thapy. Thapy will take a percentage cut from your session fees as agreed upon in your contract.</p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>7. </span>Scheduling and Availability
                </span>
                <p>You have the flexibility to arrange your own schedule and availability on Thapy. You will use the scheduling system provided by Thapy to book sessions with clients when you are free.</p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>8. </span>Content Creation
                </span>
                <p>You may contribute to the motivational content section of Thapy by creating reels or other materials for the benefit of users. Your contributions may be compensated separately as agreed upon with Thapy.</p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>9. </span>User Ratings
                </span>
                <p>After every session with a user, you will be rated based on the quality of your service. Your ratings will be visible to users on Thapy, and they may influence user decisions when choosing
                  a therapist.
                </p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>10. </span>Termination
                </span>
                <p>We reserve the right to suspend or terminate your account on Thapy at any time, without prior notice or liability, for any reason whatsoever, including if we believe that you have violated these Terms or engaged in misconduct.
                </p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>11. </span>Changes to Terms
                </span>
                <p>We may revise these Terms from time to time in our sole discretion. The most current version will always be available on Thapy. By continuing to use Thapy after any revisions become effective, you agree to be bound by the revised Term.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TherapistsTerms
