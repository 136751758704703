import React, { useContext } from 'react'
import { AuthContext } from "../../../Context/AuthContext";
import Sidebar from '../../../Components/sidebar/Sidebar';
import Navbar from '../../../Components/navbar/navbar';
import './style.css'

export default function PeerTermsCond() {
    const { currentUser } = useContext(AuthContext);
    return (
        <>
            <div className='consultant_home'>
                <Sidebar userType={'therapist'} />
                <div className='Consultant_home_container'>
                    <Navbar userData={currentUser} userType={'therapist'} />
                    <div className="container-content" data-aos="fade-up">
                        <div className="compalinace-content">
                            <div className="content-container">
                                <p className="content-head">Terms & Conditions</p>
                                <div className="content-separator"></div>
                                <p className="updated-date">Last updated on Aug 1st 2024</p>
                                <p className="content-text">
                                    Welcome to <b>Thapy</b>! These Terms and Conditions ("Terms") govern your use of our platform as a Peer. By registering as a counselor on <b>Thapy</b>, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our platform.
                                </p>

                                <p className="content-title"><strong>Eligibility:</strong></p>
                                <p className="content-text">
                                    You must be at least 18 years old to register as a peer on <b>Thapy</b>. By registering on <b>Thapy</b>, you
                                    represent and warrant that you meet this requirement.
                                </p>

                                <p className="content-title"><strong>Account Registration:</strong></p>
                                <p className="content-text">
                                    You are required to provide accurate and complete information during the registration process,
                                    Including your name, age, and any additional details.
                                </p>

                                <p className="content-title"><strong>Verification Process:</strong></p>
                                <p className="content-text">
                                    You agree to cooperate with <b>Thapy</b>'s verification process, which may include providing
                                    documentation or additional information to confirm your identity and experiences.
                                </p>

                                <p className="content-title"><strong>Communication Guidelines:</strong></p>
                                <p className="content-text">
                                    You agree to engage in respectful and supportive communication with other users on <b>Thapy</b>.
                                    Harassment, discrimination, or any form of inappropriate behavior will not be tolerated.
                                </p>

                                <p className="content-title"><strong>Peer Matching:</strong></p>
                                <p className="content-text">
                                    You understand that the matching process is based on the information you provide in your
                                    profile, and <b>Thapy</b> cannot guarantee compatibility or outcomes from peer connections.
                                </p>

                                <p className="content-title"><strong>Pricing and Compensation:</strong></p>
                                <p className="content-text">
                                    You agree to the prices set for counselors on <b>Thapy</b>, which may be subject to change depending
                                    on your reviews and performance. As an incentive, you may earn more as you improve your
                                    ratings and receive positive feedback from users.
                                </p>

                                <p className="content-title"><strong>User Ratings:</strong></p>
                                <p className="content-text">
                                    Your performance will be rated by users after each interaction, based on factors such as
                                    empathy, communication, and effectiveness. Your ratings may influence your compensation and
                                    opportunities on <b>Thapy</b>.
                                </p>

                                <p className="content-title"><strong>Confidentiality:</strong></p>
                                <p className="content-text">
                                    You agree to maintain the confidentiality of any information shared by other users during peer
                                    interactions. Respect the privacy and boundaries of your peers at all times.
                                </p>

                                <p className="content-title"><strong>Investigation and Termination:</strong></p>
                                <p className="content-text">
                                    <b>Thapy</b> reserves the right to investigate any reports of misconduct or violations of these Terms
                                    against counselors. If found guilty, your account may be terminated immediately without prior
                                    notice or liability.
                                </p>

                                <p className="content-title"><strong>Availability for Instant Calls:</strong></p>
                                <p className="content-text">
                                    You have the option to make yourself available for instant calls with users on <b>Thapy</b>. This
                                    feature is voluntary and can be enabled or disabled based on your availability and preferences.
                                </p>

                                <p className="content-title"><strong>Access to Community Page:</strong></p>
                                <p className="content-text">
                                    You are welcome to participate in the community page on <b>Thapy</b>, where users can connect and
                                    discuss various issues. Your contributions should be respectful and adhere to the community
                                </p>

                                <p className="content-title"><strong>Changes to Terms:</strong></p>
                                <p className="content-text">
                                    We may revise these Terms from time to time in our sole discretion. The most current version will always be available on <b><b>Thapy</b></b>. By continuing to use, <b><b>Thapy</b></b> after any revisions become effective, you agree to be bound by the revised Term.
                                </p>

                                <p className="content-title"><strong>Grievance Redressal:</strong></p>
                                <p className="content-text">
                                    We take user concerns seriously. If you have any issues with <b>Thapy</b>'s services, you can file a grievance through our dedicated support channel: <a href="mailto:contact@thapy.com">contact@thapy.com</a>
                                    . We strive to resolve all grievances fairly and efficiently. You can expect a response
                                    within one month of submitting your complaint.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
