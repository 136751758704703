import React, { useState, useEffect, useContext } from 'react';
import Sidebar from '../../../Components/sidebar/Sidebar';
import "./home.scss";
// import Navbar from '../../../Components/navbar/navbar';
import Navbar from '../../../Components/navbar/navbar';
import Welcome from '../../../Components/welcome/Welcome';
// import Analytics from '../../../Components/analytics/Dashboard_Analytics'
// import Calendar from '../../../Components/Calendar/Calendar'
import LineChart from '../../../Components/Line_Chart/LineChart';
import DailyFeed from '../../../Components/Daily_feed/DailyFeed';
import Toggle from '../../../Components/booking_toggle/Toggle';
import Appointments from '../../../Components/appointments/Appointments';
import Widget from '../../../Components/widget/Widget';
import CallRequest from '../../../Components/CallRequest/CallRequest';
import DBHandler from "../../../Utils/DBHandler";
import Loader from '../../../Components/Loader/Loader';
import { AuthContext } from "../../../Context/AuthContext";

const TherapistHome = ({ incomingCall, callData, setIncomingCall }) => {
    const [therapistData, setTherapistData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { currentUser } = useContext(AuthContext);
    // const psyId = currentUser.id;
    const psyId = 'd0somDf5SJVkaO7d8oNOc8Chtvb2';

    const currentDate = new Date();
    const offset = 330; // IST offset in minutes (UTC+05:30)
    const istDate = new Date(currentDate.getTime() + offset * 60 * 1000);
    const istDateString = istDate.toISOString().split('T')[0];

    useEffect(() => {
        const fetchBookings = async () => {
            const dbHandler = new DBHandler();
            setLoading(true);
            const data = await dbHandler.getTherapistDashboardInfo(psyId);
            setTherapistData(data);
            setLoading(false);
        };

        fetchBookings();
    }, [psyId]);

    const tenDaysPayments = therapistData.tenDaysPayments || [];
    const latestPayment = tenDaysPayments[tenDaysPayments.length - 1];
    const latestDateString = latestPayment ? latestPayment.date : '';

    return (
        <div className='therapy_home'>
            {loading && <Loader />}
            <Sidebar userType={'therapist'} />
            <div className='homeContainer'>
                <Navbar userData={currentUser} userType={'therapist'} />
                <Welcome
                    currBalance={
                        latestDateString === istDateString
                            ? latestPayment?.amt || 0.00
                            : 0.00
                    }
                    lastSettledBalance={therapistData.lastSettledAmt}
                    lastSettledDate={therapistData.lastSettledDate}
                />
                <div className="widgets">
                    <Widget type="user" value={therapistData.uniquePatients?.length || 0} />
                    <Widget type="liveCalls" value={therapistData.totalLiveCalls} />
                    <Widget type="bookingCalls" value={therapistData.totalBookingCalls} />
                    <Widget type="rating" value={therapistData.totalRatings} />
                </div>
                {/* <Analytics /> */}
                <div className='third_container'>
                    <LineChart tenDaysPayments={therapistData.tenDaysPayments} />
                    <DailyFeed className='home_dailyfeed' />
                </div>
                <div className='fourth_container'>
                    {/* <Calendar /> */}
                    <Toggle />
                    <Appointments />
                </div>
                {incomingCall && callData && (
                    <CallRequest
                        callData={callData}
                        psyId={psyId}
                        onClose={() => setIncomingCall(false)}
                    />
                )}
            </div>
        </div>
    )
}

export default TherapistHome
