import { db } from "../Config/Config";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { doc, setDoc, getDoc, serverTimestamp, Timestamp, increment, arrayUnion } from "firebase/firestore";
import Psychologist from "./Psychologist/Psychologist";
import User from "./User/User";
import Credit from "./Credit";
import BookingDetails from "./BookingDetails";
import Payment from "./Payment";
// import TherapistDashboardInfo from "./TherapistDashboard";

class DBHandler {
    constructor(collectionName) {
        this.collectionName = collectionName;
    }

    doesUserExist = async (userId) => {
        try {
            const userRef = db.collection('users').doc(userId);
            const userSnapshot = await userRef.get();
            return userSnapshot.exists;
        } catch {
            return false;
        }
    }

    getCustomUID = async (firebaseUID) => {
        const docRef = db.collection('uidRef').doc(firebaseUID);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return docSnap.data().customUID;
        } else {
            return null;
        }
    };

    storeUIDReference = async (firebaseUID, customUID) => {
        await setDoc(doc(db, 'uidRef', firebaseUID), { customUID });
    };



    addUserData = async (user) => {
        if (user.id === '') return;
        const uniqueCode = this.generateUniqueCode(user.name);
        const referralCode = `USR_${uniqueCode}`;

        await this.addReferralCode({ referCode: referralCode, userId: user.id });

        const userData = {
            imagePath: user.imagePath,
            uid: user.uid,
            name: user.name,
            email: user.email,
            countryCode: user.countryCode,
            phone: user.phone,
            activityLevel: user.activityLevel,
            alcoholConsumption: user.alcoholConsumption,
            referCode: referralCode,
            allergies: user.allergies,
            chronicDiseases: user.chronicDiseases,
            currentMedication: user.currentMedication,
            gender: user.gender,
            injuries: user.injuries,
            maritalStatus: user.maritalStatus,
            occupation: user.occupation,
            pastMedication: user.pastMedication,
            smokingHabits: user.smokingHabits,
            surgeries: user.surgeries,
            creditEarned: user.creditEarned,
            creditRedeemed: user.creditRedeemed,
            referralUserId: user.referralUserId,
            creditHistory: user.creditHistory,
            isReferralTrue: user.isReferralTrue,
            bookmarkedPost: user.bookmarkedPost,
            config: user.config,
        };

        await db.collection('users').doc(user.id).set(userData);
    };

    getUser = async (customUID) => {
        try {
            const userRef = db.collection('users').doc(customUID);
            const userSnapshot = await userRef.get();

            if (userSnapshot.exists) {
                const userData = userSnapshot.data();

                const creditHistoryData = userData.creditHistory || [];
                const creditHistory = creditHistoryData.map(entry => {
                    return new Credit({
                        credit: entry.credit || 0,
                        isCredited: entry.isCredited || 0,
                        timestamp: entry.timestamp.toDate(),
                    });
                });

                const user = new User({
                    id: customUID,
                    uid: userData.uid || '',
                    imagePath: userData.imagePath || '',
                    name: userData.name || '',
                    email: userData.email || '',
                    countryCode: userData.countryCode || '',
                    phone: userData.phone || '',
                    activityLevel: userData.activityLevel || '',
                    alcoholConsumption: userData.alcoholConsumption || '',
                    referCode: userData.referCode || '',
                    allergies: userData.allergies || '',
                    chronicDiseases: userData.chronicDiseases || '',
                    currentMedication: userData.currentMedication || '',
                    emailId: userData.emailId || '',
                    gender: userData.gender || '',
                    injuries: userData.injuries || '',
                    maritalStatus: userData.maritalStatus || '',
                    occupation: userData.occupation || '',
                    pastMedication: userData.pastMedication || '',
                    smokingHabits: userData.smokingHabits || '',
                    surgeries: userData.surgeries || '',
                    creditEarned: userData.creditEarned || 0,
                    creditRedeemed: userData.creditRedeemed || 0,
                    referralUserId: userData.referralUserId || '',
                    creditHistory: creditHistory,
                    isReferralTrue: userData.isReferralTrue || 0,
                });

                return user;
            } else {
                console.error('Error getting user. Please try again later!!');
                return new User();
            }
        } catch (error) {
            console.error('Error getting user:', error);
            return new User();
        }
    };

    updateUser = async ({ id, uid, phone, name, emailId, countryCode, imagePath, activityLevel, alcoholConsumption, allergies, chronicDiseases, currentMedication, gender, injuries, maritalStatus, occupation, pastMedication, smokingHabits, surgeries, creditHistory }) => {
        if (!id) return;

        const parameterMap = {
            name,
            phone,
            emailId,
            countryCode,
            imagePath,
            activityLevel,
            alcoholConsumption,
            allergies,
            chronicDiseases,
            currentMedication,
            gender,
            injuries,
            maritalStatus,
            occupation,
            pastMedication,
            smokingHabits,
            surgeries,
            creditHistory,
        };

        const userMap = {};

        for (const [key, value] of Object.entries(parameterMap)) {
            if (value !== undefined && value !== null) {
                userMap[key] = value;
            }
        }

        if (Object.keys(userMap).length > 0) {
            try {
                await db.collection('users').doc(id).update(userMap);
            } catch (error) {
                const errorMessage = error.toString().split(']')[1].trim();
                alert(`Error updating user details!! ${errorMessage}`);
            }
        }
    };

    generateUniqueCode = (userName) => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let uniqueCode = '';
        for (let i = 0; i < 5; i++) {
            uniqueCode += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return userName.substring(0, Math.min(4, userName.length)) + uniqueCode;
    };

    addReferralCode = async ({ referCode, userId }) => {
        try {
            await db.collection('referralCode').doc(referCode).set({ userId: userId });
        } catch (e) {
            console.error('Error adding referral code:', e);
        }
    };

    checkReferCode = async ({ referCode }) => {
        try {
            const querySnapshot = await db.collection('referralCode').get();
            for (const docSnapshot of querySnapshot.docs) {
                if (docSnapshot.id === referCode) {
                    return docSnapshot.get('userId');
                }
            }
            return '';
        } catch (error) {
            console.error('Error checking referCode:', error);
            return '';
        }
    };

    updateReferralStatus = async ({ userId, isReferralTrue }) => {
        try {
            await db.collection('users').doc(userId).update({
                isReferralTrue: 0,
            });
        } catch (error) {
            console.error('Error updating user referral status:', error);
        }
    };

    addPsychologist = async (psychologist) => {
        if (psychologist.id === '') return;

        const therapistsData = {
            email: psychologist.email,
            uid: psychologist.uid,
            phone: psychologist.phone,
            countryCode: psychologist.countryCode,
            identityProofPath: psychologist.identityProofPath,
            professionProofPath: psychologist.professionProofPath,
            upi: psychologist.upi,
            lastOnline: serverTimestamp(),
        };

        const therapists2Data = {
            name: psychologist.name,
            imagePath: psychologist.imagePath,
            areas: psychologist.areas,
            languages: psychologist.languages,
            about: psychologist.about,
            price: psychologist.price,
            rating: psychologist.rating,
            overallRating: psychologist.overallRating,
            noOfRatings: psychologist.noOfRatings,
            verified: psychologist.verified,
            professional: psychologist.professional,
            calls: psychologist.calls,
            status: psychologist.status,
            meetLink: "",
        };

        await Promise.all([
            db.collection('therapists').doc(psychologist.id).set(therapistsData),
            db.collection('therapists2').doc(psychologist.id).set(therapists2Data),
        ]);
    };

    doesPsychologistExist = async (psychologistId) => {
        try {
            const therapistsRef = db.collection('therapists').doc(psychologistId);
            const therapists2Ref = db.collection('therapists2').doc(psychologistId);

            const [therapistsSnapshot, therapists2Snapshot] = await Promise.all([
                therapistsRef.get(),
                therapists2Ref.get()
            ]);

            return therapistsSnapshot.exists && therapists2Snapshot.exists;
        } catch {
            return false;
        }
    };

    getPsychologist = async (psychologistId) => {
        try {
            const therapistsRef = db.collection('therapists').doc(psychologistId);
            const therapists2Ref = db.collection('therapists2').doc(psychologistId);

            const [therapistsSnapshot, therapists2Snapshot] = await Promise.all([
                therapistsRef.get(),
                therapists2Ref.get()
            ]);

            if (therapistsSnapshot.exists && therapists2Snapshot.exists) {
                const therapistsData = therapistsSnapshot.data();
                const therapists2Data = therapists2Snapshot.data();

                const combinedData = { ...therapistsData, ...therapists2Data };

                const psychologist = new Psychologist({
                    id: psychologistId,
                    uid: combinedData.uid || '',
                    name: combinedData.name || '',
                    email: combinedData.email || '',
                    phone: combinedData.phone || '',
                    countryCode: combinedData.countryCode || '',
                    imagePath: combinedData.imagePath || '',
                    areas: combinedData.areas || [],
                    languages: combinedData.languages || [],
                    about: combinedData.about || '',
                    price: combinedData.price || 0,
                    rating: combinedData.rating || 0,
                    overallRating: combinedData.overallRating || 0,
                    noOfRatings: combinedData.noOfRatings || 0,
                    identityProofPath: combinedData.identityProofPath || '',
                    professionProofPath: combinedData.professionProofPath || '',
                    verified: combinedData.verified || -1,
                    professional: combinedData.professional || false,
                    upi: combinedData.upi || '',
                    calls: combinedData.calls ? new Map(Object.entries(combinedData.calls)) : new Map(),
                    meetLink: combinedData.meetLink || ''
                });

                return psychologist;
            } else {
                return new Psychologist();
            }
        } catch (error) {
            console.error("Error:", error);
            return new Psychologist();
        }
    };


    updatePsychologist = async ({
        id,
        uid,
        name,
        email,
        phone,
        countryCode,
        imagePath,
        areas,
        languages,
        about,
        price,
        rating,
        overallRating,
        noOfRatings,
        identityProofPath,
        professionProofPath,
        verified,
        professional,
        upi,
        calls,
        status,
        lastOnline,
        timeSlotsMap,
    }) => {
        if (!id) return;

        const therapistsMap = {};
        if (uid) therapistsMap.uid = uid;
        if (email) therapistsMap.email = email;
        if (phone) therapistsMap.phone = phone;
        if (countryCode) therapistsMap.countryCode = countryCode;
        if (identityProofPath) therapistsMap.identityProofPath = identityProofPath;
        if (professionProofPath) therapistsMap.professionProofPath = professionProofPath;
        if (upi) therapistsMap.upi = upi;
        if (lastOnline) therapistsMap.lastOnline = serverTimestamp();
        if (timeSlotsMap) therapistsMap.timeSlotsMap = timeSlotsMap;

        const therapists2Map = {};
        if (name) therapists2Map.name = name;
        if (imagePath) therapists2Map.imagePath = imagePath;
        if (areas) therapists2Map.areas = areas;
        if (languages) therapists2Map.languages = languages;
        if (about) therapists2Map.about = about;
        if (price) therapists2Map.price = price;
        if (rating) therapists2Map.rating = rating;
        if (overallRating) therapists2Map.overallRating = overallRating;
        if (noOfRatings) therapists2Map.noOfRatings = noOfRatings;
        if (verified !== undefined) therapists2Map.verified = verified;
        if (professional !== undefined) therapists2Map.professional = professional;
        if (calls) therapists2Map.calls = calls;
        if (status) therapists2Map.status = status;

        try {
            if (Object.keys(therapistsMap).length > 0) {
                await db.collection('therapists').doc(id).update(therapistsMap);
            }

            if (Object.keys(therapists2Map).length > 0) {
                await db.collection('therapists2').doc(id).update(therapists2Map);
            }
        } catch (error) {
            console.error('Error updating psychologists: ', error);
        }
    };

    doesPsychologistDetailsExist = async (psychologist) => {
        if (psychologist.id === '') return false;
        try {
            if (psychologist.email === '' || psychologist.phone === '' || psychologist.countryCode === '' || psychologist.imagePath === '' || psychologist.areas.length === 0 || psychologist.languages.length === 0 || psychologist.about === '' || psychologist.price === 0 || psychologist.identityProofPath === '' || psychologist.professionProofPath === '' || psychologist.upi === '') {
                return false;
            }
            return true;
        } catch {
            return false;
        }
    };

    fetchPsychologistList = async (psyType, limit) => {
        const psychologists = [];
        const now = new Date();

        try {
            const querySnapshot = await db
                .collection("therapists2")
                .where("verified", "==", 0)
                .where("professional", "==", psyType)
                .limit(limit)
                .get();

            for (const doc of querySnapshot.docs) {
                const psyData = doc.data();

                // Check lastOnline status from "therapists" collection
                const therapistDoc = await db
                    .collection("therapists")
                    .doc(doc.id)
                    .get();

                const therapistData = therapistDoc.data() || {};

                // Check if the lastOnline timestamp is older than 330 seconds
                const lastOnline = therapistData.lastOnline?.toDate();
                const difference = now - lastOnline;

                if (difference / 1000 > 330 && psyData.status === "Online") {
                    // Update status in the retrieved list
                    psyData.status = "Offline";

                    try {
                        await db
                            .collection("therapists2")
                            .doc(doc.id)
                            .update({ status: "Offline" });
                    } catch (e) {
                        console.error(e);
                    }
                }

                // Add therapist to list
                psychologists.push({
                    id: doc.id,
                    name: psyData.name,
                    imagePath: psyData.imagePath || '',
                    areas: psyData.areas || '',
                    languages: psyData.languages || '',
                    about: psyData.about || '',
                    price: psyData.price,
                    rating: psyData.rating,
                    overallRating: psyData.overallRating,
                    noOfRatings: psyData.noOfRatings,
                    verified: psyData.verified,
                    professional: psyData.professional,
                    calls: psyData.calls || {},
                    status: psyData.status || "Offline",
                    meetLink: psyData.meetLink || "",
                });
            }
        } catch (e) {
            console.error(e);
            return [];
        }

        return psychologists;
    };

    fetchPsychologistListLazy = async (psyType, limit = 10, lastOrder = null) => {
        const psychologists = [];
        const now = new Date();
        let lastDocument = null;

        try {
            let query = firebase.firestore()
                .collection('therapists2')
                .where("verified", "==", 0)
                .where("professional", "==", psyType)
                .limit(limit);

            if (lastOrder) {
                query = query.startAfter(lastOrder);
            }

            const querySnapshot = await query.get();

            for (let index = 0; index < querySnapshot.docs.length; index++) {
                const doc = querySnapshot.docs[index];
                const psyData = doc.data();

                // Check lastOnline status from "therapists" collection
                const therapistDoc = await db
                    .collection("therapists")
                    .doc(doc.id)
                    .get();

                const therapistData = therapistDoc.data() || {};

                // Check if the lastOnline timestamp is older than 330 seconds
                const lastOnline = therapistData.lastOnline?.toDate();
                const difference = now - lastOnline;

                if (difference / 1000 > 330 && psyData.status === "Online") {
                    // Update status in the retrieved list
                    psyData.status = "Offline";

                    try {
                        await db
                            .collection("therapists2")
                            .doc(doc.id)
                            .update({ status: "Offline" });
                    } catch (e) {
                        console.error(e);
                    }
                }

                // Add therapist to list
                psychologists.push({
                    id: doc.id,
                    name: psyData.name,
                    imagePath: psyData.imagePath || '',
                    areas: psyData.areas || '',
                    languages: psyData.languages || '',
                    about: psyData.about || '',
                    price: psyData.price,
                    rating: psyData.rating,
                    overallRating: psyData.overallRating,
                    noOfRatings: psyData.noOfRatings,
                    verified: psyData.verified,
                    professional: psyData.professional,
                    calls: psyData.calls || {},
                    status: psyData.status || "Offline",
                    meetLink: psyData.meetLink || "",
                });

                if (index === querySnapshot.size - 1) {
                    lastDocument = doc;
                }
            }
        } catch (e) {
            console.error(e);
            return { psychologists: [], lastDocument: null };
        }

        return { psychologists, lastDocument };
    };

    getBookingHistory = async (psyId, callType, limit = 10, lastOrder = null) => {
        try {
            let query = firebase.firestore()
                .collection('bookings')
                .doc(psyId)
                .collection('bookingData')
                .orderBy(firebase.firestore.FieldPath.documentId(), 'desc')
                .where(firebase.firestore.FieldPath.documentId(), '>=', `${callType.toLowerCase()}`)
                .where(firebase.firestore.FieldPath.documentId(), '<', `${callType.toLowerCase()}z`)
                .limit(limit);

            if (lastOrder) {
                query = query.startAfter(lastOrder);
            }

            const orderSnapshot = await query.get();
            const bookings = [];
            let lastDocument = null;

            orderSnapshot.forEach((orderDoc, index) => {
                const bookingData = orderDoc.data();
                const booking = BookingDetails.fromMap(bookingData, orderDoc);
                bookings.push(booking);

                if (index === orderSnapshot.size - 1) {
                    lastDocument = orderDoc;
                }
            });

            return { bookings, lastDocument };
        } catch (e) {
            console.error("Error fetching payment details. Please try again later!!", e);
            return { bookings: [], lastDocument: null };
        }
    }

    // getFilteredBookings = async (userId, tabTypes) => {
    //     try {
    //         const doc = await db.collection('bookings').doc(userId).get();
    //         if (doc.exists) {
    //             const bookingsList = [];
    //             const bookings = doc.data().bookings;

    //             bookings.forEach((booking) => {
    //                 const bookingMap = BookingDetails.fromMap(booking);
    //                 if (tabTypes.includes(bookingMap.callType)) {
    //                     bookingsList.push(bookingMap);
    //                 }
    //             });

    //             return bookingsList;
    //         } else {
    //             return [];
    //         }
    //     } catch (error) {
    //         console.error("Error fetching bookings: ", error);
    //         return [];
    //     }
    // }

    initiateBookingStatus = async (psyName, bookingData, statusValue) => {
        const batch = db.batch();
        const updates = [
            this.updateBookings(batch, bookingData, bookingData.psyId, statusValue),
            this.updateBookings(batch, bookingData, bookingData.userId, statusValue)
        ];

        if (statusValue === 1) {
            updates.push(this.updatePaymentHistory(batch, bookingData, psyName));
            updates.push(this.updateTherapistDashboardInfo(batch, bookingData));
        }

        await Promise.all(updates);
        await batch.commit();
    };

    updateBookings = async (batch, bookingData, docId, statusValue) => {
        const docRef = db.collection('bookings').doc(docId).collection('bookingData').doc(bookingData.bookingId);
        batch.update(docRef, { bookingStatus: statusValue });
    };


    generateTransactionId = () => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let uniqueCode = '';
        for (let i = 0; i < 10; i++) {
            uniqueCode += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return 'trn_' + uniqueCode;
    };

    updatePaymentHistory = async (batch, bookingData, psyName) => {
        const docRef = db.collection('paymentHistoryTherapist').doc(bookingData.psyId);
        const paymentHistoryRef = docRef.collection('paymentHistory');
        const currentDate = new Date();
        const offset = 330;
        const istDate = new Date(currentDate.getTime() + offset * 60 * 1000);
        const payableAmt = parseFloat(bookingData.amt.toFixed(2));
        const dateKey = istDate.toISOString().split('T')[0]; // Get date in YYYY-MM-DD format
        const transactionId = this.generateTransactionId();
        const paymentData = {
            amt: payableAmt,
            createdOn: Timestamp.fromDate(currentDate),
            paymentStatus: 0,
            utr: '',
            trxId: transactionId,
        };

        const paymentDocRef = paymentHistoryRef.doc(dateKey);
        const paymentDoc = await paymentDocRef.get();
        if (paymentDoc.exists) {
            const existingEntry = paymentDoc.data();
            paymentData.trxId = existingEntry.trxId;
            existingEntry.amt = parseFloat((existingEntry.amt + payableAmt).toFixed(2));
            batch.update(paymentDocRef, existingEntry);
        } else {
            batch.set(paymentDocRef, paymentData);
        }

        await this.updatePendingPaymentAdmin(batch, psyName, paymentData.trxId, bookingData);
    };


    updatePendingPaymentAdmin = async (batch, psyName, trxId, bookingData) => {
        const docRef = db.collection('pendingPaymentAdmin').doc('pendingPaymentDoc');
        const pendingPaymentRef = docRef.collection('pendingPayment');
        const currentDate = new Date();
        const payableAmt = parseFloat(bookingData.amt.toFixed(2));
        const paymentData = {
            amt: payableAmt,
            createdOn: Timestamp.fromDate(currentDate),
            paymentStatus: 0,
            utr: '',
            trxId: trxId,
            psyId: bookingData.psyId,
            psyName: psyName,
        };

        const pendingPaymentDocRef = pendingPaymentRef.doc(trxId);
        const pendingPaymentDoc = await pendingPaymentDocRef.get();

        if (pendingPaymentDoc.exists) {
            const existingEntry = pendingPaymentDoc.data();
            existingEntry.amt = parseFloat((existingEntry.amt + payableAmt).toFixed(2));
            batch.update(pendingPaymentDocRef, existingEntry);
        } else {
            batch.set(pendingPaymentDocRef, paymentData);
        }
    };

    updateTherapistDashboardInfo = async (batch, bookingData) => {
        const docRef = db.collection('therapistDashboardInfo').doc(bookingData.psyId);
        const currentDate = new Date();
        const offset = 330;
        const istDate = new Date(currentDate.getTime() + offset * 60 * 1000);
        const payableAmt = parseFloat(bookingData.amt.toFixed(2));
        const dateKey = istDate.toISOString().split('T')[0];

        const doc = await docRef.get();
        const updates = {
            uniquePatients: firebase.firestore.FieldValue.arrayUnion(bookingData.userId),
        };

        if (doc.exists) {
            const data = doc.data();
            const tenDaysPayments = data.tenDaysPayments || [];
            const paymentEntryIndex = tenDaysPayments.findIndex(payment => payment.date === dateKey);

            if (paymentEntryIndex !== -1) {
                tenDaysPayments[paymentEntryIndex].amt = parseFloat((tenDaysPayments[paymentEntryIndex].amt + payableAmt).toFixed(2));
            } else {
                tenDaysPayments.push({
                    amt: payableAmt,
                    date: dateKey,
                });
            }

            const fieldToUpdate = bookingData.callType === 'Live Call' ? 'totalLiveCalls' : 'totalBookingCalls';
            const currentCount = data[fieldToUpdate] || 0;
            updates[fieldToUpdate] = currentCount + 1;

            batch.update(docRef, updates);
            batch.update(docRef, { tenDaysPayments });
        } else {
            const fieldToUpdate = bookingData.callType === 'Live Call' ? 'totalLiveCalls' : 'totalBookingCalls';
            updates[fieldToUpdate] = 1;

            batch.set(docRef, {
                ...updates,
                tenDaysPayments: [{
                    amt: payableAmt,
                    date: dateKey,
                }],
            });
        }
    };

    // getSettlementHistory = async (psyId) => {
    //     try {
    //         const doc = await db.collection('paymentHistoryTherapist').doc(psyId).get();
    //         if (doc.exists) {
    //             const settlementHistory = [];
    //             const payments = doc.data().paymentHistory;

    //             for (const payment of Object.values(payments)) {
    //                 const paymentMap = Payment.fromMap(payment);
    //                 settlementHistory.push(paymentMap);
    //             }

    //             return settlementHistory;
    //         } else {
    //             return [];
    //         }
    //     } catch (error) {
    //         console.error("Error fetching bookings: ", error);
    //         return [];
    //     }
    // }

    // getSettlementHistory = async (psyId, limit = 10, lastOrder = null) => {
    //     try {
    //         let query = firebase.firestore()
    //             .collection('paymentHistoryTherapist')
    //             .doc(psyId)
    //             .collection('paymentHistory')
    //             .orderBy(firebase.firestore.FieldPath.documentId(), 'desc')
    //             .limit(limit);

    //         if (lastOrder) {
    //             query = query.startAfter(lastOrder);
    //         }

    //         const orderSnapshot = await query.get();
    //         const payments = [];

    //         orderSnapshot.forEach(orderDoc => {
    //             const paymentData = orderDoc.data();
    //             const payment = Payment.fromMap(paymentData, orderDoc);
    //             payments.push(payment);
    //         });

    //         return payments;
    //     } catch (e) {
    //         console.error("Error fetching payment details. Please try again later!!", e);
    //         alert("Error fetching payment details. Please try again later!!");
    //         return [];
    //     }
    // }

    getSettlementHistory = async (psyId, limit = 10, lastOrder = null) => {
        try {
            let query = firebase.firestore()
                .collection('paymentHistoryTherapist')
                .doc(psyId)
                .collection('paymentHistory')
                .orderBy(firebase.firestore.FieldPath.documentId(), 'desc')
                .limit(limit);

            if (lastOrder) {
                query = query.startAfter(lastOrder);
            }

            const orderSnapshot = await query.get();
            const payments = [];
            let lastDocument = null;

            orderSnapshot.forEach((orderDoc, index) => {
                const paymentData = orderDoc.data();
                const payment = Payment.fromMap(paymentData, orderDoc);
                payments.push(payment);

                if (index === orderSnapshot.size - 1) {
                    lastDocument = orderDoc;
                }
            });

            return { payments, lastDocument };
        } catch (e) {
            console.error("Error fetching payment details. Please try again later!!", e);
            alert("Error fetching payment details. Please try again later!!");
            return { payments: [], lastDocument: null };
        }
    }


    addTherapistDeviceToken = async (psyId, newDeviceToken) => {
        try {
            const docRef = db.collection('therapistDashboardInfo').doc(psyId);
            const doc = await docRef.get();

            if (doc.exists) {
                const currentData = doc.data();
                const currentDeviceTokens = currentData.deviceToken || {};

                const updatedDeviceTokens = {
                    ...currentDeviceTokens,
                    [newDeviceToken]: true
                };

                await docRef.update({
                    deviceToken: updatedDeviceTokens
                });
            } else {
                await docRef.set({
                    deviceToken: {
                        [newDeviceToken]: true
                    }
                });
            }
        } catch (error) {
            console.error("Error updating therapist dashboard info: ", error);
        }
    }

    getTherapistDashboardInfo = async (psyId) => {
        try {
            const doc = await db.collection('therapistDashboardInfo').doc(psyId).get();
            if (doc.exists) {
                const dashboardInfo = doc.data();
                return dashboardInfo;
            } else {
                return [];
            }
        } catch (error) {
            console.error("Error fetching data: ", error);
            return [];
        }
    }

    fetchCallsForTherapist = async (psychologistId) => {
        try {
            const therapistRef = db.collection('therapist2').doc(psychologistId);
            const therapistDoc = await therapistRef.get();

            if (therapistDoc.exists) {
                const callsData = therapistDoc.data().calls;
                return callsData;
            } else {
                return null;
            }
        } catch (error) {
            console.error('Error fetching calls:', error);
            throw error;
        }
    };

    updateCallStatus = async (id, callType, status) => {
        try {
            const psychologistRef = db.collection('therapists2').doc(id);
            await psychologistRef.update({
                [`calls.${callType}`]: status
            });
        } catch (error) {
            console.error('Error fetching calls:', error);
            throw error;
        }
    };

    updateCallRequest = async ({
        id,
        callType,
        status,
        uid,
        userName,
        anonymous,
        isVideoCall
    }) => {
        if (!id) return;

        const callRequestMap = {};
        if (callType) callRequestMap['callType'] = callType;
        if (status) callRequestMap['status'] = status;
        if (uid) callRequestMap['uid'] = uid;
        if (userName) callRequestMap['userName'] = userName;
        if (anonymous !== undefined) callRequestMap['anonymous'] = anonymous;
        if (isVideoCall !== undefined) callRequestMap['isVideoCall'] = isVideoCall;

        if (Object.keys(callRequestMap).length > 0) {
            try {
                await db.collection('callRequest').doc(id).set(callRequestMap, { merge: true });
            } catch (error) {
                console.error('Error updating Call Requests:', error);
            }
        }
    };

    // getPsychologistTimeSlots = async (doctorId) => {
    //     try {
    //         const psychologistDoc = await db.collection('therapists').doc(doctorId).get();

    //         if (psychologistDoc.exists) {
    //             const data = psychologistDoc.data();

    //             if (data && data.timeSlotsMap) {
    //                 const timeSlotsMap = data.timeSlotsMap;
    //                 const timeSlots = [];

    //                 Object.keys(timeSlotsMap).forEach(date => {
    //                     const slots = timeSlotsMap[date];

    //                     if (Array.isArray(slots)) {
    //                         slots.forEach(slot => {
    //                             if (typeof slot === 'object' && slot.time && slot.status) {
    //                                 timeSlots.push({
    //                                     time: `${date} ${slot.time}`,
    //                                     status: slot.status,
    //                                 });
    //                             }
    //                         });
    //                     }
    //                 });

    //                 return timeSlots;
    //             }
    //         }
    //         return [];
    //     } catch (e) {
    //         console.error("Error getting psychologist time slots:", e);
    //         return [];
    //     }
    // };

    getPsychologistTimeSlotsForDate = async (doctorId, date) => {
        try {
            const psychologistDoc = await db.collection('therapists').doc(doctorId).get();

            if (psychologistDoc.exists) {
                const data = psychologistDoc.data();

                if (data && data.timeSlotsMap) {
                    const timeSlotsMap = data.timeSlotsMap;
                    const timeSlotsForDate = timeSlotsMap[date];

                    if (Array.isArray(timeSlotsForDate)) {
                        return timeSlotsForDate.map(slot => ({
                            time: slot.time || '',
                            status: slot.status || '',
                        }));
                    }
                }
            }
            return null;
        } catch (e) {
            console.error("Error getting psychologist time slots:", e);
            return null;
        }
    };

    updatePsychologistTimeSlots = async (doctorId, date, timeSlots) => {
        try {
            const docRef = db.collection('therapists').doc(doctorId);

            const formattedTimeSlots = timeSlots.map(slot => ({
                time: slot.time,
                status: slot.status || 'Available',
            }));

            await docRef.update({
                [`timeSlotsMap.${date}`]: formattedTimeSlots
            });

        } catch (e) {
            console.error("Error saving or updating time slots:", e);
        }
    };


    removePsychologistTimeSlots = async (doctorId, date, timeSlots) => {
        try {
            const docRef = db.collection('therapists').doc(doctorId);

            const formattedTimeSlots = timeSlots.map(slot => ({
                time: slot,
                status: 'Available',
            }));

            await docRef.update({
                [`timeSlotsMap.${date}`]: formattedTimeSlots
            });

        } catch (e) {
            console.error("Error saving or updating time slots:", e);
        }
    };

    fetchCreditHistory = async (userId) => {
        try {
            const userRef = doc(db, 'users', userId);
            const userDoc = await getDoc(userRef);

            if (userDoc.exists()) {
                const userData = userDoc.data();
                return userData.creditHistory || [];
            } else {
                console.log('No such document!');
                return [];
            }
        } catch (error) {
            console.error('Error fetching user credit history:', error);
            return [];
        }
    };

    updateCreditHistory = async (userId, credit, isCredited) => {
        try {
            const userRef = db.collection('users').doc(userId);

            const newCreditHistory = {
                credit: credit,
                isCredited: isCredited,
                timestamp: Timestamp.now(),
            };

            await userRef.set({
                creditHistory: arrayUnion(newCreditHistory)
            }, { merge: true });

            if (isCredited === 1) {
                await userRef.update({
                    creditEarned: increment(credit),
                });
            } else {
                await userRef.update({
                    creditRedeemed: increment(credit),
                });
            }

        } catch (error) {
            console.error('Error updating user credit history:', error);
        }
    }
}

export default DBHandler;
