const AuthReducer = (state, action) => {
    switch (action.type) {
        case "SET_USER_TYPE":
            return {
                ...state,
                currentUser: action.payload.currentUser,
                userType: action.payload.userType,
            };
        case "LOGOUT":
            sessionStorage.removeItem("user");
            return {
                currentUser: null,
                userType: null,
            };
        default:
            return state;
    }
};

export default AuthReducer;