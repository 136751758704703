import "../HomeTherapistsCards/HomeTherapistCards.css";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { FaStar, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import DBHandler from "../../Utils/DBHandler";
import Loader from '../Loader/Loader';

const PeerCard = React.memo(({ peer, handleBtnClick, cardHeight }) => (
  <div className="therapist-card" style={{ height: cardHeight }}>
    <div className="user_card_flex">
      <img
        src={peer.imagePath}
        alt={peer.name}
        className="therapist-image"
      />
      <div className="user_details_cont">
        <h2 className="therapist-name">{peer.name}</h2>
        <p className="therapist-username">{peer.areas.join(', ')}</p>
        <p className="therapist-details">{peer.languages.join(', ')}</p>
      </div>
    </div>
    <p className="therapist-bio">{peer.about}</p>
    <div className="therapist-stats">
      <div>
        <strong>{peer.rating}</strong>
        <FaStar color="gold" />
      </div>
      <div>
        <strong className="therap_price">₹{peer.price}</strong>
        <span>/hr</span>
      </div>
    </div>
    <button className="view-profile-btn" onClick={() => handleBtnClick(peer)}>View profile</button>
  </div>
));

const PeerCardRow = ({ peers }) => {
  const [startIndex, setStartIndex] = useState(0);
  const [cardHeight, setCardHeight] = useState('auto');
  const navigate = useNavigate();

  const handleBtnClick = (psychologist) => {
    navigate(`/user/peer/${psychologist.id}`, { state: { psychologist } });
  };

  useEffect(() => {
    const updateCardHeight = () => {
      const cardElements = document.querySelectorAll(".therapist-card");
      if (cardElements.length) {
        const heights = Array.from(cardElements).map(card => card.scrollHeight);
        setCardHeight(`${Math.max(...heights)}px`);
      }
    };

    updateCardHeight();
    window.addEventListener('resize', updateCardHeight);
    return () => window.removeEventListener('resize', updateCardHeight);
  }, [peers]);

  const displayedPeers = useMemo(() => {
    const endIndex = startIndex + 3;
    if (endIndex <= peers.length) {
      return peers.slice(startIndex, endIndex);
    } else {
      const wrappedPeers = [...peers.slice(startIndex), ...peers.slice(0, endIndex - peers.length)];
      return wrappedPeers;
    }
  }, [startIndex, peers]);

  const handlePrev = useCallback(() => {
    setStartIndex((prevIndex) => (prevIndex - 1 + peers.length) % peers.length);
  }, [peers.length]);

  const handleNext = useCallback(() => {
    setStartIndex((prevIndex) => (prevIndex + 1) % peers.length);
  }, [peers.length]);

  return (
    <div className="therapist-card-container">
      <button className="nav-btn prev" onClick={handlePrev}>
        <FaChevronLeft />
      </button>
      <div className="therapist-card-row">
        {displayedPeers.map((peer) => (
          <PeerCard
            key={peer.id}
            peer={peer}
            handleBtnClick={handleBtnClick}
            cardHeight={cardHeight}
          />
        ))}
      </div>
      <button className="nav-btn next" onClick={handleNext}>
        <FaChevronRight />
      </button>
    </div>
  );
};

const HomePeersCards = () => {
  const [peerData, setPeerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const handleViewBtnClick = () => {
    navigate('/user/peer');
  }

  useEffect(() => {
    const fetchPeers = async () => {
      setLoading(true);
      const dbHandler = new DBHandler();
      const data = await dbHandler.fetchPsychologistList(false, 5);
      setPeerData(data);
      setLoading(false);
    };

    fetchPeers();
  }, []);

  return (
    <div className="home-peers-cards">
      <h1 className="therap_profile_heading">Our Peer Support</h1>
      {loading ? (
        <Loader />
      ) : (
        <PeerCardRow peers={peerData} />
      )}
      <div className="view_all_cont">
        <button className="view-all-btn" onClick={handleViewBtnClick}>
          View More
          <div className="arrow-wrapper">
            <div className="arrow"></div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default HomePeersCards;
