import React, { useContext } from 'react';
import Sidebar from '../../../Components/sidebar/Sidebar';
import Navbar from '../../../Components/navbar/navbar';
import SettlementHistory from './SettlementHistory';
import '../Home/home.scss';
import { AuthContext } from "../../../Context/AuthContext";

export default function Settlement() {
    const { currentUser } = useContext(AuthContext);
    return (
        <div className='therapy_home'>
            <Sidebar userType={'therapist'} />
            <div className='homeContainer'>
                <Navbar userData={currentUser} userType={'therapist'} />
                <SettlementHistory />
            </div>
        </div>
    )
}
