import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleButton } from 'react-google-button';
import { signInWithPopup, OAuthProvider } from "firebase/auth";
import { auth, provider, appleProvider, requestPermission } from '../../Config/Config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logoImage from '../../Assets/hapynobg.png';
import sideImage from '../../Assets/background.avif';
import HomeNavbar from '../../Components/Navbar';
import { Box, TextField, InputAdornment } from '@mui/material';
import { useUser } from '../../Utils/User/UserContext';
import { usePsychologist } from '../../Utils/Psychologist/PsychologistContext';
import DBHandler from '../../Utils/DBHandler';
import { AuthContext } from '../../Context/AuthContext';
import './style.css';

export default function Login() {
    const navigate = useNavigate();
    const scrollRef = useRef();
    const dbHandler = new DBHandler();

    const { user, modifyUser } = useUser();
    const { psychologist, modifyPsychologist } = usePsychologist();
    const { dispatch, setUserType, userType } = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
    const [isLoadingApple, setIsLoadingApple] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const getUserType = (uid) => {
        if (uid.startsWith('thp_')) return 'therapist';
        if (uid.startsWith('usr_')) return 'generalUser';
        return null;
    };


    const navigateAfterLogin = async (userTypes, userData, setLoader) => {
        setUserType(userTypes === 'therapist' ? 'userPsychologist' : 'generalUser');
        dispatch({
            type: 'SET_USER_TYPE',
            payload: {
                currentUser: userData,
                userType: userType
            }
        });

        if (userTypes === 'therapist') {
            const showIntroDetailPage = await dbHandler.doesPsychologistDetailsExist(userData);
            showIntroDetailPage ? setTimeout(() => { navigate('/therapists/dashboard'); }, 3000) : setTimeout(() => { navigate('/therapists/intro'); }, 3000);
            //TODO: Implement the following function to save the FCM token and dashboard data in the therapistDashboardInfo collection
            requestPermission(userData.id);
        } else {
            setTimeout(() => { navigate('/user/dashboard'); }, 3000);
        }
        toast.success('Login Successful. You will now automatically get redirected to the Home page.', { containerId: 'login' });
        setLoader(false);
        scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleGoogleClick = async () => {
        try {
            setIsLoadingGoogle(true);
            const data = await signInWithPopup(auth, provider);
            const userAuth = data.user;
            let customUID = await dbHandler.getCustomUID(userAuth.uid);
            if (!customUID) {
                await userAuth.delete();
                await auth.signOut();
                setIsLoadingGoogle(false);
                toast.error('User not registered. Please register before logging in.', { containerId: 'login' });
                setTimeout(() => { navigate('/register'); }, 3000);
                return;
            }
            const userType = await getUserType(customUID);

            if (userType) {
                const userData = userType === 'therapist'
                    ? await dbHandler.getPsychologist(customUID)
                    : await dbHandler.getUser(customUID);

                const modifyFn = userType === 'therapist' ? modifyPsychologist : modifyUser;
                await modifyFn(userData);

                await navigateAfterLogin(userType, userData, setIsLoadingGoogle);
            } else {
                await auth.signOut();
                setIsLoadingGoogle(false);
                toast.error('User not registered. Please register before logging in.', { containerId: 'login' });
                setTimeout(() => { navigate('/register'); }, 3000);
            }
        } catch (error) {
            toast.error(error.message, { containerId: 'login' });
        } finally {
            setIsLoadingGoogle(false);
        }
    };

    // TODO: Implement the following Apple SignIn functions
    const handleAppleClick = async () => {
        try {
            setIsLoadingApple(true);
            const data = await signInWithPopup(auth, appleProvider);
            const userAuth = data.user;
            const credential = OAuthProvider.credentialFromResult(data);
            const accessToken = credential.accessToken;
            const idToken = credential.idToken;
            console.log(accessToken, idToken);
            let customUID = await dbHandler.getCustomUID(userAuth.uid);
            if (!customUID) {
                await auth.signOut();
                setIsLoadingApple(false);
                toast.error('User not registered. Please register before logging in.', { containerId: 'login' });
                setTimeout(() => { navigate('/register'); }, 3000);
            }
            const userType = await getUserType(customUID);

            if (userType) {
                const userData = userType === 'therapist'
                    ? await dbHandler.getPsychologist(customUID)
                    : await dbHandler.getUser(customUID);

                const modifyFn = userType === 'therapist' ? modifyPsychologist : modifyUser;
                await modifyFn(userData);

                await navigateAfterLogin(userAuth, userType, userData, setIsLoadingApple);
            } else {
                await auth.signOut(); // Sign out the user immediately
                setIsLoadingApple(false);
                toast.error('User not registered. Please register before logging in.', { containerId: 'login' });
                setTimeout(() => { navigate('/register'); }, 3000);// Redirect to registration page
            }
        } catch (error) {
            setIsLoadingApple(false);
            toast.error(error.message, { containerId: 'userLogin' });
        }
    };

    const handleLoginClick = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const userCredential = await auth.signInWithEmailAndPassword(email, password);
            const userAuth = userCredential.user;

            if (userAuth.emailVerified) {
                let customUID = await dbHandler.getCustomUID(userAuth.uid);
                if (!customUID) {
                    await auth.signOut();
                    setIsLoading(false);
                    toast.error('User not registered. Please register before logging in.', { containerId: 'login' });
                    setTimeout(() => { navigate('/register'); }, 3000);
                }
                const userType = await getUserType(customUID);

                if (userType) {
                    const userData = userType === 'therapist'
                        ? await dbHandler.getPsychologist(customUID)
                        : await dbHandler.getUser(customUID);

                    const modifyFn = userType === 'therapist' ? modifyPsychologist : modifyUser;
                    await modifyFn(userData);
                    await navigateAfterLogin(userType, userData, setIsLoading);
                    setEmail('');
                    setPassword('');
                } else {
                    await auth.signOut(); // Sign out the user immediately
                    setEmail('');
                    setPassword('');
                    setIsLoading(false);
                    toast.error('User not registered. Please register before logging in.', { containerId: 'login' });
                    setTimeout(() => { navigate('/register'); }, 3000);// Redirect to registration page
                }
            } else {
                toast.error('Please verify your email address before logging in.', { containerId: 'userLogin' });
            }
        } catch (error) {
            toast.error(error.message, { containerId: 'userLogin' });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
    }, [psychologist, user]);

    return (
        <>
            <div ref={scrollRef}></div>
            <HomeNavbar />
            <ToastContainer
                containerId="login"
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light" />
            <section className="login-back p-3 p-md-4 p-xl-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xxl-11">
                            <div className="card border-light-subtle shadow-sm">
                                <div className="row g-0">
                                    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
                                        <img className="img-fluid rounded-start w-100 h-95 object-fit-cover" loading="lazy" src={sideImage} alt="Welcome back you've been missed!" />
                                    </div>
                                    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
                                        <div className="col-12 col-lg-11 col-xl-10">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mb-4">
                                                            <div className="text-center mb-4">
                                                                <Link to="/" aria-current="page" className='logo-link'>
                                                                    <img src={logoImage} alt="BootstrapBrain Logo" width="175" height="100" />
                                                                </Link>
                                                            </div>
                                                            <h4 className="text-center">Welcome back you've been missed!</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="other-auth row mt-2">
                                                    <div className="col-12 d-flex flex-column align-items-center">
                                                        <div style={{ width: '300px' }}>
                                                            <div className="other-auth-btn d-flex justify-content-center gap-3">
                                                                <GoogleButton
                                                                    style={{ width: '100%' }}
                                                                    onClick={handleGoogleClick}
                                                                    label={
                                                                        isLoadingGoogle ? (
                                                                            <span>
                                                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                            </span>
                                                                        ) : (
                                                                            'Continue with Google'
                                                                        )
                                                                    }
                                                                    disabled={isLoadingGoogle}
                                                                />
                                                            </div>
                                                            <div className="d-flex justify-content-center mt-3" onClick={handleAppleClick}>
                                                                {isLoadingApple ? (
                                                                    <button className="other-auth-btn btn btn-lg btn-dark w-100" type="button" disabled>
                                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                    </button>
                                                                ) : (
                                                                    <div className="other-auth-btn btn btn-lg btn-dark w-100 d-flex justify-content-center gap-3">
                                                                        <i className="bi bi-apple me-auto"></i>
                                                                        <span className="mx-auto apple-text">Continue with Apple</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {/* <Link
                                                            to="/login-professional"
                                                            aria-current="page"
                                                            className="therapist-reg-text"
                                                        >
                                                            <h6 className="text-center mt-4 mb-3">Are you a Psychologist or Peer?</h6>
                                                        </Link> */}
                                                        <p className="text-center mt-4 mb-4">Or sign in with</p>
                                                    </div>
                                                </div>
                                                <form action="#!">
                                                    <Box className="row gy-3 overflow-hidden">
                                                        <Box className="col-12">
                                                            <TextField
                                                                variant="outlined"
                                                                fullWidth
                                                                label="Email"
                                                                type="email"
                                                                name="email"
                                                                id="email"
                                                                placeholder="Email"
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                value={email}
                                                                autoComplete="username"
                                                                required
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <i className="bi bi-envelope-fill"></i>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                margin="normal"
                                                            />
                                                        </Box>
                                                        <Box className="col-12">
                                                            <TextField
                                                                variant="outlined"
                                                                fullWidth
                                                                label="Password"
                                                                type="password"
                                                                name="password"
                                                                id="password"
                                                                placeholder="Password"
                                                                onChange={(e) => setPassword(e.target.value)}
                                                                value={password}
                                                                required
                                                                autoComplete="current-password"
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <i className="bi bi-lock-fill"></i>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                margin="normal"
                                                            />
                                                        </Box>
                                                        <div className="form-btn col-12">
                                                            <div className="d-grid">
                                                                {isLoading ? (
                                                                    <button className="btn btn-dark btn-lg" type="button" disabled>
                                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                    </button>
                                                                ) : (
                                                                    <button className="btn btn-dark btn-lg" type="button" onClick={handleLoginClick}>
                                                                        Sign in now
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Box>
                                                </form>
                                                <div className="addn-info row">
                                                    <div className="col-12">
                                                        <div className="d-flex gap-2 gap-md-4 flex-column flex-md-row justify-content-md-center mt-5">
                                                            <Link
                                                                to="/register"
                                                                aria-current="page"
                                                                className="addn-info-text"
                                                            > Create new account  </Link>
                                                            <Link
                                                                to="/"
                                                                aria-current="page"
                                                                className="addn-info-text"
                                                            > Forgot password</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    );
}
