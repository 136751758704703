import React, { useState } from "react";
import {
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Badge,
    IconButton,
    Grid,
    FormHelperText,
    Avatar,
} from "@mui/material";
import Loader from "../../../Components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { auth, storage } from "../../../Config/Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DBHandler from "../../../Utils/DBHandler";
import { useUser } from "../../../Utils/User/UserContext";
import { serverTimestamp } from "firebase/firestore";
import { Link } from "react-router-dom";
import './Profile.css'
import Sidebar from '../../../Components/sidebar/Sidebar';
import Navbar from '../../../Components/navbar/navbar';
import '../Home/home.css';

const UserProfile = () => {

    const { user, modifyUser } = useUser();
    const dbHandler = new DBHandler();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    //General Info
    const [fullName, setFullName] = useState(user.name || "");
    const [profileImage, setProfileImage] = useState("");
    const [contactNumber, setContactNumber] = useState(user.phone || "");
    const [countryCode, setCountryCode] = useState(
        user.countryCode || "91"
    );
    const [gender, setGender] = useState(user.gender || '');
    const [maritalStatus, setMaritalStatus] = useState(user.maritalStatus || '');

    //Medical Info
    const [medicalInfo, setMedicalInfo] = useState({
        allergies: user.allergies || "",
        currentMedication: user.currentMedication || "",
        pastMedication: user.pastMedication || "",
        chronicDiseases: user.chronicDiseases || "",
        injuries: user.injuries || "",
        surgeries: user.surgeries || "",
    });

    //Lifestyle Info
    const [lifestyleInfo, setLifestyleInfo] = useState({
        smokingHabits: user.smokingHabits || "",
        alcoholConsumption: user.alcoholConsumption || "",
        activityLevel: user.activityLevel || "",
        occupation: user.occupation || "",
    });

    const medicalFields = [
        { label: "Allergies", name: "allergies" },
        { label: "Current Medication", name: "currentMedication" },
        { label: "Past Medication", name: "pastMedication" },
        { label: "Chronic Diseases", name: "chronicDiseases" },
        { label: "Injuries", name: "injuries" },
        { label: "Surgeries", name: "surgeries" },
    ];

    const lifestyleFields = [
        { label: "Smoking Habit", name: "smokingHabits", options: ["Never", "Formerly", 'Occasional', 'Regular', 'Heavy'] },
        { label: "Alcohol Consumption", name: "alcoholConsumption", options: ["Never", "Formerly", 'Occasional', 'Regular', 'Heavy'] },
        { label: "Activity Level", name: "activityLevel", options: ['Sedentary', 'Low', 'Moderate', 'Active'] },
        { label: "Occupation", name: "occupation", options: ['Student', 'Professional', 'Homemaker', 'Retired', 'Others'] },
    ];

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setMedicalInfo((prevInfo) => ({
            ...prevInfo,
            [name]: value,
        }));
    };

    const handleLifestyleChange = (event) => {
        const { name, value } = event.target;
        setLifestyleInfo((prevInfo) => ({
            ...prevInfo,
            [name]: value,
        }));
    };

    const handleDropdownChange = (event, setField) => {
        const { value } = event.target;
        setField(value);
    };


    const handleImageChange = (event) => {
        const selectedImage = event.target.files[0];
        if (selectedImage) {
            if (selectedImage.size > 1024 * 1024 * 5) {
                toast.error("Image size should be less than 5MB", { containerId: 'userProfile' });
            } else {
                const reader = new FileReader();
                reader.onload = () => {
                    setProfileImage(reader.result);
                };
                reader.readAsDataURL(selectedImage);
            }
        } else {
            toast.error("Please add a valid image!!", { containerId: 'userProfile' });
        }
    };

    const uploadImage = async (file, path) => {
        const ref = storage.ref(path);
        await ref.put(file);
        return ref.getDownloadURL();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const currentUser = auth.currentUser;
            if (currentUser) {
                const profileFile = document.getElementById("profile-image-upload")
                    .files[0];

                let profileImagePath = "";

                if (profileFile) {
                    const profileExtension = profileFile.name.split(".").pop();
                    profileImagePath = await uploadImage(
                        profileFile,
                        `user/profile/${user.id}.${profileExtension}`
                    );
                    // await uploadImage(
                    //     profileFile,
                    //     `profile/${currentUser.uid}.${profileExtension}`
                    // );
                }
                const userData = {
                    id: user.id,
                    uid: currentUser.uid,
                    name: fullName,
                    phone: contactNumber,
                    countryCode: countryCode,
                    imagePath: profileImagePath,
                    gender: gender,
                    maritalStatus: maritalStatus,
                    allergies: medicalInfo.allergies,
                    currentMedication: medicalInfo.currentMedication,
                    pastMedication: medicalInfo.pastMedication,
                    chronicDiseases: medicalInfo.chronicDiseases,
                    injuries: medicalInfo.injuries,
                    surgeries: medicalInfo.surgeries,
                    smokingHabits: lifestyleInfo.smokingHabits,
                    alcoholConsumption: lifestyleInfo.alcoholConsumption,
                    activityLevel: lifestyleInfo.activityLevel,
                    occupation: lifestyleInfo.occupation,
                    lastOnline: serverTimestamp(),
                };
                await dbHandler.updateUser(userData);
                await modifyUser(userData);
                toast.success("Profile updated successfully", { containerId: 'userProfile' });
                setTimeout(() => {
                    navigate("/user/dashboard");
                }, 3000);
            } else {
                toast.error("Error updating details. Please try again later!!", { containerId: 'userProfile' });
            }
        } catch (error) {
            toast.error(error.message, { containerId: 'userProfile' });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <link
                href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700"
                rel="stylesheet"
            />
            <ToastContainer
                containerId="userProfile"
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {isLoading && <Loader />}
            <div className='user_home'>
                <Sidebar userType={'user'} />
                <div className='homeContainer'>
                    <Navbar userData={user} userType={'user'} />
                    <div className="profile-content">
                        <div
                            className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
                            style={{
                                minHeight: 600,
                                backgroundImage:
                                    "url(https://raw.githubusercontent.com/creativetimofficial/argon-dashboard/gh-pages/assets-old/img/theme/profile-cover.jpg)",
                                backgroundSize: "cover",
                                backgroundPosition: "center top"
                            }}
                        >
                            <span className="mask bg-gradient-default opacity-8" />
                            <div className="container-fluid d-flex align-items-center">
                                <div className="row">
                                    <div className="col-lg-7 col-md-10">
                                        <h1 className="display-2 text-white">Hello {user.name.split(" ")[0]}!</h1>
                                        <p className="text-white mt-0 mb-5">
                                            Welcome to your profile page. Here, you can monitor the progress you've made with your work and make any necessary updates to your profile
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid mt--7">
                            <div className="row">
                                <div className="col-xl-4 order-xl-2 mb-5 mb-xl-0">
                                    <div className="card card-profile shadow">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-3 order-lg-2">
                                                <div className="card-profile-image">
                                                    <Link>
                                                        {user.imagePath === "" ? (
                                                            <img
                                                                src={user.imagePath}
                                                                className="rounded-circle initalAvatar"
                                                                alt={user?.name[0]?.toUpperCase()}
                                                            />
                                                        ) : (
                                                            <img
                                                                src={user.imagePath}
                                                                className="rounded-circle"
                                                                alt={user.name[0].toUpperCase()}
                                                            />
                                                        )}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></div>
                                        <div className="card-body pt-0 pt-md-4">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                                                        <div>
                                                            <span className="heading">₹{user.creditEarned}</span>
                                                            <span className="description">Credits Earned</span>
                                                        </div>
                                                        <div>
                                                            <span className="heading">₹{user.creditRedeemed}</span>
                                                            <span className="description">Credits Redeemed</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <h3>
                                                    {user.name}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-8 order-xl-1">
                                    <div className="card bg-secondary shadow">
                                        <div className="card-header bg-white border-0">
                                            <div className="row align-items-center">
                                                <div className="col-8">
                                                    <h3 className="mb-0">My account</h3>
                                                </div>
                                                <div className="col-4 text-right">
                                                    <Link to='/user/dashboard' className="btn btn-sm btn-primary">
                                                        Settings
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <form onSubmit={handleSubmit}>
                                                <h6 className="heading-small text-muted mb-0">
                                                    User information
                                                </h6>
                                                <div className="pl-lg-4">
                                                    <div className="row">
                                                        <Grid container justifyContent="center">
                                                            <Grid item xs={12}>
                                                                <Box
                                                                    display="flex"
                                                                    alignItems="center"
                                                                    flexDirection="column"
                                                                    justifyContent="center"
                                                                >
                                                                    <Badge
                                                                        overlap="circular"
                                                                        anchorOrigin={{
                                                                            vertical: "bottom",
                                                                            horizontal: "right",
                                                                        }}
                                                                        badgeContent={
                                                                            <label htmlFor="profile-image-upload">
                                                                                <IconButton
                                                                                    sx={{ color: "#00296b" }}
                                                                                    aria-label="upload picture"
                                                                                    component="span"
                                                                                >
                                                                                    <i className="bi bi-camera-fill"></i>
                                                                                </IconButton>
                                                                                <input
                                                                                    accept="image/*"
                                                                                    style={{ display: "none" }}
                                                                                    id="profile-image-upload"
                                                                                    type="file"
                                                                                    onChange={handleImageChange}
                                                                                />
                                                                            </label>
                                                                        }
                                                                    >
                                                                        <Avatar
                                                                            alt="Profile Image"
                                                                            src={profileImage === "" ? user.imagePath : profileImage}
                                                                            sx={{ width: 140, height: 150, objectFit: 'cover' }}
                                                                        />
                                                                    </Badge>
                                                                    <FormHelperText style={{ fontSize: "1rem", margin: '1rem' }}>
                                                                        <b>Upload your profile image</b>
                                                                    </FormHelperText>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="form-group focused">
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="name"
                                                                >
                                                                    Full Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    id="name"
                                                                    className="form-control form-control-alternative"
                                                                    placeholder="Full Name"
                                                                    required
                                                                    value={fullName}
                                                                    onChange={(e) => setFullName(e.target.value)}
                                                                />
                                                                {!fullName && (
                                                                    <FormHelperText style={{ color: 'red', fontSize: '0.85rem', margin: 0, padding: 0 }}>
                                                                        Please fill the required field
                                                                    </FormHelperText>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="email"
                                                                >
                                                                    Email address
                                                                </label>
                                                                <input
                                                                    type="email"
                                                                    id="email"
                                                                    className="form-control form-control-alternative"
                                                                    placeholder="abc@example.com"
                                                                    value={user.email}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="row">
                                                                <div className="col-lg-3">
                                                                    <div className="form-group focused">
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="country-code"
                                                                        >
                                                                            Country
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            id="country-code"
                                                                            className="form-control form-control-alternative"
                                                                            placeholder="Country Code"
                                                                            value={countryCode}
                                                                            required
                                                                            onChange={(e) => setCountryCode(e.target.value)}
                                                                        />
                                                                        {!countryCode && (
                                                                            <FormHelperText style={{ color: 'red', fontSize: '0.85rem', margin: 0, padding: 0 }}>
                                                                                Please fill the required field
                                                                            </FormHelperText>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-9">
                                                                    <div className="form-group focused">
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="phonenumber"
                                                                        >
                                                                            Phone Number
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            id="phonenumber"
                                                                            className="form-control form-control-alternative"
                                                                            placeholder="Contact Number"
                                                                            value={contactNumber}
                                                                            required
                                                                            onChange={(e) => setContactNumber(e.target.value)}
                                                                        />
                                                                        {!contactNumber && (
                                                                            <FormHelperText style={{ color: 'red', fontSize: '0.85rem', margin: 0, padding: 0 }}>
                                                                                Please fill the required field
                                                                            </FormHelperText>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="form-group focused">
                                                                <label className="form-control-label mb-2" htmlFor="input-country">
                                                                    Gender
                                                                </label>
                                                                <Box>
                                                                    <FormControl variant="outlined" fullWidth required>
                                                                        <InputLabel id="gender-label">Select Gender</InputLabel>
                                                                        <Select
                                                                            labelId="gender-label"
                                                                            id="gender"
                                                                            name="Gender"
                                                                            required
                                                                            onChange={(event) => handleDropdownChange(event, setGender)}
                                                                            value={gender}
                                                                            label="Select Gender"
                                                                        >
                                                                            <MenuItem disabled value="">
                                                                                <em>Select Gender</em>
                                                                            </MenuItem>
                                                                            <MenuItem value="Male">Male</MenuItem>
                                                                            <MenuItem value="Female">Female</MenuItem>
                                                                            <MenuItem value="Others">Others</MenuItem>
                                                                        </Select>
                                                                        {!gender && (
                                                                            <FormHelperText style={{ color: 'red', fontSize: '0.85rem', margin: 0, padding: 0 }}>
                                                                                Please fill the required field
                                                                            </FormHelperText>
                                                                        )}
                                                                    </FormControl>
                                                                </Box>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="form-group focused">
                                                                <label className="form-control-label mb-2" htmlFor="input-country">
                                                                    Marital Status
                                                                </label>
                                                                <Box>
                                                                    <FormControl variant="outlined" fullWidth required>
                                                                        <InputLabel id="gender-label">Select Marital Status</InputLabel>
                                                                        <Select
                                                                            labelId="gender-label"
                                                                            id="maritalStatus"
                                                                            name="MaritalStatus"
                                                                            required
                                                                            onChange={(event) => handleDropdownChange(event, setMaritalStatus)}
                                                                            value={maritalStatus}
                                                                            label="Select Marital Status"
                                                                        >
                                                                            <MenuItem disabled value="">
                                                                                <em>Select Marital Status</em>
                                                                            </MenuItem>
                                                                            <MenuItem value="Single">Single</MenuItem>
                                                                            <MenuItem value="Married">Married</MenuItem>
                                                                            <MenuItem value="Divorced">Divorced</MenuItem>
                                                                            <MenuItem value="Widowed">Widowed</MenuItem>
                                                                        </Select>
                                                                        {!maritalStatus && (
                                                                            <FormHelperText style={{ color: 'red', fontSize: '0.85rem', margin: 0, padding: 0 }}>
                                                                                Please fill the required field
                                                                            </FormHelperText>
                                                                        )}
                                                                    </FormControl>
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="my-4" />
                                                <h6 className="heading-small text-muted mb-0">
                                                    Medical information
                                                </h6>
                                                <div className="pl-lg-4">
                                                    <div className="row">
                                                        {medicalFields.map((field) => (
                                                            <div className="col-md-12" key={field.name}>
                                                                <div className="form-group focused">
                                                                    <label className="form-control-label" htmlFor={field.name}>
                                                                        {field.label}
                                                                    </label>
                                                                    <input
                                                                        id={field.name}
                                                                        name={field.name}
                                                                        className="form-control form-control-alternative"
                                                                        placeholder={field.label}
                                                                        value={medicalInfo[field.name]}
                                                                        onChange={handleInputChange}
                                                                        type="text"
                                                                        required
                                                                    />
                                                                    <FormHelperText style={{ fontSize: '0.85rem', margin: 0, padding: 0 }}>
                                                                        If yes, please specify them, separated by commas
                                                                    </FormHelperText>
                                                                    {!medicalInfo[field.name] && (
                                                                        <FormHelperText style={{ color: 'red', fontSize: '0.85rem', margin: 0, padding: 0 }}>
                                                                            Please fill the required field
                                                                        </FormHelperText>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <hr className="my-4" />
                                                <h6 className="heading-small text-muted mb-0">
                                                    Lifestyle information
                                                </h6>
                                                <div className="pl-lg-4">
                                                    <div className="row">
                                                        {lifestyleFields.map((field) => (
                                                            <div className="col-lg-4" key={field.name}>
                                                                <div className="form-group focused">
                                                                    <label className="form-control-label mb-2" htmlFor={field.name}>
                                                                        {field.label}
                                                                    </label>
                                                                    <Box style={{ padding: 0, margin: 0 }}>
                                                                        <FormControl variant="outlined" fullWidth required>
                                                                            <InputLabel id={`${field.name}-label`}>Select {field.label}</InputLabel>
                                                                            <Select
                                                                                labelId={`${field.name}-label`}
                                                                                id={field.name}
                                                                                name={field.name}
                                                                                required
                                                                                onChange={handleLifestyleChange}
                                                                                value={lifestyleInfo[field.name]}
                                                                                label={`Select ${field.label}`}
                                                                            >
                                                                                <MenuItem disabled value="">
                                                                                    <em>Select {field.label}</em>
                                                                                </MenuItem>
                                                                                {field.options.map((option) => (
                                                                                    <MenuItem key={option} value={option}>
                                                                                        {option}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                            {!lifestyleInfo[field.name] && (
                                                                                <FormHelperText style={{ color: 'red', fontSize: '0.85rem', margin: 0, padding: 0 }}>
                                                                                    Please fill the required field
                                                                                </FormHelperText>
                                                                            )}
                                                                        </FormControl>
                                                                    </Box>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="text-center mt-5 mb-5">
                                                    <button type="submit" className="btn btn-default rounded-pill" disabled={isLoading}>
                                                        Save Changes
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>

    )
}

export default UserProfile
