import React, { useState, useEffect, useCallback, useMemo } from "react";
import { FaStar, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import DBHandler from "../../Utils/DBHandler";
import Loader from '../Loader/Loader';
import "./HomeTherapistCards.css";

const TherapistCard = React.memo(({ therapist, handleBtnClick, cardHeight }) => (
  <div className="therapist-card" style={{ height: cardHeight }}>
    <div className="user_card_flex">
      <img
        src={therapist.imagePath}
        alt={therapist.name}
        className="therapist-image"
      />
      <div className="user_details_cont">
        <h2 className="therapist-name">Dr. {therapist.name}</h2>
        <p className="therapist-username">{therapist.areas.join(', ')}</p>
        <p className="therapist-details">{therapist.languages.join(', ')}</p>
      </div>
    </div>
    <p className="therapist-bio">{therapist.about}</p>
    <div className="therapist-stats">
      <div>
        <strong>{therapist.rating}</strong>
        <FaStar color="gold" />
      </div>
      <div>
        <strong className="therap_price">₹{therapist.price}</strong>
        <span>/hr</span>
      </div>
    </div>
    <button className="view-profile-btn" onClick={() => handleBtnClick(therapist)}>View profile</button>
  </div>
));

const TherapistCardRow = ({ therapists }) => {
  const [startIndex, setStartIndex] = useState(0);
  const [cardHeight, setCardHeight] = useState('auto');
  const navigate = useNavigate();

  const handleBtnClick = (psychologist) => {
    navigate(`/user/therapist/${psychologist.id}`, { state: { psychologist } });
  };

  useEffect(() => {
    const updateCardHeight = () => {
      const cardElements = document.querySelectorAll(".therapist-card");
      if (cardElements.length) {
        const heights = Array.from(cardElements).map(card => card.scrollHeight);
        setCardHeight(`${Math.max(...heights)}px`);
      }
    };

    updateCardHeight();
    window.addEventListener('resize', updateCardHeight);
    return () => window.removeEventListener('resize', updateCardHeight);
  }, [therapists]);

  const displayedTherapists = useMemo(() => {
    const endIndex = startIndex + 3;
    if (endIndex <= therapists.length) {
      return therapists.slice(startIndex, endIndex);
    } else {
      const wrappedTherapists = [...therapists.slice(startIndex), ...therapists.slice(0, endIndex - therapists.length)];
      return wrappedTherapists;
    }
  }, [startIndex, therapists]);

  const handlePrev = useCallback(() => {
    setStartIndex((prevIndex) => (prevIndex - 1 + therapists.length) % therapists.length);
  }, [therapists.length]);

  const handleNext = useCallback(() => {
    setStartIndex((prevIndex) => (prevIndex + 1) % therapists.length);
  }, [therapists.length]);

  return (
    <div className="therapist-card-container">
      <button className="nav-btn prev" onClick={handlePrev}>
        <FaChevronLeft />
      </button>
      <div className="therapist-card-row">
        {displayedTherapists.map((therapist) => (
          <TherapistCard
            key={therapist.id}
            therapist={therapist}
            handleBtnClick={handleBtnClick}
            cardHeight={cardHeight}
          />
        ))}
      </div>
      <button className="nav-btn next" onClick={handleNext}>
        <FaChevronRight />
      </button>
    </div>
  );
};

const HomeTherapistCards = () => {
  const [therapistData, setTherapistData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const handleViewBtnClick = () => {
    navigate('/user/therapist');
  }

  useEffect(() => {
    const fetchTherapists = async () => {
      setLoading(true);
      const dbHandler = new DBHandler();
      const data = await dbHandler.fetchPsychologistList(true, 5);
      setTherapistData(data);
      setLoading(false);
    };

    fetchTherapists();
  }, []);

  return (
    <div className="home-therapist-cards">
      <h1 className="therap_profile_heading">Our Therapists</h1>
      {loading ? (
        <Loader />
      ) : (
        <TherapistCardRow therapists={therapistData} />
      )}
      <div className="view_all_cont">
        <button className="view-all-btn" onClick={handleViewBtnClick}>
          View More
          <div className="arrow-wrapper">
            <div className="arrow"></div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default HomeTherapistCards;