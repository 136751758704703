import React, { useState } from 'react';
import './CardComponent.css';
import VideocamIcon from '@mui/icons-material/Videocam';
import MicIcon from '@mui/icons-material/Mic';
import DBHandler from "../../Utils/DBHandler";
import { Box, Typography, Select, MenuItem, FormControl, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

export const CardComponent = ({ initials, consultationData, onClick, updateBookingHistory, psyName }) => {
    const [status, setStatus] = useState('Scheduled');
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');
    const dbHandler = new DBHandler();

    const statusMap = {
        'Scheduled': 0,
        'Completed': 1,
        'Cancel': -1,
    };

    const handleStatusChange = (event) => {
        event.stopPropagation(); // Prevent the click event from propagating
        setSelectedStatus(event.target.value);
        setOpenDialog(true); // Open the dialog when status is changed
    };

    const handleCloseDialog = async (confirm) => {
        if (confirm) {
            setStatus(selectedStatus); // Update status if confirmed
            await dbHandler.initiateBookingStatus(psyName, consultationData, statusMap[selectedStatus]);
            updateBookingHistory(consultationData.paymentId, statusMap[selectedStatus]);
        }
        setOpenDialog(false); // Close the dialog
    };

    const handleCardClick = (event) => {
        event.stopPropagation(); // Prevent dialog clicks from triggering card click
        onClick();
    };

    const IconComponent = consultationData.sessionMode === 'Video' ? VideocamIcon : MicIcon;

    const getDateTime = () => {
        if (consultationData.callType === 'Live Call') {
            return consultationData.timestamp instanceof Date ? consultationData.timestamp : consultationData.timestamp.toDate();
        } else {
            return consultationData.dateTimeSlot instanceof Date ? consultationData.dateTimeSlot : consultationData.dateTimeSlot.toDate();
        }
    };

    const dateTimeSlot = getDateTime();

    const dateOptions = { day: 'numeric', month: 'short', year: 'numeric', timeZone: 'Asia/Kolkata' };
    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: 'Asia/Kolkata' };

    const dateFormatter = new Intl.DateTimeFormat('en-IN', dateOptions);
    const timeFormatter = new Intl.DateTimeFormat('en-IN', timeOptions);

    const formattedDate = dateFormatter.format(dateTimeSlot);
    const formattedTime = timeFormatter.format(dateTimeSlot);

    const MenuProps = {
        PaperProps: {
            style: {
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
    };

    const canMarkAsCompleted = new Date() >= dateTimeSlot;

    return (
        <Box className="Consultantcard">
            <Box className="circle">
                <Typography className="initials">{initials}</Typography>
            </Box>
            <Box className="card-content">
                <div className='name_bg' onClick={handleCardClick}>
                    <Typography className="Consultantname">{consultationData.userName}</Typography>
                    <Typography className="Consultantphone">{`+91 ${consultationData.userPhone}`}</Typography>
                </div>

                <Box className="details" onClick={handleCardClick}>
                    <IconComponent className="Videoicon" />
                    <Box className="detail-item">
                        <Typography className="detail-text">{consultationData.sessionMode} Consultation</Typography>
                        <Box className="detail-info">
                            <Typography className="detail-info">@ {formattedDate}</Typography>
                            <Typography className="detail-info">{formattedTime}</Typography>
                        </Box>
                    </Box>
                    <Box className="detail-item">
                        <Typography className="detail-text">Amount Paid</Typography>
                        <Typography className="detail-info">{`₹${consultationData.amt}`}</Typography>
                    </Box>
                </Box>

                <hr className="divider" />

                {consultationData.callType === 'Booking' && consultationData.bookingStatus === 0 && (
                    <Box className="status-dropdown">
                        <Typography className="status-heading">Status:</Typography>
                        <FormControl>
                            <Select
                                value={status}
                                onChange={handleStatusChange}
                                onClick={(event) => event.stopPropagation()} // Prevent propagation to card click
                                MenuProps={MenuProps}
                            >
                                <MenuItem value="Completed" disabled={!canMarkAsCompleted}>Completed</MenuItem>
                                <MenuItem value="Scheduled">Scheduled</MenuItem>
                                <MenuItem value="Cancel" style={{ color: '#d90429', fontWeight: '600' }}>Cancel</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                )}

                {consultationData.status === 'Payment Fail' && (
                    <Box className="payment-failed-text">
                        <Typography className='payment-failed-text'>Payment Failed</Typography>
                    </Box>
                )}
            </Box>

            <Dialog
                open={openDialog}
                onClose={() => handleCloseDialog(false)} // Close dialog without action
                disableEscapeKeyDown
                disablebackdropclick
            >
                <DialogTitle>Confirm Status Change</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to change the status to {selectedStatus}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseDialog(false)} color="primary">
                        No
                    </Button>
                    <Button
                        onClick={(event) => {
                            event.stopPropagation(); // Prevent the click event from propagating
                            handleCloseDialog(true); // Confirm status change
                        }}
                        color="primary"
                        autoFocus
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
