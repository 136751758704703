import React from 'react'
import './style.css';
import Sidebar from '../sidebar/Sidebar';
import Navbar from '../navbar/navbar';
//TODO: Update the content of the users faq

export default function FaqComp({ userType, currentUser }) {
    const faqTherapist = [
        {
            id: 'headingOne',
            target: 'collapseOne',
            question: 'What is the purpose of Thapy?',
            answer: (
                <>
                    At <strong>Thapy</strong>, we provide a convenient platform for individuals to access mental health support. It connects users with licensed therapists and peer support specialists, offering a range of therapeutic resources to enhance well-being.
                </>
            )
        },
        {
            id: 'headingTwo',
            target: 'collapseTwo',
            question: 'How does the therapy process work in the application?',
            answer: 'The therapy process typically involves creating an account, completing an initial assessment, and then choosing a licensed therapist. Users can engage in live video or messaging sessions, set goals, and access resources tailored to their mental health needs.'
        },
        {
            id: 'headingThree',
            target: 'collapseThree',
            question: 'What is the difference between a therapist and peer councillors?',
            answer: 'Therapists are licensed professionals with formal training in mental health, offering clinical expertise. Peer counsellors, while experienced and supportive, share personal experiences to empathize and assist based on their lived experiences. Both play unique roles in providing valuable support.'
        },
        {
            id: 'headingFour',
            target: 'collapseFour',
            question: 'What is the role of a licensed therapist?',
            answer: 'A licensed therapist is a trained mental health professional who provides evidence-based therapy to help individuals manage and overcome various psychological and emotional challenges. They use scientifically proven techniques to support their clients’ mental health and well-being. Their work includes assessing, diagnosing, and treating mental health disorders.'
        },
        {
            id: 'headingFive',
            target: 'collapseFive',
            question: 'What is the role of a peer counselor?',
            answer: 'A peer counselor uses their personal experiences and supportive nature to help others facing similar challenges. Unlike licensed therapists, peer counselors may not have formal clinical training but draw upon their lived experiences to offer empathy and practical advice. They provide support by sharing their own journey and helping others navigate their own challenges.'
        },
        {
            id: 'headingSix',
            target: 'collapseSix',
            question: 'What should I do if I experience technical issues during a session?',
            answer: ' If you encounter technical issues, first check your internet connection. If problems persist, navigate to the website\'s settings and select the "Technical Support" option. Our support team will assist you in resolving any technical issues promptly.'
        },
        {
            id: 'headingSeven',
            target: 'collapseSeven',
            question: 'How can I provide feedback or report issues with the website?',
            answer: 'We welcome your feedback! You can provide input or report issues through the website\'s settings menu, where you\'ll find a dedicated "Feedback" or "Report a Problem" option. Alternatively, you can reach out to our support team via email.'
        },
        {
            id: 'headingEight',
            target: 'collapseEight',
            question: 'What security measures are in place to protect user data?',
            answer: 'We employ robustsecurity measures, including encryption protocols and strict privacy policies, to safeguard user data. Confidentiality and privacy are fundamental principles guiding our app\'s design and functionality.'
        }
    ];

    const faqUser = [
        {
            id: 'headingOne',
            target: 'collapseOne',
            question: 'What is the purpose of Thapy?',
            answer: (
                <>
                    At <strong>Thapy</strong>, we provide a convenient platform for individuals to access mental health support. It connects users with licensed therapists and peer support specialists, offering a range of therapeutic resources to enhance well-being.
                </>
            )
        },
        {
            id: 'headingTwo',
            target: 'collapseTwo',
            question: 'How does the therapy process work in the application?',
            answer: 'The therapy process typically involves creating an account, completing an initial assessment, and then choosing a licensed therapist. Users can engage in live video or messaging sessions, set goals, and access resources tailored to their mental health needs.'
        },
        {
            id: 'headingThree',
            target: 'collapseThree',
            question: 'What is the difference between a therapist and peer councillors?',
            answer: 'Therapists are licensed professionals with formal training in mental health, offering clinical expertise. Peer counsellors, while experienced and supportive, share personal experiences to empathize and assist based on their lived experiences. Both play unique roles in providing valuable support.'
        },
        {
            id: 'headingFour',
            target: 'collapseFour',
            question: 'What is the role of a licensed therapist?',
            answer: 'A licensed therapist is a trained mental health professional who provides evidence-based therapy to help individuals manage and overcome various psychological and emotional challenges. They use scientifically proven techniques to support their clients’ mental health and well-being. Their work includes assessing, diagnosing, and treating mental health disorders.'
        },
        {
            id: 'headingFive',
            target: 'collapseFive',
            question: 'What is the role of a peer counselor?',
            answer: 'A peer counselor uses their personal experiences and supportive nature to help others facing similar challenges. Unlike licensed therapists, peer counselors may not have formal clinical training but draw upon their lived experiences to offer empathy and practical advice. They provide support by sharing their own journey and helping others navigate their own challenges.'
        },
        {
            id: 'headingSix',
            target: 'collapseSix',
            question: 'What should I do if I experience technical issues during a session?',
            answer: ' If you encounter technical issues, first check your internet connection. If problems persist, navigate to the website\'s settings and select the "Technical Support" option. Our support team will assist you in resolving any technical issues promptly.'
        },
        {
            id: 'headingSeven',
            target: 'collapseSeven',
            question: 'How can I provide feedback or report issues with the website?',
            answer: 'We welcome your feedback! You can provide input or report issues through the website\'s settings menu, where you\'ll find a dedicated "Feedback" or "Report a Problem" option. Alternatively, you can reach out to our support team via email.'
        },
    ];

    const faqData = userType === 'therapist' ? faqTherapist : faqUser;

    return (
        <>
            <div className='consultant_home'>
                <Sidebar userType={userType} />
                <div className='Consultant_home_container'>
                    <Navbar userData={currentUser} userType={userType} />
                    <section className="faqTherapist" data-aos="fade-up" data-aos-delay="150">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10">
                                    <div className="card card-style1 border-0">
                                        <div className="card-body p-4 p-md-5 p-xl-6">
                                            <div className="text-center mb-2-3 mb-lg-6">
                                                <h2 className="h1 mb-0 text-secondary"><strong>Frequently Asked Questions</strong></h2>
                                            </div>
                                            <div id="accordion" className="accordion-style">
                                                {faqData.map((item, index) => (
                                                    <div className="card mb-3" key={index}>
                                                        <div className="card-header" id={item.id}>
                                                            <h5 className="mb-0">
                                                                <button
                                                                    className={`btn btn-link ${item.id === 'headingOne' ? '' : 'collapsed'}`}
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#${item.target}`}
                                                                    aria-expanded={item.id === 'headingOne' ? 'true' : 'false'}
                                                                    aria-controls={item.target}
                                                                >
                                                                    <span className="text-theme-secondary me-2">Q.</span>
                                                                    {item.question}
                                                                </button>
                                                            </h5>
                                                        </div>
                                                        <div
                                                            id={item.target}
                                                            className={`collapse ${item.id === 'headingOne' ? 'show' : ''}`}
                                                            aria-labelledby={item.id}
                                                            data-bs-parent="#accordion"
                                                        >
                                                            <div className="card-body">
                                                                {item.answer}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}
