import React, { useContext } from 'react';
import Sidebar from '../../../Components/sidebar/Sidebar';
import Navbar from '../../../Components/navbar/navbar';
import './ReferAndEarn.css';
import referimg from '../../../Assets/refer.png';
import { WhatsApp, Group, AttachMoney } from '@mui/icons-material';
import { AuthContext } from "../../../Context/AuthContext";
import ShareIcon from '@mui/icons-material/Share';


const ReferAndEarn = () => {
  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Refer a Friend!',
        text: `Use my referral code: ${currentUser.referCode}`,
        url: window.location.href,
      })
        .then(() => console.log('Successfully shared'))
        .catch((error) => console.error('Something went wrong sharing', error));
    } else {
      alert('Your browser does not support the Web Share API.');
    }
  };

  const handleWhatsAppShare = () => {
    const message = `Use my referral code: ${currentUser.referCode}`;
    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };
  const { currentUser } = useContext(AuthContext);
  return (
    <div className='user_home'>
      <Sidebar userType={'user'} />
      <div className='homeContainer'>
        <Navbar userData={currentUser} userType={'user'} />
        <div className='referdetails_cont'>
          <img src={referimg} alt="Refer and Earn" className='refer_img' />
          <div className='refer_details'>
            <h2 className='refer_mainhead'>Refer your friends and family</h2>
            <p className='refrer_mainpara'>and earn upto 500 credits on each referal</p>
            <div className='referral-steps'>
              <div className='step'>
                <div className='icon-container'>
                  <WhatsApp />
                </div>
                <div className='details_para_cont'>
                  <h3 className='refer_head'>Share your referral code on WhatsApp</h3>
                  <p className='refer_para'>Or simply copy and paste the message anywhere.</p>
                </div>

              </div>
              <div className='step'>
                <div className='icon-container'>
                  <Group />
                </div>
                <div className='details_para_cont'>
                  <h3 className='refer_head'>Your friend uses your referral code</h3>
                  <p className='refer_para'>Your friend enters your referral code during sign-up to ensure that they got first consultation at zero cost.</p>
                </div>

              </div>
              <div className='step'>
                <div className='icon-container'>
                  <AttachMoney />
                </div>
                <div className='details_para_cont'>
                  <h3 className='refer_head'>You get upto ₹500 Credits Per friend</h3>
                  <p className='refer_para'>For each friend who completes their first consultation using your referral code, you earn up to Rs 500 in credits. There's no limit to the number of friends you can refer!</p>
                </div>
              </div>
              <div className='refer_btn_cont'>
                <button className='share_code_btn refer_btn' onClick={handleShare}>
                  <ShareIcon className='whtsapp_icon' />{currentUser.referCode}</button>
                <button className=' refer_btn' onClick={handleWhatsAppShare}>
                  <WhatsApp className='whtsapp_icon' /> {currentUser.referCode}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div >
  );
};

export default ReferAndEarn;