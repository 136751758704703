import React, { createContext, useContext, useState, useEffect } from 'react';
import Psychologist from './Psychologist';
import DBHandler from '../DBHandler';

const PsychologistContext = createContext();

export const PsychologistProvider = ({ children }) => {
    const [psychologist, setPsychologist] = useState(new Psychologist({}));

    useEffect(() => {
        const fetchPsychologist = async () => {
            if (psychologist.id) {
                try {
                    const dbHandler = new DBHandler();
                    const fetchedPsychologist = await dbHandler.getPsychologist(psychologist.id);
                    if (JSON.stringify(fetchedPsychologist) !== JSON.stringify(psychologist)) {
                        setPsychologist(fetchedPsychologist);
                    }
                } catch (error) {
                    console.error("Error fetching Psychologist data: ", error);
                }
            }
        };

        fetchPsychologist();
    }, [psychologist.id]);

    const modifyPsychologist = async (newPsychologist) => {
        if (JSON.stringify(newPsychologist) !== JSON.stringify(psychologist)) {
            await psychologist.modifyPsychologist(newPsychologist);
            setPsychologist(new Psychologist({ ...newPsychologist }));
        }
    };

    return (
        <PsychologistContext.Provider value={{ psychologist, modifyPsychologist }}>
            {children}
        </PsychologistContext.Provider>
    );
};

export const usePsychologist = () => useContext(PsychologistContext);
