class Payment {
    constructor({
        amt = 0.00,
        paymentStatus = 0, // 0: Pending, 1: Completed, -1: Declined
        trxId = '',
        utr = '',
        createdOn,
        settledOn = null,
        documentSnapshot = null,
    }) {
        this.amt = amt;
        this.paymentStatus = paymentStatus;
        this.trxId = trxId;
        this.utr = utr;
        this.createdOn = createdOn;
        this.settledOn = settledOn;
        this.documentSnapshot = documentSnapshot;
    }

    static fromMap(map, doc) {
        return new Payment({
            amt: map.amt ?? 0.00,
            paymentStatus: map.paymentStatus ?? 0,
            trxId: map.trxId ?? '',
            utr: map.utr ?? '',
            createdOn: map.createdOn ? map.createdOn.toDate() : null,
            settledOn: map.settledOn ? map.settledOn.toDate() : null,
            documentSnapshot: doc,
        });
    }
}

export default Payment;
