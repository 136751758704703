import React from 'react';
import { Box, Typography, Divider, Button } from '@mui/material';

const DailyFeed = () => {
  const feedItems = [
    {
      id: 1,
      avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRC8kiSH5ZSAcVoj3tAQQDoP_ux0sSricMyUg&s',
      name: 'Ashley Briggs',
      action: 'started following Stacie Hall',
      timestamp: '5m ago',
    },
    {
      id: 2,
      avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7ntUU7AzmOxa5HB8zS83sa-JFHEfZJAoI2A&s',
      name: 'Chris Wood',
      action: 'posted something on Stacie Halls timeline',
      content:
        'Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing...',
      timestamp: '30m ago',
    },
    {
      id: 3,
      avatar: 'https://pzer99.github.io/images/profile4.jpeg',
      name: 'Stacie Hall',
      action: 'posted a new blog',
      timestamp: '1h ago',
    },
    {
      id: 4,
      avatar: 'https://www.unsw.edu.au/content/dam/images/medicine-health/population-health/headshots/2022-07-med-pop-health/2022-08-tewodros-profile.cropimg.width=335.crop=square.jpg',
      name: 'John Doe',
      action: 'commented on a post',
      content: 'Great post! Keep up the good work.',
      timestamp: '2h ago',
    },
  ];

  return (
    <Box
      sx={{
        width: '40%',
        height: '500px',
        maxWidth: '600px',
        margin: '40px',
        marginTop: '0',
        marginLeft: '0',
        borderRadius: '14px',
        boxShadow: '0 0 0 0.5px rgb(190, 190, 190), 0.2em 0.2em 0.5em rgba(0, 0, 0, 0.3)',
        backgroundColor: 'white',
        padding: '20px',
        overflowY: 'auto',
        '@media (max-width: 850px)': {
          width: '90%',
          margin: '20px auto',
          height: '400px',
          padding: '10px',
        },
        '@media (maxWidth: 600px)': {
          width: '90%',
        },
        '@media (max-width: 1100px)':{
          width: '90%',
          margin: '20px auto',
        }
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h6">Daily Feed</Typography>
        <Typography
          variant="body1"
          sx={{
            bgcolor: '#1976D2',
            color: 'white',
            px: 2,
            py: 0.5,
            borderRadius: 1,
            fontSize: '14px',
            '@media (maxWidth: 600px)': {
              fontSize: '12px',
              px: 1,
              py: 0.25,
            },
          }}
        >
          Today
        </Typography>
      </Box>
      {feedItems.map((item, index) => (
        <React.Fragment key={item.id}>
          <Box display="flex" alignItems="center" py={1}>
            <img src={item.avatar} alt="Avatar" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
            <Box ml={2} flex={1}>
              <Typography variant="subtitle2" sx={{ fontSize: '14px', '@media (maxWidth: 600px)': { fontSize: '12px' } }}>
                <strong>{item.name}</strong> {item.action}
              </Typography>
              {item.content && (
                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '12px', '@media (maxWidth: 600px)': { fontSize: '11px' } }}>
                  {item.content}
                </Typography>
              )}
              <Typography variant="caption" color="text.secondary" sx={{ fontSize: '10px', '@media (maxWidth: 600px)': { fontSize: '10px' } }}>
                {item.timestamp}
              </Typography>
            </Box>
          </Box>
          {index !== feedItems.length - 1 && <Divider sx={{ marginTop: '10px' }} />}
        </React.Fragment>
      ))}
      <Box mt={2} display="flex" justifyContent="center">
        <Button variant="contained" color="primary" sx={{ width: '100%', '@media (maxWidth: 600px)': { fontSize: '12px' } }}>
          Load more
        </Button>
      </Box>
    </Box>
  );
};

export default DailyFeed;
