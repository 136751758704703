import React, { useContext } from 'react'
import Sidebar from '../../../Components/sidebar/Sidebar';
import { AuthContext } from "../../../Context/AuthContext";
import Navbar from '../../../Components/navbar/navbar';
import './style.css'

export default function UserPrivacyPolicy() {
    const { currentUser } = useContext(AuthContext);
    return (
        <>
            <div className='consultant_home'>
                <Sidebar userType={'user'} />
                <div className='Consultant_home_container'>
                    <Navbar userData={currentUser} userType={'user'} />
                    <div className="container-content" data-aos="fade-up">
                        <div className="compalinace-content">
                            <div className="content-container">
                                <p className="content-head">Privacy Policy</p>
                                <div className="content-separator"></div>
                                <p className="updated-date">Last updated on Aug 1st 2024</p>
                                <p className="content-text">
                                    This privacy policy sets out how <b>Thapy</b> uses and protects any information that you give <b>Thapy</b> when you use this website.
                                </p>
                                <p className="content-text">
                                    At <b>Thapy</b>, we are committed to respecting the privacy and confidentiality of the information that you entrust us with. Our Privacy Policy outlines the policies and procedures regarding the collection, use and disclosure of Personal Information from users. Please review this Privacy Policy carefully. In order to guarantee privacy to the client, we maintain the client’s anonymity and work in accordance with confidentiality policies to ensure that all personal and health information received is maintained and transmitted through a highly secure environment. It is recommended that you do not use the website, mobile application(s) or the related Services if any of the terms of this Privacy Policy are not in accordance with the applicable laws of your country.
                                </p>
                                <p className="content-text">
                                    <b>Thapy</b> may change this policy from time
                                    to time by updating this page. You should check this page from time to time
                                    to ensure that you are happy with any changes.
                                </p>
                                <p className="content-title"><strong>Nature of Services:</strong></p>
                                <p className="content-text">
                                    <b>Thapy</b> is a psychological wellness platform that delivers emotional wellness products and Services to individuals and organizations. These include, but are not restricted to, organizational wellness programs through which employees of organizations or individuals affiliated with organizations avail various products and Services. <b>Thapy</b> offerings may include/are:
                                </p>
                                <ul className="unorder-list">
                                    <li className="list-item">
                                        <p className="content-text list-text">Online consultation with expert Therapist (who have been authorized by Thapy to use the platform for delivering their Services)</p>
                                    </li>
                                    <li className="list-item">
                                        <p className="content-text list-text">
                                            Periodic self-assessments and psychological tests
                                        </p>
                                    </li>
                                    <li className="list-item">
                                        <p className="content-text list-text">
                                            Workshops and/or webinars delivered by trained Therapist
                                        </p>
                                    </li>
                                    <li className="list-item">
                                        <p className="content-text list-text">
                                            Self-help tools, content and programs delivered through a range of channels including, but not restricted to websites, mobile applications and emails
                                        </p>
                                    </li>
                                    <li className="list-item">
                                        <p className="content-text list-text">
                                            Guide chat packs where in clients may be able to exchange encrypted private messages with their Therapist in addition to online consultation
                                        </p>
                                    </li>
                                    <li className="list-item">
                                        <p className="content-text list-text">
                                            Participation in user forums, topics, replies, comments, feedback, blogs, reels and any other submissions on the platform
                                        </p>
                                    </li>
                                    <li className="list-item">
                                        <p className="content-text list-text">
                                            Talking to peers is a facility which we provide for affordable price
                                        </p>
                                    </li>
                                    <li className="list-item">
                                        <p className="content-text list-text">
                                            A curated shop offering a variety of products such as books, tools, and other resources aimed at improving mental health and well-being. These products can be purchased based on professional(s) recommendations or personal preference
                                        </p>
                                    </li>
                                </ul>
                                <p className="content-text">
                                    <b>Thapy</b> reserves the right to add or remove products and Services from its overall offerings without prior notification. The aforementioned shall hereinafter be referred to as “Services”.
                                </p>
                                <p className="content-title"><strong>Information We Collect:</strong></p>
                                <ul className="unorder-list">
                                    <li className="list-item">
                                        <p className="content-text list-text"><strong>Personal Information:</strong> When you register an account on Thapy, we may collect personal information such as your name, email address, date of birth, gender, and payment information.</p>
                                    </li>
                                    <li className="list-item">
                                        <p className="content-text list-text">
                                            <strong>Usage Information:</strong> We may collect information about how you interact with our app, including the pages you visit, the features you use, and the frequency and duration of your activities.
                                        </p>
                                    </li>
                                    <li className="list-item">
                                        <p className="content-text list-text">
                                            <strong>Device Information</strong> We may collect information about the device you use to access Thapy, including your device type, operating system, and unique device identifiers.
                                        </p>
                                    </li>
                                </ul>
                                <p className="content-title"><strong>How We Use Your Information:</strong></p>
                                <p className="content-text">
                                    <b>Thapy</b> uses your information to:
                                </p>
                                <ul className="unorder-list">
                                    <li className="list-item">
                                        <p className="content-text list-text"><b>Support You:</b> We use your data to respond to inquiries, provide Thapy services, and process your requests.</p>
                                    </li>
                                    <li className="list-item">
                                        <p className="content-text list-text">
                                            <b>Enhance Your Experience:</b> We personalize your Thapy journey and send relevant offers (with your permission).
                                        </p>
                                    </li>
                                    <li className="list-item">
                                        <p className="content-text list-text">
                                            <b>Improve Thapy:</b> Data analysis helps us improve Thapy's functionality and user experience.
                                        </p>
                                    </li>
                                    <li className="list-item">
                                        <p className="content-text list-text">
                                            <b>Stay Informed:</b> We may use your information to keep you informed about Thapy updates or important legal notices
                                        </p>
                                    </li>
                                    <li className="list-item">
                                        <p className="content-text list-text">
                                            <b>Safety and Security:</b> Your data may be used to comply with legal requirements and protect the safety of yourself and others.
                                        </p>
                                    </li>
                                    <li className="list-item">
                                        <p className="content-text list-text">
                                            <b>Research and Development:</b> In some cases, we may use anonymized data for research purposes, always with your consent.
                                        </p>
                                    </li>
                                    <li className="list-item">
                                        <p className="content-text list-text">
                                            <b>With Therapists and Counselors:</b> We may share your information with therapists and   counselors on Thapy to facilitate your sessions and ensure quality care.
                                        </p>
                                    </li>
                                </ul>
                                <p className="content-text">
                                    We may, when permitted, combine your Personal Data with other information, whether online or offline, maintained and available to use from you or from other sources,
                                    and we may use and disclose the combined data for the purposes described in this Section or for internal business purposes. We may, when permitted, use and disclose
                                    anonymized and aggregated data for any purpose, which may include, without limitation, disclosures to third parties for analytics purposes, such as evaluating the
                                    effectiveness of Online Services or providing additional benefits, programs and services. We may use your Personal Data for internal research purposes, and we may
                                    share such information with third party research partners who are bound to maintain the confidentiality of any and all personally identifiable information and use
                                    it only for such research purposes as described under “How We Share Personal Data”.
                                </p>
                                <p className="content-title"><strong>Data Security:</strong></p>
                                <p className="content-text">
                                    We take appropriate measures to safeguard your personal information and protect it from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over
                                    the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
                                </p>

                                <p className="content-title"><strong>Cookies:</strong></p>
                                <p className="content-text">
                                    We use “cookies” to collect information and smoothen your experience on our platform. A cookie is a small data file that we transfer to your device’s hard disk for record-keeping purposes.
                                    We use cookies for two purposes. First, we may utilise persistent cookies to save your user credentials for future logins to the Services. Second, we may utilise session ID cookies to enable
                                    certain features of the Services, to better understand how you interact with the Services and to monitor aggregate usage by users of the Services and online traffic routing on the Services.
                                    Unlike persistent cookies, session cookies are deleted from your computer when you log off from the Services and then close your browser.
                                </p>
                                <p className="content-text">
                                    We may work with third parties that place or read cookies on your browser to improve your user experience. In such cases, by using the third-party services through our platform, you consent
                                    to their Privacy Policy and terms of use and agree not to hold Amaha liable for any issues arising from such use.
                                </p>
                                <p className="content-text">
                                    You can instruct your browser, by changing its options, to stop accepting cookies or to prompt you before accepting a cookie from the websites you visit. If you do not accept cookies, however,
                                    you may not be able to use all features or functionalities of the platform.
                                </p>

                                <p className="content-title"><strong>Thapy Account Management:</strong></p>
                                <p className="content-text">
                                    You have the option to deactivate your Thapy account at any time. While your profile will no longer be active, we may retain certain limited data to comply with legal requirements or for internal
                                    administrative purposes. You may also choose to opt out of receiving promotional communications from us.
                                </p>

                                <p className="content-title"><strong>Confidentiality:</strong></p>
                                <p className="content-text">
                                    <b>Thapy</b> prioritizes your privacy. Information shared in therapy sessions is confidential and will not be disclosed without your consent, except in rare circumstances:
                                </p>
                                <ul className="unorder-list">
                                    <li className="list-item">
                                        <p className="content-text list-text"><b>Safety Risk:</b> If there's a serious threat of harm to yourself or others.</p>
                                    </li>
                                    <li className="list-item">
                                        <p className="content-text list-text">
                                            <b>Legal Requirement:</b> If compelled by law (e.g., court order).
                                        </p>
                                    </li>
                                </ul>
                                <p className="content-text">
                                    Your therapist may keep confidential session notes as required by professional standards.
                                </p>

                                <p className="content-title"><strong>Sharing wisely:</strong></p>
                                <p className="content-text">
                                    <b>Thapy</b> protects your info, but public forums within the app are NOT private. Be cautious about what you share. Thapy is not responsible for misuse of information you choose to make public.
                                    Data security is a priority, but no online system is foolproof.
                                </p>
                                <p className="content-text">
                                    Use <b>Thapy</b> responsibly. Keep your personal info private!
                                </p>

                                <p className="content-title"><strong>Changes to this Privacy Policy:</strong></p>
                                <p className="content-text">
                                    <b>Thapy</b> connects you with licensed therapists for mental health support. We strive for quality, but can't guarantee specific outcomes or the advice provided. Therapists are independent and
                                    <b>Thapy</b> isn't liable for their actions. Calls are not recorded,
                                    but therapists may keep confidential session notes as required by professional standards.
                                </p>
                                <p className="content-text">
                                    By continuing to use <b>Thapy</b> after any updates to this Privacy Policy, you acknowledge and agree to the revised terms. This signifies your continued consent to the collection, use, and processing
                                    of your personal information as outlined in the updated Privacy Policy.
                                </p>

                                <p className="content-title"><strong>Terms and Conditions:</strong></p>
                                <p className="content-text">
                                    Please refer to our separate <b>Terms and Conditions</b> document for detailed information about your
                                    rights and obligations when using <b>Thapy</b>, including rules of conduct, limitations of liability, and
                                    dispute resolution procedures.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}