import React from 'react';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography, ThemeProvider, createTheme } from '@mui/material';
import { styled } from '@mui/system';
import './appointment.css'; // Import the CSS file

const Header = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontWeight: 'bold', // Make the title bold
  padding:'15px 0px 0px 15px',
  fontSize:'20px'
}));

const useStyles = {
  listItem: {
    alignItems: 'flex-start',
    borderBottom: '1px solid #ddd',
    paddingBottom: '10px',
    marginBottom: '10px',
  },
  avatar: {
    backgroundColor: 'transparent',
  },
  icon: {
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    border: '2px solid #1976d2',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerCircle: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: '#1976d2',
  },
  inline: {
    display: 'inline',
  },
  time: {
    fontSize: '0.8rem',
    color: 'text.secondary',
  },
};

const Appointments = () => {
  const classes = useStyles;

  const theme = createTheme({
    palette: {
      background: {
        paper: '#fff',
      },
      primary: {
        main: '#1976d2',
      },
      text: {
        primary: '#000',
        secondary: '#666',
      },
    },
    spacing: 8,
  });

  const appointments = [
    { title: 'Chat with Carl and Ashley', description: 'Nom pretium turpis et arcu. Duis arcu tortor, suscipit eget, imperdiet nec, imperdiet iaculis, ipsum. Sed aliquam ultrices mauris..', time: '30m ago' },
    { title: 'The big launch', description: 'Sed aliquam ultrices mauris. Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Praesent adipiscing. Phasellus ullamcorper ipsum rutrum nunc.', time: '2h ago' },
    { title: 'Coffee break', description: 'Curabitur ligula sapien, tincidunt non, euismod vitae, posuere imperdiet, leo. Maecenas malesuada.', time: '3h ago' },
    { title: 'Chat with team', description: 'Nam pretium turpis et arcu. Duis arcu tortor, suscipit eget, imperdiet nec, imperdiet iaculis, ipsum..', time: '3h ago' },
  ];

  return (
    <div className="appointment_card">
      <ThemeProvider theme={theme}>
        <Header variant="h6" >
          Appointments
        </Header>
        <List sx={{ maxHeight: 400, overflowY: 'auto' }}>
          {appointments.map((appointment, index) => (
            <ListItem alignItems="flex-start" key={index} sx={classes.listItem}>
              <ListItemAvatar>
                <Avatar sx={classes.avatar}>
                  <div className="outer-circle">
                    <div className="inner-circle"></div>
                  </div>
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    <Typography variant="h7" fontWeight="bold"> 
                      {appointment.title}
                    </Typography>
                    <Typography component="span" sx={classes.time}>
                      {' '}{appointment.time}
                    </Typography>
                  </>
                }
                secondary={
                  <Typography
                    component="span"
                    variant="body2"
                    sx={classes.inline}
                    color="textPrimary"
                  >
                    {appointment.description}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </ThemeProvider>
    </div>
  );
};

export default Appointments;
