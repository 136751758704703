class BookingDetails {
    constructor({
        amt = 0.00,
        bookingId = '',
        bookingStatus = 0, // 0: Scheduled, 1: Completed, -1: Cancelled
        callType = '',
        dateTimeSlot = null,
        paymentId = '',
        psyId = '',
        sessionMode = '',
        discount = 0.00,
        userId = '',
        status = '',
        rating = 0.00,
        userName = '',
        userPhone = '',
        notificationToken = '',
        timestamp
    }) {
        this.amt = amt;
        this.bookingId = bookingId;
        this.bookingStatus = bookingStatus;
        this.callType = callType;
        this.dateTimeSlot = dateTimeSlot;
        this.paymentId = paymentId;
        this.psyId = psyId;
        this.sessionMode = sessionMode;
        this.discount = discount;
        this.userId = userId;
        this.status = status;
        this.rating = rating;
        this.timestamp = timestamp;
        this.userName = userName;
        this.userPhone = userPhone;
        this.notificationToken = notificationToken;
    }

    static fromMap(map) {
        return new BookingDetails({
            amt: map.amt ?? 0.00,
            bookingId: map.bookingId ?? '',
            bookingStatus: map.bookingStatus ?? 0,
            callType: map.callType ?? '',
            dateTimeSlot: map.dateTimeSlot ? map.dateTimeSlot.toDate() : null,
            paymentId: map.paymentId ?? '',
            psyId: map.psyId ?? '',
            sessionMode: map.sessionMode ?? '',
            discount: map.discount ?? 0.00,
            status: map.status ?? '',
            timestamp: map.timestamp,
            userId: map.userId ?? '',
            rating: map.rating ?? 0.00,
            userName: map.userName ?? '',
            userPhone: map.userPhone ?? '',
            notificationToken: map.notificationToken ?? ''
        });
    }
}

export default BookingDetails;
