import React, { useState, useContext } from 'react'
import './Reset.css'
import passwordImg from '../../Assets/resetPassword.png'
import Sidebar from '../sidebar/Sidebar';
import Navbar from '../navbar/navbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../Config/Config";


const ResetPassword = ({ currentUser, userType }) => {
  const [passwordData, setPasswordData] = useState({
    newPassword: '',
    renewPassword: ''
  });

  const [reauthPassword, setReauthPassword] = useState('');
  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setPasswordData({ ...passwordData, [id]: value });
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();

    const { newPassword, renewPassword } = passwordData;

    if (newPassword !== renewPassword) {
      toast.error("The entered new passwords do not match!", { containerId: 'resetPassword' });
      setPasswordData({
        newPassword: '',
        renewPassword: ''
      })
      setReauthPassword('')
      return;
    }

    if (reauthPassword === newPassword) {
      toast.info("Your password remains unchanged as it matches your current password!", { containerId: 'resetPassword' });
      setPasswordData({
        newPassword: '',
        renewPassword: ''
      })
      setReauthPassword('')
      return;
    }

    try {
      const credential = EmailAuthProvider.credential(auth.currentUser.email, reauthPassword);
      await reauthenticateWithCredential(auth.currentUser, credential);

      await auth.currentUser.updatePassword(newPassword);

      toast.success("Your password has been successfully updated!", { containerId: 'resetPassword' });
      setPasswordData({
        newPassword: '',
        renewPassword: ''
      })
      setReauthPassword('')
      userType === 'therapist' ? setTimeout(() => { navigate('/therapists/dashboard'); }, 1000) : setTimeout(() => { navigate('/user/dashboard'); }, 3000);
    } catch (error) {
      toast.error("Current Password is wrong!", { containerId: 'resetPassword' });
      setPasswordData({
        newPassword: '',
        renewPassword: ''
      })
      setReauthPassword('')
    }
  };

  return (
    <div className='reset_home'>
      <ToastContainer
        containerId="resetPassword"
        position="top-right"
        autoClose={2400}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light" />
      <Sidebar userType={userType} />
      <div className='homeContainer'>
        <Navbar userData={currentUser} userType={userType} />
        <div className='reset_container'>
          <div className='resetImg_container'>
            <img
              src={passwordImg}
              className='password_img'
              alt="Reset Password"
            />
          </div>
          <div className='reset_form_container'>
            <div>
              <h2 className='reset_heading'>Reset Password</h2>
              <div className="input-group first_one">
                <input required type="password" name="reauthPassword" autoComplete="off" className="input" value={reauthPassword}
                  onChange={(e) => setReauthPassword(e.target.value)} />
                <label className="user-label">Current Password</label>
              </div>
              <div className="input-group">
                <input required type="password" id="newPassword" name="newPassword" autoComplete="off" className="input" value={passwordData.newPassword}
                  onChange={handlePasswordChange} />
                <label className="user-label">New Password</label>
              </div>
              <div className="input-group">
                <input required type="password" id="renewPassword" name="renewPassword" autoComplete="off" className="input" value={passwordData.renewPassword}
                  onChange={handlePasswordChange} />
                <label className="user-label">Re Enter Password</label>
              </div>
              <button className="button" onClick={handleChangePassword}>Reset Password</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
