import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PsychologistProvider } from './Utils/Psychologist/PsychologistContext';
import { UserProvider } from './Utils/User/UserContext';
import { AuthContextProvider } from './Context/AuthContext';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PsychologistProvider>
      <UserProvider>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </UserProvider>
    </PsychologistProvider>
  </React.StrictMode >
);
reportWebVitals();
serviceWorkerRegistration.register();
