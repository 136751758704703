import { createContext, useEffect, useReducer, useMemo, useState } from "react";
import AuthReducer from "./AuthReducer";
import { auth } from "../Config/Config";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from "../Utils/User/UserContext";
import { usePsychologist } from "../Utils/Psychologist/PsychologistContext";
import DBHandler from '../Utils/DBHandler';
import Loader from "../Components/Loader/Loader";

const INITIAL_STATE = {
    currentUser: null,
    userType: null,
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
    const { modifyUser } = useUser();
    const { modifyPsychologist } = usePsychologist();
    const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);
    const [loading, setLoading] = useState(true);

    const dbHandler = useMemo(() => new DBHandler(), []);

    useEffect(() => {
        const sessionUser = JSON.parse(sessionStorage.getItem("user"));
        if (sessionUser) {
            dispatch({
                type: "SET_USER_TYPE",
                payload: {
                    currentUser: sessionUser,
                    userType: sessionUser.userType,
                },
            });
            setLoading(false);
        }

        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                try {
                    let customUID = await dbHandler.getCustomUID(user.uid);
                    const isPsyTrue = await dbHandler.doesPsychologistExist(customUID);
                    const isUserTrue = await dbHandler.doesUserExist(customUID);

                    if (isPsyTrue) {
                        const psychologistData = await dbHandler.getPsychologist(customUID);
                        await modifyPsychologist(psychologistData);
                        dispatch({
                            type: "SET_USER_TYPE",
                            payload: {
                                currentUser: psychologistData,
                                userType: 'userPsychologist',
                            },
                        });
                    } else if (isUserTrue) {
                        const userData = await dbHandler.getUser(customUID);
                        await modifyUser(userData);
                        dispatch({
                            type: "SET_USER_TYPE",
                            payload: {
                                currentUser: userData,
                                userType: 'generalUser',
                            },
                        });
                    } else {
                        // toast.error("User data not found! Please contact the administrator.");
                    }
                } catch (error) {
                    toast.error("Error fetching user data!! Please check your internet connection. If the problem persists, please contact the administrator.", { containerId: 'authContext' });
                }
            } else {
                sessionStorage.removeItem("user");
                dispatch({
                    type: "SET_USER_TYPE",
                    payload: {
                        currentUser: null,
                        userType: null,
                    },
                });
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, [modifyPsychologist, modifyUser, dbHandler]);

    useEffect(() => {
        if (state.currentUser) {
            sessionStorage.setItem("user", JSON.stringify(state.currentUser));
        }
    }, [state.currentUser]);

    // useEffect(() => {
    //     const handleBeforeUnload = () => {
    //         sessionStorage.removeItem("user");
    //     };

    //     window.addEventListener("beforeunload", handleBeforeUnload);

    //     return () => {
    //         window.removeEventListener("beforeunload", handleBeforeUnload);
    //     };
    // }, []);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            sessionStorage.removeItem("user");
            sessionStorage.clear();
            event.returnValue = '';
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    const setUserType = (userType) => {
        dispatch({ type: "SET_USER_TYPE", payload: userType });
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <ToastContainer
                containerId="authContext"
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <AuthContext.Provider value={{ ...state, setUserType, dispatch }}>
                {children}
            </AuthContext.Provider>
        </>
    );
};
