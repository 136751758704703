import React, { createContext, useContext, useState, useEffect } from 'react';
import User from './User';
import DBHandler from '../DBHandler';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(new User({}));

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                if (user.id) {
                    const dbHandler = new DBHandler();
                    const fetchedUserData = await dbHandler.getUser(user.id);
                    if (JSON.stringify(fetchedUserData) !== JSON.stringify(user)) {
                        setUser(fetchedUserData);
                    }
                }
            } catch (error) {
                console.error("Error fetching userdata: ", error);
            }
        };

        fetchUserData();
    }, [user.id]);

    const modifyUser = async (newUser) => {
        if (JSON.stringify(newUser) !== JSON.stringify(user)) {
            await user.modifyUser(newUser);
            setUser(new User({ ...newUser }));
        }
    };

    return (
        <UserContext.Provider value={{ user, modifyUser }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
