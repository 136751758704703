import React from 'react'
import './UserTerms.css'
import termsimg from '../../Assets/terms and conditions.png'
import '../PrivacyPolicy/PrivacyPolicy.css'
import HomeNavbar from '../../Components/Navbar';

const PeersTerms = () => {
  return (
    <>
      <HomeNavbar />
      <div className="terms_image_container">
        <div className="terms_image">
          <img src={termsimg} alt="privacypolicy" />
        </div>
        <div className="terms_text">
          <h1 className="terms_heading">Terms and Conditions</h1>
          <p className="terms_paragraph">
            Explore the guidelines that govern your use of our services, ensuring transparency and fairness for all users.
          </p>
        </div>
      </div>

      <div className='privacypolicy_details_container'>
        <div className='common_privacy_policy_para'>
          <span className='privacypolicy_details_heading'>T&C for Peers<span className="logo-dot">.</span></span>
          <p className='privacy_first_para'>
            Welcome to Thapy! These Terms and Conditions ("Terms") govern your use of our platform as a Peer. By registering as a counselor on Thapy, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our platform.
          </p>
        </div>

        <div className="container-policy">
          <div className="card">
            <div className="card-body">
              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>1. </span>Eligibility
                </span>
                <p>You must be at least 18 years old to register as a peer on Thapy. By registering on Thapy, you
                  represent and warrant that you meet this requirement.
                </p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>2. </span>Account Registration
                </span>
                <p>You are required to provide accurate and complete information during the registration process,
                  Including your name, age, and any additional details.
                </p>
              </div>


              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>3. </span>Verification Process
                </span>
                <p>You agree to cooperate with Thapy's verification process, which may include providing
                  documentation or additional information to confirm your identity and experiences.
                </p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>4. </span>Communication Guidelines
                </span>
                <p>You agree to engage in respectful and supportive communication with other users on Thapy.
                  Harassment, discrimination, or any form of inappropriate behavior will not be tolerated.
                </p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>5. </span>Peer Matching
                </span>
                <p>You understand that the matching process is based on the information you provide in your
                  profile, and Thapy cannot guarantee compatibility or outcomes from peer connections.

                </p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>6. </span>Pricing and Compensation
                </span>
                <p>You agree to the prices set for counselors on Thapy, which may be subject to change depending
                  on your reviews and performance. As an incentive, you may earn more as you improve your
                  ratings and receive positive feedback from users.
                </p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>7. </span>User Ratings
                </span>
                <p>Your performance will be rated by users after each interaction, based on factors such as
                  empathy, communication, and effectiveness. Your ratings may influence your compensation and
                  opportunities on Thapy.
                </p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>8. </span>Confidentiality
                </span>
                <p>You agree to maintain the confidentiality of any information shared by other users during peer
                  interactions. Respect the privacy and boundaries of your peers at all times.
                </p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>9. </span>Investigation and Termination
                </span>
                <p>Thapy reserves the right to investigate any reports of misconduct or violations of these Terms
                  against counselors. If found guilty, your account may be terminated immediately without prior
                  notice or liability.

                </p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>10. </span>Availability for Instant Calls
                </span>
                <p>You have the option to make yourself available for instant calls with users on Thapy. This
                  feature is voluntary and can be enabled or disabled based on your availability and preferences.

                </p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>11. </span>Access to Community Page
                </span>
                <p>You are welcome to participate in the community page on Thapy, where users can connect and
                  discuss various issues. Your contributions should be respectful and adhere to the community guidelines.

                </p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>12. </span>Changes to Terms
                </span>
                <p>We may revise these Terms from time to time in our sole discretion. The most current version will always be available on Thapy. By continuing to use Thapy after any revisions become effective, you agree to be bound by the revised Terms.

                </p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  Grievance Redressal
                </span>
                <p>We take user concerns seriously. If you have any issues with Thapy's services, you can   file a grievance through our dedicated support channel: <span className='terms_mail'>contact@thapy.com</span><br />
                  We strive to resolve all grievances fairly and efficiently. You can expect a response
                  within one month of submitting your complaint.

                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PeersTerms
