import React, { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Badge,
  IconButton,
  OutlinedInput,
  Grid,
  Chip,
  FormHelperText,
  Avatar,
} from "@mui/material";
import Loader from "../../../Components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { auth, storage } from "../../../Config/Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DBHandler from "../../../Utils/DBHandler";
import { usePsychologist } from "../../../Utils/Psychologist/PsychologistContext";
import { serverTimestamp } from "firebase/firestore"; import { Link } from "react-router-dom";
import './Profile.css'
import Sidebar from '../../../Components/sidebar/Sidebar';
import Navbar from '../../../Components/navbar/navbar';
import '../Home/home.scss';

const TherapistsProfile = () => {

  const { psychologist, modifyPsychologist } = usePsychologist();
  const dbHandler = new DBHandler();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [languageError, setLanguageError] = useState(false);
  const [expertiseError, setExpertiseError] = useState(false);

  const [fullName, setFullName] = useState(psychologist.name || "");
  const [profileImage, setProfileImage] = useState("");
  const [about, setAbout] = useState(psychologist.about || "");
  const [contactNumber, setContactNumber] = useState(psychologist.phone || "");
  const [countryCode, setCountryCode] = useState(
    psychologist.countryCode || "91"
  );
  const [priceConsult, setPriceConsult] = useState(psychologist.price || 0);
  const [upiID, setUpiID] = useState(psychologist.upi || "");
  const [language, setLanguage] = useState(psychologist.languages || []);
  const [expertise, setExpertise] = useState(psychologist.areas || []);

  const languageOptions = [
    { label: "English", value: "English" },
    { label: "Hindi (हिंदी)", value: "Hindi" },
    { label: "Bengali (বাংলা)", value: "Bengali" },
    { label: "Marathi (मराठी)", value: "Marathi" },
    { label: "Telugu (తెలుగు)", value: "Telugu" },
    { label: "Tamil (தமிழ்)", value: "Tamil" },
    { label: "Gujarati (ગુજરાતી)", value: "Gujarati" },
    { label: "Urdu (اردو)", value: "Urdu" },
    { label: "Kannada (کنڑ)", value: "Kannada" },
    { label: "Odia (ଓଡିଆ)", value: "Odia" },
    { label: "Malayalam (മലയാളം)", value: "Malayalam" },
    { label: "Punjabi (ਪੰਜਾਬੀ) ", value: "Punjabi" },
  ];

  const expertiseOptions = [
    { label: "General", value: "General" },
    { label: "Depression", value: "Depression" },
    { label: "Breakup", value: "Breakup" },
    { label: "Stress", value: "Stress" },
    { label: "Anxiety", value: "Anxiety" },
    { label: "Addiction", value: "Addiction" },
    { label: "Relationship", value: "Relationship" },
    { label: "Grief", value: "Grief" },
    { label: "Career", value: "Career" },
    { label: "Family", value: "Family" },
    { label: "Trauma", value: "Trauma" },
    { label: "Self-Esteem", value: "Self-Esteem" },
  ];

  const handleLanguageChange = (event) => {
    const { value } = event.target;
    setLanguage(value);
    if (value.length === 0) {
      setLanguageError("Please select at least one language");
    } else {
      setLanguageError("");
    }
  };

  const handleExpertiseChange = (event) => {
    const { value } = event.target;
    setExpertise(value);
    if (value.length === 0) {
      setExpertiseError("Please select at least one language");
    } else {
      setExpertiseError("");
    }
  };

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      if (selectedImage.size > 1024 * 1024 * 5) {
        toast.error("Image size should be less than 5MB", { containerId: 'therapistProfile' });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          setProfileImage(reader.result);
        };
        reader.readAsDataURL(selectedImage);
      }
    } else {
      toast.error("Please add a valid image!!", { containerId: 'therapistProfile' });
    }
  };

  const uploadImage = async (file, path) => {
    const ref = storage.ref(path);
    await ref.put(file);
    return ref.getDownloadURL();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const profileFile = document.getElementById("profile-image-upload")
          .files[0];

        let profileImagePath = "";

        if (profileFile) {
          const profileExtension = profileFile.name.split(".").pop();
          profileImagePath = await uploadImage(
            profileFile,
            `psy/profile/${currentUser.uid}.${profileExtension}`
          );
          await uploadImage(
            profileFile,
            `profile/${currentUser.uid}.${profileExtension}`
          );
        }
        const psychologistData = {
          id: currentUser.uid,
          name: fullName,
          phone: contactNumber,
          countryCode: countryCode,
          imagePath: profileImagePath,
          about: about,
          price: parseFloat(priceConsult),
          upi: upiID,
          languages: language,
          areas: expertise,
          lastOnline: serverTimestamp(),
        };
        await dbHandler.updatePsychologist(psychologistData);
        await modifyPsychologist(psychologistData);
        toast.success("Profile updated successfully", { containerId: 'therapistProfile' });
        setTimeout(() => {
          navigate("/therapists/dashboard");
        }, 3000);
      } else {
        toast.error("Error updating details. Please try again later!!", { containerId: 'therapistProfile' });
      }
    } catch (error) {
      toast.error(error.message, { containerId: 'therapistProfile' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <link
        href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700"
        rel="stylesheet"
      />
      <ToastContainer
        containerId="therapistProfile"
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {isLoading && <Loader />}
      <div className='therapy_home'>
        <Sidebar userType={'therapist'} />
        <div className='homeContainer'>
          <Navbar userData={psychologist} userType={'therapist'} />
          <div className="profile-content">
            <div
              className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
              style={{
                minHeight: 600,
                backgroundImage:
                  "url(https://raw.githubusercontent.com/creativetimofficial/argon-dashboard/gh-pages/assets-old/img/theme/profile-cover.jpg)",
                backgroundSize: "cover",
                backgroundPosition: "center top"
              }}
            >
              <span className="mask bg-gradient-default opacity-8" />
              <div className="container-fluid d-flex align-items-center">
                <div className="row">
                  <div className="col-lg-7 col-md-10">
                    <h1 className="display-2 text-white">Hello {psychologist.name.split(" ")[0]}!</h1>
                    <p className="text-white mt-0 mb-5">
                      Welcome to your profile page. Here, you can monitor the progress you've made with your work and make any necessary updates to your profile
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Page content */}
            <div className="container-fluid mt--7">
              <div className="row">
                <div className="col-xl-4 order-xl-2 mb-5 mb-xl-0">
                  <div className="card card-profile shadow">
                    <div className="row justify-content-center">
                      <div className="col-lg-3 order-lg-2">
                        <div className="card-profile-image">
                          <Link>
                            <img
                              src={psychologist.imagePath}
                              className="rounded-circle"
                              alt={psychologist.name}
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></div>
                    <div className="card-body pt-0 pt-md-4">
                      <div className="row">
                        <div className="col">
                          <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                            <div>
                              <span className="heading">50+</span>
                              <span className="description">Patients</span>
                            </div>
                            <div>
                              <span className="heading" >4.8</span>
                              <span className="description" style={{ color: 'orange' }}><i className="bi bi-star-fill"></i></span>
                            </div>
                            <div>
                              <span className="heading">100+</span>
                              <span className="description">Calls</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-center">
                        <h3>
                          {psychologist.name}
                          {/* <span className="font-weight-light">, 27</span> */}
                        </h3>
                        <div className="h5 font-weight-300">
                          <i className="ni location_pin mr-2" />
                          {psychologist.professional ? 'Professional Psychologist' : 'Peer'}
                        </div>
                        <div className="h5 mt-3">
                          <i className="ni business_briefcase-24 mr-2" />
                          {psychologist.languages.join(", ")}
                        </div>
                        <div>
                          <i className="ni education_hat mr-2" />
                          {psychologist.areas.join(", ")}
                        </div>
                        <hr className="my-4" />
                        <p>
                          {psychologist.about}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 order-xl-1">
                  <div className="card bg-secondary shadow">
                    <div className="card-header bg-white border-0">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h3 className="mb-0">My account</h3>
                        </div>
                        <div className="col-4 text-right">
                          <Link to='/therapists/dashboard' className="btn btn-sm btn-primary">
                            Settings
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <form onSubmit={handleSubmit}>
                        <h6 className="heading-small text-muted mb-0">
                          User information
                        </h6>
                        <div className="pl-lg-4">
                          <div className="row">
                            <Grid container justifyContent="center">
                              <Grid item xs={12}>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="column"
                                  justifyContent="center"
                                >
                                  <Badge
                                    overlap="circular"
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "right",
                                    }}
                                    badgeContent={
                                      <label htmlFor="profile-image-upload">
                                        <IconButton
                                          sx={{ color: "#00296b" }}
                                          aria-label="upload picture"
                                          component="span"
                                        >
                                          <i className="bi bi-camera-fill"></i>
                                        </IconButton>
                                        <input
                                          accept="image/*"
                                          style={{ display: "none" }}
                                          id="profile-image-upload"
                                          type="file"
                                          onChange={handleImageChange}
                                        />
                                      </label>
                                    }
                                  >
                                    <Avatar
                                      alt="Profile Image"
                                      src={profileImage === "" ? psychologist.imagePath : profileImage}
                                      sx={{ width: 140, height: 150 }}
                                    />
                                  </Badge>
                                  <FormHelperText style={{ fontSize: "1rem" }}>
                                    <b>Upload your profile image</b>
                                  </FormHelperText>
                                </Box>
                              </Grid>
                            </Grid>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group focused">
                                <label
                                  className="form-control-label"
                                  htmlFor="name"
                                >
                                  Full Name
                                </label>
                                <input
                                  type="text"
                                  id="name"
                                  className="form-control form-control-alternative"
                                  placeholder="Full Name"
                                  required
                                  value={fullName}
                                  onChange={(e) => setFullName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label
                                  className="form-control-label"
                                  htmlFor="email"
                                >
                                  Email address
                                </label>
                                <input
                                  type="email"
                                  id="email"
                                  className="form-control form-control-alternative"
                                  placeholder="abc@example.com"
                                  value={psychologist.email}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="row">
                                <div className="col-lg-3">
                                  <div className="form-group focused">
                                    <label
                                      className="form-control-label"
                                      htmlFor="country-code"
                                    >
                                      Country
                                    </label>
                                    <input
                                      type="text"
                                      id="country-code"
                                      className="form-control form-control-alternative"
                                      placeholder="Country Code"
                                      value={countryCode}
                                      required
                                      onChange={(e) => setCountryCode(e.target.value)}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-9">
                                  <div className="form-group focused">
                                    <label
                                      className="form-control-label"
                                      htmlFor="phonenumber"
                                    >
                                      Phone Number
                                    </label>
                                    <input
                                      type="text"
                                      id="phonenumber"
                                      className="form-control form-control-alternative"
                                      placeholder="Contact Number"
                                      value={contactNumber}
                                      required
                                      onChange={(e) => setContactNumber(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group focused">
                                <label
                                  className="form-control-label"
                                  htmlFor="profession"
                                >
                                  Profession
                                </label>
                                <input
                                  type="text"
                                  id="profession"
                                  className="form-control form-control-alternative"
                                  placeholder="Profession"
                                  value={psychologist.professional ? 'Professional' : 'Peer'}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="my-4" />
                        <h6 className="heading-small text-muted mb-0">
                          Consultation information
                        </h6>
                        <div className="pl-lg-4">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group focused">
                                <label
                                  className="form-control-label"
                                  htmlFor="priceConsult"
                                >
                                  Price Per Consultation
                                </label>
                                <input
                                  id="priceConsult"
                                  className="form-control form-control-alternative"
                                  placeholder="Home Address"
                                  value={priceConsult}
                                  onChange={(e) => setPriceConsult(e.target.value)}
                                  type="text"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="form-group focused">
                                <label
                                  className="form-control-label"
                                  htmlFor="upiID"
                                >
                                  UPI ID
                                </label>
                                <input
                                  type="text"
                                  id="upiID"
                                  className="form-control form-control-alternative"
                                  onChange={(e) => setUpiID(e.target.value)}
                                  value={upiID}
                                  required
                                  placeholder="abc@upi"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="form-group focused">
                                <label
                                  className="form-control-label mb-3"
                                  htmlFor="input-country"
                                >
                                  Languages
                                </label>
                                <Box>
                                  <FormControl
                                    variant="outlined"
                                    fullWidth
                                    error={languageError}
                                    required
                                  >
                                    <InputLabel id="language-label">
                                      Select Languages
                                    </InputLabel>
                                    <Select
                                      labelId="language-label"
                                      id="language"
                                      name="Language"
                                      multiple
                                      onChange={handleLanguageChange}
                                      value={language}
                                      input={
                                        <OutlinedInput
                                          startAdornment={
                                            <InputAdornment position="start">
                                              <i className="bi bi-translate"></i>
                                            </InputAdornment>
                                          }
                                          label="Select Languages"
                                          id="select-multiple-chip"
                                        />
                                      }
                                      renderValue={(selected) => (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: 0.5,
                                          }}
                                        >
                                          {selected.map((value) => {
                                            const option = languageOptions.find(
                                              (opt) => opt.value === value
                                            );
                                            return (
                                              <Chip
                                                key={value}
                                                label={option ? option.label : value}
                                              />
                                            );
                                          })}
                                        </Box>
                                      )}
                                    >
                                      <MenuItem disabled value="">
                                        <em>Select atleast 1 language</em>
                                      </MenuItem>
                                      {languageOptions.map((option) => (
                                        <MenuItem
                                          key={option.value}
                                          value={option.value}
                                        >
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    {languageError && (
                                      <FormHelperText>
                                        Please select at least one language
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Box>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="form-group">
                                <label
                                  className="form-control-label mb-3"
                                  htmlFor="input-country"
                                >
                                  Expertise Area
                                </label>
                                <Box className="col-12">
                                  <FormControl
                                    variant="outlined"
                                    fullWidth
                                    error={expertiseError}
                                    required
                                  >
                                    <InputLabel id="language-label">
                                      Select Expertise Areas
                                    </InputLabel>
                                    <Select
                                      labelId="expertise-label"
                                      id="expertise"
                                      name="expertise"
                                      multiple
                                      onChange={handleExpertiseChange}
                                      value={expertise}
                                      input={
                                        <OutlinedInput
                                          startAdornment={
                                            <InputAdornment position="start">
                                              <i className="bi bi-award-fill"></i>
                                            </InputAdornment>
                                          }
                                          label="Select Expertise Areas"
                                          id="select-multiple-chip"
                                        />
                                      }
                                      renderValue={(selected) => (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: 0.5,
                                          }}
                                        >
                                          {selected.map((value) => {
                                            const option = expertiseOptions.find(
                                              (opt) => opt.value === value
                                            );
                                            return (
                                              <Chip
                                                key={value}
                                                label={option ? option.label : value}
                                              />
                                            );
                                          })}
                                        </Box>
                                      )}
                                    >
                                      <MenuItem disabled value="">
                                        <em>Select atleast 1 expertise area</em>
                                      </MenuItem>
                                      {expertiseOptions.map((option) => (
                                        <MenuItem
                                          key={option.value}
                                          value={option.value}
                                        >
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    {expertiseError && (
                                      <FormHelperText>
                                        Please select at least one expertise area
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Box>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="my-4" />
                        <h6 className="heading-small text-muted mb-4">About me</h6>
                        <div className="pl-lg-4">
                          <div className="form-group focused">
                            <label>About Me</label>
                            <textarea
                              rows={4}
                              className="form-control form-control-alternative"
                              placeholder="A few words about you ..."
                              value={about}
                              required
                              onChange={(e) => setAbout(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="text-center mt-5 mb-5">
                          <button type="submit" className="btn btn-default rounded-pill" disabled={isLoading}>
                            Save Changes
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>

  )
}

export default TherapistsProfile
