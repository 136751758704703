import React from 'react'
import termsimg from '../../Assets/terms and conditions.png'
import './UserTerms.css'
import '../PrivacyPolicy/PrivacyPolicy.css'
import HomeNavbar from '../../Components/Navbar';

const UserTerms = () => {
  return (
    <>
      <HomeNavbar />
      <div className="terms_image_container">
        <div className="terms_image">
          <img src={termsimg} alt="privacypolicy" />
        </div>
        <div className="terms_text">
          <h1 className="terms_heading">Terms and Conditions</h1>
          <p className="terms_paragraph">
            Explore the guidelines that govern your use of our services, ensuring transparency and fairness for all users.
          </p>
        </div>
      </div>

      <div className='privacypolicy_details_container'>
        <div className='common_privacy_policy_para'>
          <span className='privacypolicy_details_heading'>T&C for Users<span className="logo-dot">.</span></span>
          <p className='privacy_first_para'>
            Welcome to Thapy! These Terms and Conditions {"Terms"} govern your use of our mobile application and website. By accessing or using Thapy, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our services.
          </p>
        </div>
        <div className="container-policy">
          <div className="card">
            <div className="card-body">
              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  For Users Under 18
                </span>
                <p>
                  This User Agreement applies to users under the age of 18 who wish to access therapy services through Thapy.
                </p>
                <p>Please review this information with your parent or guardian before using the app.</p>

                <p className='terms_bold_head'>For Users 13 and Over:</p>
                <ul className='custom-list'>
                  <li>
                    Thapy is a safe space for teens (ages 13+) to connect with licensed therapists.
                  </li>
                  <li>To create an account, you will need permission from a parent or guardian. This ensures a safe and supportive environment for your therapy journey.</li>
                  <li>We take your privacy seriously. Therapists cannot share what you talk about with anyone without your permission, except in very important situations (like if you're in danger).</li>
                  <li>
                    Your Voice Matters: You have the right to decide what information you share with your parent or guardian about your therapy sessions.
                  </li>

                </ul>
                <p className='terms_bold_head'>For Parents and Guardians : </p>

                <ul className='custom-list'>
                  <li>
                    Your consent is required for your teen to create an account on Thapy.
                  </li>
                  <li>We prioritize the privacy of your teen. Therapists follow strict confidentiality guidelines. Information shared in sessions is not disclosed without your teen's permission, except in legally mandated situations (e.g., potential harm to themself or others).</li>
                  <li>You can choose to receive basic information about your teen's therapy sessions with their consent. However, the content of their discussions with the therapist remains confidential.</li>
                </ul>
                <p>By using this app, you (and your parent or guardian if you are under 18) acknowledge and agree to these terms. We look forward to supporting your well-being!</p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>1. </span>Eligibility
                </span>
                <p>You must be at least 13+ with parent’s consent. By using our services, you represent and warrant that you are of required age to form a binding contract with us.</p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>2. </span>Account Registration
                </span>
                <p>You may be required to create an account to access certain features of Thapy. When registering for an account, you agree to provide accurate and complete information. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>
              </div>


              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>3. </span>User Conduct
                </span>
                <p>You agree to use Thapy in compliance with all applicable laws and regulations. You further agree not to:</p>
                <ul className='custom-list'>
                  <li>
                    Use Thapy for any unlawful purpose or in violation of these Terms.
                  </li>
                  <li>Impersonate any person or entity, or falsely state or misrepresent your affiliation with any person or entity.</li>
                  <li>Interfere with or disrupt the operation of Thapy or the networks connected to it.</li>
                  <li>
                    Engage in any conduct that could damage, disable, or impair Thapy or its users experience.
                  </li>

                </ul>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>4. </span>Therapy and Counseling Services
                </span>
                <p>
                  Thapy connects you with licensed therapists for mental health support. We strive for
                  quality, but can't guarantee specific outcomes or the advice provided. Therapists are
                  independent and Thapy isn't liable for their actions. Calls are not recorded, but therapists may    keep confidential session notes as required by professional standards.
                </p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>5. </span>Thapy Cancellation and Refund Policy
                </span>
                <p>
                  We understand that scheduling changes can happen. Here's a breakdown of our cancellation and refund policy:
                </p>
                <ul className='custom-list'>
                  <li>
                    <span>User Initiated Cancellations : </span>
                    <p><span>Full Refund (Limited) : </span> You may cancel your upcoming session for a full refund if you provide at least 24 hours’ notice and have not cancelled another session within the past 30 days.</p>
                    <p>
                      <span>No Refund : </span> Unfortunately, we cannot offer refunds for cancellations made with less than 24 hours’ notice.
                    </p>


                  </li>
                  <li>
                    <span>Therapist Initiated Cancellations : </span>
                    <p><span>Full Refund : </span> If your therapist due to circumstances has to cancel your session, we will give you the option to reschedule it to another convenient time. If at all due to extreme cases rescheduling is not available, we will offer a full refund for that session but only if you claim it within 24 hours of receiving the cancellation notification.</p>
                  </li>
                  <li>
                    <span>Important Notes : </span>
                    <p>
                      You can cancel your upcoming session through the Thapy app.<br />
                      <p>
                        Refunds will be processed automatically through your original payment method within 5 business days.<br />
                      </p>
                      <p>
                        This policy applies to all individual therapy sessions booked through Thapy.<br />
                      </p>
                      <p>
                        Here's why we have this policy:<br />
                      </p>
                      <p>
                        Thapy therapists reserve time specifically for you. Cancellations with short notice limit their ability to schedule other clients.  We also aim to encourage responsible use of our platform.<br />
                      </p>
                      <p>
                        We appreciate your understanding!
                      </p>

                    </p>
                  </li>
                </ul>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>6. </span>Payment & Billing
                </span>
                <p>
                  You agree to pay all fees associated with your use of Thapy, including session fees and any applicable taxes. Payment processing services are provided by third-party payment processors, and we do not store your payment information on our servers.
                </p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>7. </span>Intellectual Property
                </span>
                <p>
                  All content and materials available on Thapy, including but not limited to text, graphics, logos, images, and software, are the property of Thapy or its licensors and are protected by copyright, trademark, and other intellectual property laws. You may not use, reproduce, or distribute any content from Thapy without our prior written consent.
                </p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>8. </span>Privacy
                </span>
                <p>
                  Your privacy is important to us. Please refer to our Privacy Policy for information about how we collect, use, and disclose your personal information.
                </p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>9. </span>Termination
                </span>
                <p>
                  We reserve the right to suspend or terminate your access to Thapy at any time, without prior notice or liability, for any reason whatsoever, including if we believe that you have violated these Terms.
                </p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>10. </span>Disclaimer of Warranties
                </span>
                <p>
                  Thapy is provided on an "as is" and "as available" basis, without any warranties of any kind, express or implied. We do not warrant that Thapy will be uninterrupted, error-free, or free of viruses or other harmful components.
                </p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>11. </span>Limitation of Liability
                </span>
                <p>
                  In no event shall Thapy, its officers, directors, employees, or agents be liable to you or any third party for any indirect, consequential, incidental, special, or punitive damages arising out of or in connection with your use of Thapy.
                </p>
              </div>

              <div className='privacypolicy_points'>
                <span className='privacypolicy_points_heading'>
                  <span className='privacypolicy_points_number'>13. </span>Changes to Terms
                </span>
                <p>
                  We may revise these Terms from time to time in our sole discretion. The most current version will always be available on Thapy. By continuing to use Thapy after any revisions become effective, you agree to be bound by the revised Terms.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserTerms
