import React from "react";
import appimg from "../../Assets/1.png";
import "./DownloadApp.css";
import { GooglePlayButton, AppStoreButton, ButtonsContainer, } from "react-mobile-app-button";

const DownloadApp = () => {
  // TODO: Implement the download app functionality
  const APKUrl = "https://play.google.com/store/apps/details?id=com.thapy";
  const IOSUrl = "https://apps.apple.com/in/app/thapy/id1560000000";

  return (
    <div className="dwnld_container">
      <div className="app_image">
        <img src={appimg} alt="app" />
      </div>
      <div className="app_text">
        <h1>Download <span className="highlight_text">Thapy</span><span className="highlight_dot">.</span></h1>
        <p>
          Access video consultation with experts on the Thapy app. Connect with
          professionals online, available 24/7, from the comfort of your home.
        </p>
        <div className="download_link_section">
          <h3>Get the link to download the app</h3>
          <div className="phone_input_container">
            <input
              type="text"
              placeholder="+91"
              className="country_code_input"
            />
            <input
              type="tel"
              placeholder="Enter phone number"
              className="phone_input"
            />
            <button className="send_sms_button">
              <div className="svg-wrapper-1">
                <div className="svg-wrapper">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path
                      fill="currentColor"
                      d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                    ></path>
                  </svg>
                </div>
              </div>
              <span>Send</span>
            </button>
          </div>
        </div>
        <div className="download_buttons">
          <ButtonsContainer>
            <GooglePlayButton
              url={APKUrl}
              theme={"dark"}
              height={60}
              width={200}
              className="google_play_button"
            />
            <AppStoreButton
              url={IOSUrl}
              theme={"dark"}
              height={60}
              width={200}
              className="google_play_button"
            />
          </ButtonsContainer>
        </div>
      </div>
    </div >
  );
};

export default DownloadApp;
