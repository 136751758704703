import React, { useState, useEffect, useContext } from 'react';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Box } from '@mui/material';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import NotificationsIcon from '@mui/icons-material/Notifications';
import MailIcon from '@mui/icons-material/Mail';
import { useNavigate, Link } from "react-router-dom";
import { styled } from '@mui/system';
import logo from '../../Assets/logo_whitebg.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Avatar from '@mui/material/Avatar';
import GradientCircularProgress from '../Loader/GradientCircularProgress';
import { AuthContext } from '../../Context/AuthContext';
import { auth } from "../../Config/Config";
import './navbar.scss';


const Logo = styled('img')({
    width: '100px',
    marginRight: '16px',
});

const Navbar = ({ userData, userType }) => {
    const navigate = useNavigate();
    const { dispatch } = useContext(AuthContext);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [isLoading, setIsLoading] = useState(true);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLogout = () => {
        auth.signOut().then(() => {
            dispatch({ type: "LOGOUT" });
            navigate("/login");
        });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (userData?.imagePath) {
            const img = new Image();
            img.src = userData.imagePath;
            img.onload = () => setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }, [userData?.imagePath]);

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullscreen(document.fullscreenElement != null);
        };

        document.addEventListener("fullscreenchange", handleFullscreenChange);

        return () => {
            document.removeEventListener("fullscreenchange", handleFullscreenChange);
        };
    }, []);

    const handleFullscreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen().catch((err) => {
                toast.error("Error attempting to enable fullscreen mode. Please try again later!!", { containerId: 'therapistNavbar' });
            });
        } else {
            document.exitFullscreen();
        }
    };

    const menuLinks = {
        'therapist': {
            profile: '/therapists/profile',
            settings: '/therapists/settings',
        },
        'user': {
            profile: '/user/profile',
            settings: '/user/settings',
        },
    };

    // const profileLink = menuLinks[userType]?.profile || '/';
    // const settingsLink = menuLinks[userType]?.settings || '/';

    return (
        <>
            <ToastContainer
                containerId="therapistNavbar"
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <AppBar position="static" sx={{ background: 'transparent', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                <Toolbar>
                    <Logo src={logo} alt="Logo" />
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton color="inherit" className='icon-btn-navbar'>
                        {isFullscreen ? (
                            <CloseFullscreenIcon onClick={handleFullscreen} sx={{ color: '#1b263b' }} />
                        ) : (
                            <FullscreenExitOutlinedIcon onClick={handleFullscreen} sx={{ color: '#1b263b' }} />
                        )}
                    </IconButton>
                    <IconButton color="inherit" className='icon-btn-navbar'>
                        <MailIcon sx={{ color: '#1b263b' }} />
                    </IconButton>
                    <IconButton color="inherit" className='icon-btn-navbar'>
                        <NotificationsIcon sx={{ color: '#1b263b' }} />
                    </IconButton>
                    <IconButton
                        color="inherit"
                        aria-label="user account"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        className='icon-btn-navbar'
                    >
                        {isLoading ? (
                            <GradientCircularProgress />
                        ) : (
                            userData?.imagePath ? (
                                <Avatar src={userData.imagePath} sx={{ width: 40, height: 40 }} />
                            ) : (
                                <Avatar sx={{ width: 40, height: 40, color: 'black', backgroundColor: '#f5f5f5' }}>
                                    <strong>{userData?.name?.charAt(0).toUpperCase()}</strong>
                                </Avatar>
                            )
                        )}
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        <Link to={menuLinks[userType]?.profile} style={{ color: 'grey' }}>
                            <MenuItem onClick={handleClose} sx={{ width: '200px' }}>
                                Profile
                            </MenuItem>
                        </Link>
                        <Link to={menuLinks[userType]?.profile} style={{ color: 'grey' }}>
                            <MenuItem onClick={handleClose} sx={{ width: '200px' }}>
                                Settings
                            </MenuItem>
                        </Link>
                        <MenuItem onClick={handleLogout} sx={{ width: '200px', color: 'red' }}>Logout</MenuItem>
                    </Menu>
                    {/* <IconButton color="inherit">
            <MoreVertIcon sx={{ color: '#1b263b' }} />
          </IconButton> */}
                </Toolbar>
            </AppBar>
        </>
    );
};

export default Navbar;
