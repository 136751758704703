import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { AiOutlineVideoCamera, AiOutlineAudio } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import './UserBookingAppCal.css';
import DBHandler from "../../Utils/DBHandler";

const UserBookingAppCal = React.memo(({ therapist }) => {
  const formatDate = useCallback((date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }, []);

  const convertToIST = useCallback((timeString) => {
    const [hour, minute] = timeString.split(':').map(Number);
    const date = new Date();
    date.setHours(hour);
    date.setMinutes(minute);
    date.setSeconds(0);

    const options = {
      timeZone: 'Asia/Kolkata',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    return date.toLocaleTimeString('en-US', options);
  }, []);

  const today = useMemo(() => new Date(), []);
  const tomorrow = useMemo(() => {
    const t = new Date(today);
    t.setDate(today.getDate() + 1);
    return t;
  }, [today]);

  const dayAfterTomorrow = useMemo(() => {
    const d = new Date(today);
    d.setDate(today.getDate() + 2);
    return d;
  }, [today]);

  const dayAfterTomorrow2 = useMemo(() => {
    const d2 = new Date(today);
    d2.setDate(today.getDate() + 3);
    return d2;
  }, [today]);

  const formattedTomorrow = useMemo(() => formatDate(tomorrow), [tomorrow, formatDate]);
  const formattedDayAfterTomorrow = useMemo(() => formatDate(dayAfterTomorrow), [dayAfterTomorrow, formatDate]);
  const formattedDayAfterTomorrow2 = useMemo(() => formatDate(dayAfterTomorrow2), [dayAfterTomorrow2, formatDate]);

  const [selectedMode, setSelectedMode] = useState('Video');
  const [selectedDate, setSelectedDate] = useState(formattedTomorrow);
  const [selectedTime, setSelectedTime] = useState('');
  const [timeSlots, setTimeSlots] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const navigate = useNavigate();

  const fetchTimeSlots = useCallback(async (date) => {
    const formattedDate = formatDate(new Date(date));
    const dbHandler = new DBHandler();
    const slots = await dbHandler.getPsychologistTimeSlotsForDate(therapist.id, formattedDate);
    setTimeSlots(slots || []);
  }, [therapist.id, formatDate]);

  useEffect(() => {
    fetchTimeSlots(selectedDate);
  }, [selectedDate, fetchTimeSlots]);

  const handleBookAppointment = useCallback(() => {
    if (selectedTime) {
      setIsPopupVisible(true);
    }
  }, [selectedTime]);

  const handleBookAppointmentYes = useCallback(() => {
    navigate('/user/bookingConfirmation', {
      state: {
        therapist,
        selectedTime,
        selectedDate,
        selectedMode,
      },
    });
    setIsPopupVisible(false);
  }, [navigate, therapist, selectedTime, selectedDate, selectedMode]);

  return (
    <div className="booking-container">
      <h2 className="booking-heading">Booking Appointment</h2>
      <h3 className="session-mode-heading">Mode of Session</h3>
      <div className="mode-buttons">
        <button
          onClick={() => setSelectedMode('Video')}
          className={`mode-button ${selectedMode === 'Video' ? 'selected' : ''}`}
        >
          <AiOutlineVideoCamera className="icon" />
          Video
        </button>
        <button
          onClick={() => setSelectedMode('Audio')}
          className={`mode-button ${selectedMode === 'Audio' ? 'selected' : ''}`}
        >
          <AiOutlineAudio className="icon" />
          Audio
        </button>
      </div>

      <div className="date-buttons">
        <button
          onClick={() => setSelectedDate(formattedTomorrow)}
          className={`date-button ${selectedDate === formattedTomorrow ? 'selected' : ''}`}
        >
          Tomorrow
        </button>
        <button
          onClick={() => setSelectedDate(formattedDayAfterTomorrow)}
          className={`date-button ${selectedDate === formattedDayAfterTomorrow ? 'selected' : ''}`}
        >
          {new Date(dayAfterTomorrow).toLocaleDateString('en-US', { day: '2-digit', month: 'short' })}
        </button>
        <button
          onClick={() => setSelectedDate(formattedDayAfterTomorrow2)}
          className={`date-button ${selectedDate === formattedDayAfterTomorrow2 ? 'selected' : ''}`}
        >
          {new Date(dayAfterTomorrow2).toLocaleDateString('en-US', { day: '2-digit', month: 'short' })}
        </button>
      </div>

      <div className="time-slots">
        {timeSlots.length > 0 ? (
          timeSlots.map((slot) => (
            <button
              key={slot.time}
              onClick={() => setSelectedTime(slot.time)}
              className={`time-slot ${selectedTime === slot.time ? 'selected' : ''}`}
            >
              {convertToIST(slot.time)}
            </button>
          ))
        ) : (
          <p className="no-slots">No slots available for the selected date.</p>
        )}
      </div>

      <button onClick={handleBookAppointment} className="book-button">
        Book an Appointment
      </button>

      {isPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-content">
            <p className="popup-text">
              Do you want to book an appointment at {convertToIST(selectedTime)} on {new Date(selectedDate).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })}?
            </p>
            <div className="popup-actions">
              <button
                onClick={() => setIsPopupVisible(false)}
                className="popup-button no"
              >
                No
              </button>
              <button
                onClick={handleBookAppointmentYes}
                className="popup-button yes"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default UserBookingAppCal;
