import React, { useState, useEffect, useCallback, useRef } from 'react';
import DBHandler from "../../Utils/DBHandler";
import Loader from '../../Components/Loader/Loader';
import './Home.css';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HomeNavbar from '../../Components/Navbar';
import TherapistProfileCard from '../../Components/TherapistProfile/TherapistProfileCard';

export default function TherapistHomeList() {
    const [therapistData, setTherapistData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [lastDoc, setLastDoc] = useState(null);
    const [loadingMore, setLoadingMore] = useState(false);
    const isFetching = useRef(false);

    const fetchTherapists = useCallback(async () => {
        if (isFetching.current) return;
        isFetching.current = true;
        setLoadingMore(true);

        try {
            const dbHandler = new DBHandler();
            const { psychologists: newPsychologists, lastDocument } = await dbHandler.fetchPsychologistListLazy(true, 10, lastDoc);

            if (newPsychologists.length > 0) {
                setTherapistData(prevData => [...prevData, ...newPsychologists]);
                setLastDoc(lastDocument);
            }
        } catch (error) {
            toast.error('Error fetching therapists. Kindly try again later!!', { containerId: 'therapistHomeListPage' });
        } finally {
            setLoadingMore(false);
            isFetching.current = false;
        }
    }, [lastDoc]);

    useEffect(() => {
        setLoading(true);
        fetchTherapists().then(() => setLoading(false));
    }, [fetchTherapists]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.scrollHeight - 50 && !loadingMore) {
                fetchTherapists();
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [fetchTherapists, loadingMore]);

    return (
        <>
            <ToastContainer
                containerId="therapistHomeListPage"
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <HomeNavbar />
            <div className='therapistHomeList'>
                <div className='therapistHomeListContainer'>
                    {loading && <Loader />}
                    <TherapistProfileCard psychologist={therapistData} psyType={true} isHome={true} />
                    {loadingMore && <Loader />}
                </div>
            </div>
        </>
    );
}
