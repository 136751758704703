class Psychologist {
    constructor({
        id = '',
        uid = '',
        name = '',
        email = '',
        phone = '',
        countryCode = '',
        imagePath = '',
        areas = [],
        languages = [],
        about = '',
        price = 999999,
        rating = 9.9,
        overallRating = 0.00,
        noOfRatings = 0,
        identityProofPath = '',
        professionProofPath = '',
        verified = -1,
        professional = true,
        upi = '',
        calls = { 'Booking': false, 'Live': false, 'Instant': false },
        online = false,
        status = '',
        lastOnline = '',
        timeSlotsMap = null,
        activeBookingMap = {},
        meetLink = ''
    }) {
        this.id = id;
        this.uid = uid;
        this.name = name;
        this.email = email;
        this.phone = phone;
        this.countryCode = countryCode;
        this.imagePath = imagePath;
        this.areas = areas;
        this.languages = languages;
        this.about = about;
        this.price = price;
        this.rating = rating;
        this.overallRating = overallRating;
        this.noOfRatings = noOfRatings;
        this.identityProofPath = identityProofPath;
        this.professionProofPath = professionProofPath;
        this.verified = verified;
        this.professional = professional;
        this.upi = upi;
        this.calls = calls;
        this.online = online;
        this.status = status;
        this.lastOnline = lastOnline;
        this.timeSlotsMap = timeSlotsMap;
        this.activeBookingMap = activeBookingMap;
        this.meetLink = meetLink;
    }

    async modifyPsychologist(psychologist) {
        Object.assign(this, psychologist);
    }

    static fromSnapshot(snapshot) {
        const psyData = snapshot.data();
        return new Psychologist({
            id: snapshot.id,
            ...psyData
        });
    }
}

export default Psychologist;
