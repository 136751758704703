class TherapistDashboardInfo {
    constructor({
        overallRating = 0.00,
        totalRatings = 0,
        totalBookingCalls = 0,
        totalLiveCalls = 0,
        tenDaysPayments = [],
        lastSettledAmt = 0.00,
        lastSettledDate = null,
        deviceToken = {},
        uniquePatients = [],
    }) {
        this.overallRating = overallRating;
        this.totalRatings = totalRatings;
        this.totalBookingCalls = totalBookingCalls;
        this.totalLiveCalls = totalLiveCalls;
        this.tenDaysPayments = tenDaysPayments;
        this.lastSettledAmt = lastSettledAmt;
        this.lastSettledDate = lastSettledDate;
        this.deviceToken = deviceToken;
        this.uniquePatients = uniquePatients;
    }

    static fromMap(map) {
        return new TherapistDashboardInfo({
            overallRating: map.overallRating ?? 0.00,
            totalRatings: map.totalRatings ?? 0,
            totalBookingCalls: map.totalBookingCalls ?? 0,
            totalLiveCalls: map.totalLiveCalls ?? 0,
            tenDaysPayments: map.tenDaysPayments ?? [],
            deviceToken: map.deviceToken ?? {},
            uniquePatients: map.uniquePatients ?? {},
            lastSettledAmt: map.lastSettledAmt ?? 0.00,
            lastSettledDate: map.lastSettledDate ? map.lastSettledDate.toDate() : null,
        });
    }

    toMap() {
        return {
            overallRating: this.overallRating,
            totalRatings: this.totalRatings,
            totalBookingCalls: this.totalBookingCalls,
            totalLiveCalls: this.totalLiveCalls,
            tenDaysPayments: this.tenDaysPayments,
            lastSettledAmt: this.lastSettledAmt,
            lastSettledDate: this.lastSettledDate,
            deviceToken: this.deviceToken,
            uniquePatients: this.uniquePatients
        };
    }
}

export default TherapistDashboardInfo;
