import React, { useState, useEffect } from 'react';
import { onMessageListener } from '../../Config/Config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Notification() {
    const [notification, setNotification] = useState({ title: '', body: '' });

    useEffect(() => {
        onMessageListener()
            .then(payload => {
                setNotification({
                    title: payload.notification.title,
                    body: payload.notification.body
                });
                toast.info(`${payload.notification.title}: ${payload.notification.body}`, { containerId: 'firebaseNotification' });
            })
            .catch(err => console.log("failed:", err));
    }, []);

    return (
        <>
            <ToastContainer
                containerId="firebaseNotification"
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    );
}
