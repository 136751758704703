import React, { useState, useEffect, useContext } from 'react';
import '../../Therapist/BookingPage/ConsultationCard.css';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../../Components/sidebar/Sidebar';
import Navbar from '../../../Components/navbar/navbar';
import DBHandler from "../../../Utils/DBHandler";
import { CardComponent } from '../../../Components/UserCardComponent/CardComponent';
import Loader from '../../../Components/Loader/Loader';
import { AuthContext } from "../../../Context/AuthContext";

export default function LiveInstantCallHistory() {
  const [tab, setTab] = useState('completed');
  const [bookingData, setBookingData] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useContext(AuthContext);
  const userId = currentUser.id;
  const navigate = useNavigate();
  //TODO: Add rating for completed calls
  //TODO: When live call ends think of a way to mark that call is completed

  useEffect(() => {
    const fetchBookings = async () => {
      setLoading(true);
      try {
        const dbHandler = new DBHandler();
        const { bookings: newBookings, lastDocument } = await dbHandler.getBookingHistory(userId, 'Live Call', 10, lastDoc);

        const combinedBookings = [...bookingData, ...newBookings];

        // const uniqueBookings = Array.from(new Map(combinedBookings.map(booking => [booking.bookingId, booking])).values());

        setBookingData(combinedBookings);
        setLastDoc(lastDocument);
      } catch (error) {
        console.error('Error fetching settlement history:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBookings();
  }, [userId, lastDoc]);

  const handleTabChange = (tab) => {
    setTab(tab);
  };

  const handleCardClick = (booking) => {
    navigate(`/user/consultationInfo/${booking.psyId}`, { state: { callData: booking } });
  };

  const filterBookings = () => {
    let filteredBookings = bookingData.filter(booking => {
      if (tab === 'cancelled') {
        return booking.bookingStatus !== 1;
      } else {
        return booking.bookingStatus === 1;
      }
    });

    filteredBookings.sort((a, b) => b.timestamp - a.timestamp);
    return filteredBookings;
  };

  const statusMap = {
    'completed': 'Completed',
    'cancelled': 'Cancelled',
  };

  const filteredBookings = filterBookings();

  // TODO: Fix loading flickering of screen
  return (
    <div className='consultant_home'>
      <Sidebar userType={'user'} />
      <div className='Consultant_home_container'>
        <Navbar userData={currentUser} userType={'user'} />
        {loading && <Loader />}
        <h2 className='consultant_call_heading'>Instant & Live Calls</h2>
        <hr className="divider" />
        <div className="tabs">
          <div className={`tab ${tab === 'completed' ? 'active' : ''}`} onClick={() => handleTabChange('completed')}>Completed</div>
          <div className={`tab ${tab === 'cancelled' ? 'active' : ''}`} onClick={() => handleTabChange('cancelled')} style={{ color: '#d90429' }}>Cancelled</div>
        </div>
        <hr className="divider" />
        <Box className="card-grid">
          {filteredBookings.length > 0 ? (
            filteredBookings.map((booking, index) => (
              <CardComponent
                key={index}
                initials={booking.userName[0].toUpperCase()}
                consultationData={booking}
                onClick={() => handleCardClick(booking)}
              />
            ))
          ) : (
            <Typography className="no-bookings-message">No {statusMap[tab]} Live Calls at the Moment</Typography>
          )}
        </Box>
      </div>
    </div>
  );
};

