import React, { useState } from 'react';
import './DeleteAc.css';
import { useNavigate } from 'react-router-dom';
import DeleteImg from '../../../Assets/deleteAc.png';
import { getAuth, deleteUser, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { query, deleteDoc, doc, where, collection, getDocs } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { db } from '../../../Config/Config';

const DeleteAccount = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const authInstance = getAuth();
  const user = authInstance.currentUser;

  const handleDeleteAccount = async () => {
    if (!email || !password) {
      toast.error('Please enter both email and password.', { containerId: 'deleteAccount' });
      return;
    }

    const userQuery = query(collection(db, 'users'), where('email', '==', email));
    const userSnapshot = await getDocs(userQuery);

    const therapistQuery = query(collection(db, 'therapists'), where('email', '==', email));
    const therapistSnapshot = await getDocs(therapistQuery);

    if (userSnapshot.empty && therapistSnapshot.empty) {
      toast.error('User with the provided email not found.', { containerId: 'deleteAccount' });
      return;
    }

    let docRef1, docRef2;

    if (!therapistSnapshot.empty) {
      const therapistDocument = therapistSnapshot.docs[0];
      docRef1 = doc(db, 'therapists', therapistDocument.id);
      docRef2 = doc(db, 'therapists2', therapistDocument.id);
    } else {
      const userDocument = userSnapshot.docs[0];
      docRef1 = doc(db, 'users', userDocument.id);
    }

    const credential = EmailAuthProvider.credential(email, password);

    try {
      await reauthenticateWithCredential(user, credential);
      if (!therapistSnapshot.empty) {
        await deleteDoc(docRef1);
        await deleteDoc(docRef2);
      } else {
        await deleteDoc(docRef1);
      }

      await deleteUser(user);

      toast.success('Account Deleted Successfully!!', { containerId: 'deleteAccount' });
      setTimeout(() => {
        navigate('/login');
      }, 2500);
    } catch (error) {
      toast.error('Authentication failed. Please check your email and password.', { containerId: 'deleteAccount' });
    }
  };

  return (
    <div className='delete_ac_container'>
      <div className='deleteImg_container'>
        <img
          src={DeleteImg}
          className='deleteAc_img'
          alt="Delete Password"
        />
      </div>
      <div className='deleteAc_form_container'>
        <div>
          <h2 className='deleteAc_heading'>Delete Account</h2>
          <p className='delete_ac_info'>Enter the email address and password associated with the account you want to delete. All user data like name, phone email related to the account will be deleted</p>
          <div className="input-group first_one">
            <input
              required
              type="email"
              id="typeEmail"
              autoComplete="off"
              className="input"
              onChange={(e) => setEmail(e.target.value)}
            />
            <label className="user-label" htmlFor="typeEmail">Registered Email Address</label>
          </div>
          <div className="input-group second_one">
            <input
              required
              type="password"
              id="typePassword"
              autoComplete="off"
              className="input"
              onChange={(e) => setPassword(e.target.value)}
            />
            <label className="user-label" htmlFor="typePassword">Password</label>
          </div>
          <button className="button" onClick={handleDeleteAccount}>Delete Account</button>
        </div>
      </div>
      <ToastContainer
        containerId="deleteAccount"
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light" />
    </div>
  );
};

export default DeleteAccount;
